<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border container">
    <ion-buttons slot="start" *ngIf="user && user.uid">
      <ion-button (click)="dismiss()" fill="clear" icon-only>
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="stage" *ngIf="view.stage" [innerHTML]="view.stage"></div>

<ion-content
  [class.vip]="post.vip"
  [class.videoMode]="view.mode === 'video'"
  scrollEvents="true"
  (ionScroll)="onScroll($event)"
  (ionScrollEnd)="onScrollEnd()"
  fullscreen="true"
>

  <div id="postPage"
    #postPage
    (swipe)="swipeEvent($event)"
    class="container post-page post-page-size{{view.size}}"
    [class.vip]="post.vip"
    [class.videoMode]="view.mode === 'video'"
    [class.tts]="view.mode === 'tts'"
  >
  
    <div
      #headlineToolbar
      class="headlineToolbar container"
      [class.videoMode]="view.mode === 'video'">
      
      <ion-thumbnail>
        <ion-img [src]="post.photo || post.thumbnail"></ion-img>
      </ion-thumbnail>

      <header class="headlineToolbarContainer">

        <h1 class="ion-text-wrap" [innerHTML]="post.name"></h1>

        <ion-item
          *ngIf="post.external"
          #hostItem
          class="hostItem"
          lines="none"
          [class.hasAvatar]="post.avatar"
        >
          <ion-icon
            name="shield-checkmark-outline"
            slot="start"
            color="white"
          ></ion-icon>
          <ion-label color="white" (click)="openProfile()">
            <h2 [innerHTML]="post.host"></h2>
          </ion-label>
          <ion-chip
            class="ratingBadge"
            *ngIf="post.rating && post.rating.score"
            slot="end"
            color="white"
          >
            <ion-icon
              [name]="post.rating.score > 4.5 ? 'star' : post.rating.score > 2.5 ? 'star-half-outline' : 'star-outline'"
            ></ion-icon>
            <ion-label [innerHTML]="post.rating.score"></ion-label>
          </ion-chip>
          <ion-button
            slot="end"
            icon-only
            fill="clear"
            (click)="information($event)"
          >
            <ion-icon
              name="information-circle-outline"
              color="white"
            ></ion-icon>
          </ion-button>
          <ion-button
            slot="end"
            [hidden]="!post.allowed"
            icon-only
            fill="clear"
            (click)="openExternal()"
          >
            <ion-icon name="open-outline" color="white"></ion-icon>
          </ion-button>
        </ion-item>
      </header>
    </div>

    <ion-item
      class="sponsoredPostItem"
      (click)="toggleSponsoredInfoView()"
      *ngIf="post.sponsored"
      color="primary"
      lines="none"
    >
      <ion-icon name="cash-outline" slot="start"></ion-icon>
      <ion-label class="ion-text-wrap">
        <h4
          class="ion-text-wrap"
          [innerHTML]="'post_sponsored_label'|translate"
        ></h4>
        <small
          class="ion-text-wrap"
          [hidden]="view.showSponsoredInfo"
          [innerHTML]="'post_sponsored_subHeadline'|translate"
        ></small>
        <small
          class="ion-text-wrap"
          [hidden]="!view.showSponsoredInfo"
          [innerHTML]="'post_sponsored_text'|translate"
        ></small>
        <ion-button
          [hidden]="!view.showSponsoredInfo"
          (click)="rate()"
          color="white"
          fill="outline"
        >
          <ion-icon name="flag-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'rate_sponsoredpost'|translate"></ion-label>
        </ion-button>
      </ion-label>
      <ion-icon
        [name]="view.showSponsoredInfo ? 'chevron-up-outline' : 'chevron-down-outline'"
        slot="end"
      ></ion-icon>
    </ion-item>

    <div class="ion-padding">

      <pipeline-post-reader [post]="post"></pipeline-post-reader>

      <div class="container">
        <ion-card class="downloadWallCard" *ngIf="view.downloadWall">
          <ion-card-header>
            <ion-card-title
              [innerHTML]="'post_download_wall_title'|translate"
            ></ion-card-title>
            <ion-card-subtitle
              [innerHTML]="'post_download_wall_subtitle'|translate"
            ></ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <ion-button (click)="downloadApp()" color="white" expand="block">
              <ion-label [innerHTML]="'download_app'|translate"></ion-label>
              <ion-icon name="download-outline" slot="end"></ion-icon>
            </ion-button>

            <ion-button
              (click)="createAccount()"
              color="white"
              fill="outline"
              expand="block"
            >
              <ion-label [innerHTML]="'create_account'|translate"></ion-label>
              <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
            </ion-button>
          </ion-card-content>
        </ion-card>

        <ion-card
          color="primary"
          *ngIf="view && view.short && (view.postWebTemplate !== 'preview_register') && (post && !post.vip)"
          id="viewShortCard"
        >
          <ion-card-header>
            <ion-card-title
              [innerHTML]="'post_view_short_headline'|translate"
            ></ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p [innerHTML]="'post_view_short_text'|translate"></p>
          </ion-card-content>
          <ion-card-header>
            <ion-button (click)="downloadApp()" color="white" expand="block">
              <ion-label [innerHTML]="'download_app'|translate"></ion-label>
              <ion-icon name="download-outline" slot="end"></ion-icon>
            </ion-button>
          </ion-card-header>
        </ion-card>

        <ion-card
          color="primary"
          *ngIf="view && view.short && (view.postWebTemplate === 'preview_register')"
          id="viewShortCard"
        >
          <ion-card-header>
            <ion-card-title
              [innerHTML]="'post_view_short_register_headline'|translate"
            ></ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p [innerHTML]="'post_view_short_register_text'|translate"></p>
          </ion-card-content>
          <ion-card-header>
            <ion-button (click)="createAccount()" color="white" expand="block">
              <ion-label [innerHTML]="'create_account'|translate"></ion-label>
              <ion-icon name="arrow-forward-outline" slot="end"></ion-icon>
            </ion-button>
          </ion-card-header>
        </ion-card>

        <div *ngIf="post.vip" class="vipCard">
          <ion-card-header>
            <ion-card-title
              [innerHTML]="'vip_card_headline'|translate"
            ></ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p>
              <span [innerHTML]="'vip_card_text_a'|translate"></span>
              <br />
              <span [innerHTML]="'vip_card_text_b'|translate"></span>
            </p>
            <p [innerHTML]="'vip_card_text_c'|translate"></p>
            <ion-button expand="block" (click)="purchase(post)" color="dark">
              <ion-icon name="ticket-outline" slot="start"></ion-icon>
              <ion-label
                [innerHTML]="'vip_card_button_label'|translate"
              ></ion-label>
            </ion-button>
            <ion-button
              expand="block"
              (click)="promptBetaTest()"
              fill="outline"
              color="dark"
            >
              <ion-icon name="git-branch-outline" slot="start"></ion-icon>
              <ion-label
                [innerHTML]="'prompt_beta_test_submitted_title_2'|translate"
              ></ion-label>
            </ion-button>
            <ion-button
              expand="block"
              (click)="changeAbonnement()"
              fill="outline"
              color="dark"
            >
              <ion-icon name="card-outline" slot="start"></ion-icon>
              <ion-label
                [innerHTML]="'change_abonnement'|translate"
              ></ion-label>
            </ion-button>
          </ion-card-content>
        </div>

        <ion-card
          *ngIf="post && (!post.post_content || (post.post_content && !post.post_content.length))"
          class="noEntriesCard"
        >
          <ion-card-content>
            <ion-icon name="warning-outline"></ion-icon>
            <ion-card-title
              [innerHTML]="'no_content'|translate"
            ></ion-card-title>
          </ion-card-content>
        </ion-card>
      </div>

    </div>
  </div>

</ion-content>
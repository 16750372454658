import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { AlertService } from 'src/app/services/core/alert.service';
import { DropService } from 'src/app/services/core/drop.service';
import { EventsService } from "src/app/services/core/events.service";
import { FoldersService } from 'src/app/services/utils/folders.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ProjectsService } from "src/app/services/core/projects.service";
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";

import { MediaFolderSettingsPage } from 'src/app/pages/media/media/media-folder-settings/media-folder-settings.page';

import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pipeline-folders-filter-card',
  templateUrl: './folders-filter-card.component.html',
  styleUrls: ['./folders-filter-card.component.scss']
})
export class FoldersFilterCardComponent implements OnDestroy, OnInit {
  @Input() cards: any;
  @Input() location: string;
  @Input() paginationConfig: paginationConfig = {};
  @Input() service: any;
  @Input() view: any;

  @Output() onChanged = new EventEmitter();
  @Output() onItemsChanged = new EventEmitter();
  @Output() onRefresh = new EventEmitter();

  subs = new Subscription();

  constructor(
    private alertService: AlertService,
    private dragula: DragulaService,
    private dropService: DropService,
    private events: EventsService,
    private folders: FoldersService,
    private modalService: ModalService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {

  }

  allFolders() {
    console.log('folders-filter-card: allFolders', this.view);

    const key: string = this.view.itemsKey || 'items', backupKey: string = `${key}_backup`;

    // reset items
    if (!!this.view[backupKey]) {
      this.view[key] = this.view[backupKey];
    }

    // overwrite pagination config
    if (!!this.view && !!this.paginationConfig) {
      this.paginationConfig.backup = this.view[key];
    }

    // reset folders view
    this.view.folders.forEach((_folder: folder) => {
      _folder.active = false;
    });

    this.view.currentFolder = null;

    /*
    this.doRefresh()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
    */

    this.onChanged.emit(this.view.currentFolder);
  }

  calcViewVars() {

  }

  cancelFolderMode() {
    this.view.createFolderMode = false;
  }

  createFolder() {
    this.view.createFolderMode = true;
    this.cards.folders.open = true;
  }

  async deleteFolder(folder: folder) {
    try {

      // request confirm
      const confirmed: boolean = !!(await this.alertService.requestConfirm());

      // do not delete if not confirmed
      if (!confirmed) {
        return false;
      }

      // delete if confirmed
      this.service.deleteFolder(folder.uid)
        .then(() => {
          this.loadFolders(true);
        })
        .catch((error: any) => {
          if (!!error) {
            this.events.publish('error', error);
          }
        });

    } catch (e) {
      console.warn('executing button action failed', e);
    }
  }

  detectChanges() {

  }

  async doRefresh() {
    this.onRefresh.emit();

    try {
      await this.loadFolders(true);
    } catch (e) {
      this.events.publish('error', e);
    }
  }

  expandList(listName: string) {
    this.view.expandedList = listName;
  }

  async folderSettings(folder: folder) {

    const modal: any = await this.modalService.create({
      component: MediaFolderSettingsPage,
      componentProps: {
        folder: folder,
      },
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: "defaultModal",
    });

    modal.onDidDismiss().then(() => {
      this.calcViewVars();
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  handleItemDrop(event: any = null, dropItem: any = null) {
    this.dropService.handleItemDrop(event, dropItem)
      .then((response: any) => {
        console.log('drop response', response);
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  async init() {
    if (!!this.view && (!this.view.folders || !this.view.folders.length)) {
      try {
        await this.loadFolders(true);
      } catch (e) {
        this.events.publish('error', e);
      }
    }
  }


  initDragula() {

    if (!this.tools.isDesktop()) {
      return false;
    }

    try {

      this.view.dragula = this.dragula.createGroup("FOLDERS_LIST", {
        //direction: 'horizontal',
        moves: (el, source, handle) => handle.className === "drag-handle",

        accepts: function (el, target, source, sibling) {
          return true; // elements can be dropped in any of the `containers` by default
        },

        invalid: function (el, handle) {
          return false; // don't prevent any drags from initiating by default
        },

        // settings
        /*
        copy: true,                       // elements are moved by default, not copied
        copySortSource: false,             // elements in copy-source containers can be reordered
        revertOnSpill: false,              // spilling will put the element back where it was dragged from, if this is true
        removeOnSpill: false,              // spilling will `.remove` the element, if this is true
        mirrorContainer: document.body,    // set the element that gets mirror elements appended
        ignoreInputTextSelection: true,     // allows users to select input text, see details below
        */
      });

      this.initDragulaEvents();

    } catch (e) {
      console.warn('dragula init failed', e);
      this.initDragulaEvents();
    }

  }

  async initDragulaEvents() {
    let mouseMoveHandler: any;

    const startMouseMove = (event: any = null) => {
      document.addEventListener("mousemove", mouseMoveHandler = (e: any) => { this.onMouseMove(e); }, true);
    };

    const stopMouseMove = () => {
      document.removeEventListener("mousemove", mouseMoveHandler, true);
      this.handleItemDrop(this.view.dragItem, this.view.dropItem);
    };

    try {
      await this.subs.unsubscribe();
      this.subs = new Subscription();
    } catch (e) {
      console.warn('unsubscribe failed', e);
    }

    setTimeout(() => {
      this.subs.add(this.dragula.drag("FOLDERS_LIST")
        .subscribe(({ el }) => {
          this.view.dragItem = el;
          this.view.dragging = true;
          startMouseMove();
        })
      );

      this.subs.add(this.dragula.dragend("FOLDERS_LIST")
        .subscribe(() => {
          this.view.dragging = false;
          stopMouseMove();
        })
      );
    }, 1000);
  }


  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });
  }

  loadByFolder(folder: folder, blForceRefresh: boolean = true) {
    folder.loading = true;
    this.view.loading = true;

    const key: string = this.view.itemsKey || 'items', backupKey: string = `${key}_backup`;

    if (!!this.view[key] && !this.view[backupKey]) {
      this.view[backupKey] = this.view[key];
    }

    this.folders.getFolderItems(folder.uid, blForceRefresh)
      .then((response: any) => {
        folder.loading = false;

        this.setFolder(folder);

        this.view[backupKey] = (response || []);
        this.view[key] = (response || []);
        this.view.loading = false;

        this.onItemsChanged.emit(this.view[key]);
        this.onChanged.emit(this.view.currentFolder);
      })
      .catch((error: any) => {
        folder.loading = false;
        this.view.loading = false;
        this.events.publish('error', error);
      });
  }

  async loadCards() {
    try {
      if (!this.cards) {
        this.cards = (await this.sidebar.getCards() || (this.cards || {}));
      }
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadFolders(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.view.project = await this.projects.getCurrent();

      if (!this.view.project || !this.view.project.uid) {
        this.view.loadingFolders = false;
        return false;
      } else {
        this.view.loadingFolders = true;

        try {
          this.view.options = this.view.options || {};

          // build filters
          let filters: any = JSON.parse(JSON.stringify(this.view.options));

          if (!!this.location) {
            filters.location = this.location;
          }

          // remove attributes from folder filters
          delete filters.attributes;

          this.service.getFolders(filters, blForceRefresh, {
            limit: 1000,
          })
            .then((folders: folder[]) => {
              this.view.loadingFolders = false;

              this.view.folders = (folders || [])
                .map((folder: folder) => {
                  folder.active = !!(!!this.view.currentFolder && (this.view.currentFolder.uid === folder.uid));
                  return folder;
                })
                .sort((a: any, b: any) => {

                  const _a: string = `${a.title}`.toLowerCase(),
                    _b: string = `${b.title}`.toLowerCase();

                  if (_a < _b) return -1;
                  if (_b > _a) return 1;
                  return 0;
                });

              this.detectChanges();

              this.initDragulaEvents();

              resolve(folders);
            })
            .catch((error: any) => {
              this.view.loadingFolders = false;
              reject(error);
            });
        } catch (e) {
          reject(e);
        }
      }

    });
  }

  ngOnDestroy() {

    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }

    try {
      this.subs.unsubscribe();
    } catch (e) {
      console.warn('unsubscribe failed', e);
    }
  }

  ngOnInit() {
    this.init();
    this.initEvents();

    this.loadCards();
  }

  onMouseMove(e: any) {
    console.log('onMouseMove', e);

    this.view.dropItem = document.elementFromPoint(e.clientX, e.clientY);

    try {
      e.preventDefault();
    } catch (e) {
      console.warn('prevent failed', e);
    }

    return false;
  }

  openFolder(folder: folder) {
    
    if (!folder.uid || !!folder.loading || !!this.view.loading) {
      return false;
    }

    if (!!folder && !!folder.location) {
      return this.loadByFolder(folder);
    }

    this.setFolder(folder);

    this.onChanged.emit(this.view.currentFolder);
  }

  async saveFolder() {

    if (!this.view.folderName || !this.view.folderName.length) {
      return false;
    }

    this.view.folders = this.view.folders || [];

    const folder: folder = {
      active: true,
      location: this.location,
      parent: (this.view.folderParent || 0),
      title: this.view.folderName,
      //user: this.userService.getUid(),
    };

    this.view.folders.push(folder);

    try {
      this.service.createFolder(folder)
        .then(async () => {
          this.view.folderName = '';
          this.cancelFolderMode();
          await this.doRefresh();
        })
        .catch(async (error: any) => {
          this.events.publish('error', error);
          await this.doRefresh();
        });
    } catch (e) {
      this.events.publish('error', e);
      await this.doRefresh();
    }
  }

  setFolder(folder: folder) {

    this.view.folders.forEach((_folder: folder) => {
      _folder.active = false;
    });

    folder.active = true;

    this.view.currentFolder = folder;
    this.view.folderParent = folder.uid;
  }

  toggleCard() {
    this.cards = this.cards || {};
    this.cards.folders = this.cards.folders || {};
    this.cards.folders.open = !this.cards.folders.open;

    this.sidebar.setCards(this.cards);
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

}
import { Pipe, PipeTransform } from '@angular/core';

import { ConfigService } from "src/app/services/core/config.service";
import { TranslationService } from 'src/app/services/core/translation.service';

@Pipe({ name: 'translateAndFillVars' })
export class TranslateAndFillVarsPipe implements PipeTransform {

  appConfig: pipelineAppConfig;

  appNameVar: string = '${appName}';
  appVersionVar: string = '${appVersion}';

  translations: any;

  constructor(
    private configService: ConfigService,
    private translate: TranslationService,
  ) {
    this.appConfig = this.configService.getConfig();
  }

  transform(str: string) {
    return (this.translate.instant(str) || str)
      .replaceAll(this.appNameVar, this.appConfig.appName)
      .replaceAll(this.appVersionVar, this.appConfig.appVersionName)
    ;
  }

}
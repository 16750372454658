import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { ConfigService } from "src/app/services/core/config.service";

//import { SimplyLocalService } from 'src/app/services/monetization/simply-local.service';
//import { WeclappService } from 'src/app/services/integrations/weclapp.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  cards: walletCard[] = [
    {
      points: 1100,
      //subtitle: '+140 in den letzten 30 Tagen',
      title: '1.100 Punkte',
    }
  ];

  history: walletHistoryItem[] = [];

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private config: ConfigService,
    //private simplyLocal: SimplyLocalService,
    //private weclapp: WeclappService,
  ) {

  }

  add() {
    return this.pick();
  }

  calculateWalletPoints(basketInfo: basketInfo) {
    if (this.config.useWallet() && this.config.useSimplyLocalExtension()) {
      return parseInt(parseFloat(basketInfo.price_a as any) * 0.1 as any);
    }
    return 0;
  }

  async getAll() {
    let data: any[] = [];

    try {
      const serverSideWallets: any = await this.getServerSideWallets();
      console.log('serverSideWallets', serverSideWallets);

    } catch(e) {
      console.warn('loading server side wallets failed', e);
    }

    /*
    if (!!this.config.useSimplyLocalExtension()) {
      data.push({
        handler: this.simplyLocal,
        name: 'Simply Local',
        uid: 'simplylocal',
      });
    }

    if (!!this.config.useWeclappExtension()) {
      data.push({
        handler: this.weclapp,
        name: 'weclapp',
        uid: 'weclapp',
      });
    }
    */

  }

  getAllPointsAmount() {
    let iAmount: number = 0,
      cards: walletCard[] = this.getCards();

    if (cards && cards.length) {
      cards.forEach((card: walletCard) => {
        if (!!card.points) {
          iAmount += card.points;
        }
      });
    }

    return iAmount;
  }

  getCards() {
    return this.cards;
  }

  getHistory() {
    return this.history;
  }

  getServerSideWallets(blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['wallet', 'list'], null, blForceRefresh);
  }

  getWallet(blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('pipeline', {}, ['wallet'], null, blForceRefresh);
  }

  pay() {
    return new Promise((resolve, reject) => {
      this.pick()
        .then((response: any) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  pick() {
    return new Promise(async (resolve, reject) => {

      const wallets: any = await this.getAll(),
            data: any = (!!wallets && !!wallets.length ? wallets : []);

      const chooseConfig: chooseConfig = {
        data: data,
        labelKey: 'name',
        service: this,
        valueKey: 'uid',
      };
      
      this.chooser.choose(chooseConfig)
        .then((chooseResponse: chooseResponse) => {
          if (!chooseResponse) {
            reject('error_empty_choose_response');
          } else
            if (!chooseResponse.data) {
              reject('error_empty_choose_response_data');
            } else
              if (!chooseResponse.data.item) {
                reject('error_empty_choose_response_item');
              } else
                if (!chooseResponse.data.item.handler) {
                  reject('error_empty_choose_response_handler');
                } else {
                  chooseResponse.data.item.handler.pay().then(resolve).catch(reject);
                }
        })
        .catch(reject);
    });
  }

}
import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from 'src/app/services/core/modal.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-choose-avatar',
  templateUrl: './choose-avatar.page.html',
  styleUrls: ['./choose-avatar.page.scss'],
})
export class ChooseAvatarPage implements OnInit {

  state: state = {};
  
  view: any = {
    gender: 'm',
    hideOrderByBtn: true,
    hideSearch: true,
    title: 'choose_avatar',
  };

  constructor(
    private modalService: ModalService,
    private navParams: NavParams,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.view.avatars = this.navParams.get('avatars');
    this.view.gender = (this.userService.getGender() || 'm');
    this.view.genders = this.userService.getGenders();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  async dismiss(avatar: avatar = null) {
    (await this.modalService).dismiss(avatar);
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
  }

  use(avatar: avatar) {
    this.view.avatar = avatar;
  }

  update() {
    this.dismiss(this.view.avatar);
  }

}

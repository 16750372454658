import { Injectable } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Injectable({
  providedIn: 'root'
})
export class StablediffusionService {

  aiOptions: any = {
    auto_upscale: true,
    sampler_name: 'auto',
    use_safety_checker: true,
  };

  samplers: any[] = [
    {
      name: 'DDIM',
      uid: 'DDIM',
    },
    {
      name: 'DPM2 a',
      uid: 'DPM2 a',
    },
    {
      name: 'DPM++ 2S a',
      uid: 'DPM++ 2S a',
    },
    {
      name: 'DPM++ 2S a Karras',
      uid: 'DPM++ 2S a Karras',
    },
    {
      name: 'Euler',
      uid: 'Euler',
    },
    {
      name: 'Euler A',
      uid: 'EulerA',
    },
    {
      name: 'Heun',
      uid: 'Heun',
    },
    {
      name: 'k-diffusion',
      uid: 'k-diffusion',
    },
    {
      name: 'LMS',
      uid: 'LMS',
    },
    {
      name: 'PLMS',
      uid: 'PLMS',
    },
    {
      name: 'UniPC',
      uid: 'UniPC',
    },
  ];

  constructor(
    private aiBridge: AiBridgeService,
    private AppCMS: AppcmsService,
    private tools: ToolsService,
  ) {
  }

  audioToText(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['audioToText'], {}, blForceRefresh);
  }

  getAvailableInstances(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('stablediffusion', options, ['instances'], {}, blForceRefresh);
  }

  getDefaultAiOptions() {
    return this.aiOptions;
  }

  getQueue(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData(
      "stablediffusion",
      options,
      ["queue"],
      {},
      blForceRefresh
    );
  }

  getSamplers() {
    return this.samplers;
  }

  imageToImage(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToImage'], {}, blForceRefresh);
  }

  imageToText(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToText'], {}, blForceRefresh);
  }

  imageToVideo(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageToVideo'], {}, blForceRefresh);
  }

  imageVariations(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['imageVariations'], {}, blForceRefresh);
  }

  inpaint(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['inpaint'], {}, blForceRefresh);
  }

  optimizeProjectPromptAttributes(project: project, key: string) {
    return new Promise((resolve, reject) => {

      if (!project.config.assets[key] || (project.config.assets[key] === '')) {
        switch (key) {
          case 'negative_prompt':
            project.config.assets[key] = 'bad quality, worst quality, ugly, deformed';
            break;
          case 'prompt_suffix':
            project.config.assets[key] = 'best quality, masterpiece, pixelperfect, ultrarealistic';
            break;
          default:
            project.config.assets[key] = '';
            break;
        }
      }

      const history: any[] = [
        {
          role: 'system',
          content: `Optimize the parameters for an optimal AI text-to-image result.
          Automatically recognize the language and translate all keywords to english language.
          Important: Only answer with an optimized, comma-separated list of English keywords based on the input (6-12 keywords).`,
        }
      ];

      if (typeof project.config.assets[key] === 'object') {
        project.config.assets[key] = this.tools.trim(project.config.assets[key].map((attribute: any) => {
          return attribute.title || attribute.uid;
        }).join(','));
      }

      this.aiBridge.execute({
        context: 'text_optimization',
        history: history,
        post_content: `${project.config.assets[key] || ''}`,
      }, true)
        .then((response: any) => {
          response.items = [];

          if (!!response && !!response.output) {
            response.items = response.output.split(',').map((tag: string) => {
              tag = this.tools.trim(tag);
              return { title: tag, uid: tag };
            });
          }

          resolve(response);
        })
        .catch(reject);
    });
  }

  outpaint(url: string, options: any = {}, blForceRefresh: boolean = true) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['outpaint'], {}, blForceRefresh);
  }

  speechToText(url: string, options: any = {}, blForceRefresh: boolean = true) {
    return this.audioToText(url, options, blForceRefresh);
  }

  sv3d(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['sv3d'], {}, blForceRefresh);
  }

  sv4d(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.url = `${url}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['sv4d'], {}, blForceRefresh);
  }

  textToImage(input: string, options: any = {}, blForceRefresh: boolean = false) {
    options.input = `${input}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['textToImage'], {}, blForceRefresh);
  }

  textToVideo(input: string, options: any = {}, blForceRefresh: boolean = false) {
    options.input = `${input}`;
    return this.AppCMS.loadPluginData('stablediffusion', options, ['textToVideo'], {}, blForceRefresh);
  }

  transcribe(url: string, options: any = {}, blForceRefresh: boolean = true) {
    return this.audioToText(url, options, blForceRefresh);
  }

  upscale(url: string, options: any = {}, blForceRefresh: boolean = false) {
    options.inputFile = `${url}`;
    options.outputFile = `${url}`;

    return this.AppCMS.loadPluginData('stablediffusion', options, ['upscale'], {}, blForceRefresh);
  }

  videoToText(url: string, options: any = {}, blForceRefresh: boolean = true) {
    return this.audioToText(url, options, blForceRefresh);
  }

}
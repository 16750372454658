import { ElementRef, Injectable, ViewChild } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class IntroService {
  @ViewChild('tabBar', { read: ElementRef }) tabBarElement: ElementRef;

  _loadedSliderPage: boolean = false;

  introJS: any;

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private events: EventsService,
    private translate: TranslationService,
    private userService: UserService,
  ) {
  }

  async getOptions() {
    return new Promise((resolve, reject) => {
      this.translate.get([
        'next', 'prev', 'skip', 'done',
        'intro_1',
        'intro_2', 'intro_2_1',
        'intro_3',
        'intro_4',
        'intro_5',
        'intro_6',
        'intro_finished',
      ])
        .subscribe((response: any) => {
          let intro5element = document.querySelectorAll('ion-slide:nth-child(2) ion-row:nth-child(2) ion-col:nth-child(1)')[0];

          resolve({
            nextLabel: response.next,
            prevLabel: response.prev,
            skipLabel: response.skip,
            doneLabel: response.done,
            steps: [
              {
                intro: response.intro_1,
              },
              {
                element: document.querySelectorAll('ion-slide:nth-child(1) ion-row:nth-child(1) ion-col:nth-child(1)')[0],
                intro: response.intro_2,
              },
              {
                element: document.querySelectorAll('ion-slide:nth-child(1) ion-row:nth-child(2) ion-col:nth-child(1)')[0],
                intro: response.intro_2_1,
                position: 'top',
              },
              {
                element: document.querySelectorAll('ion-buttons[slot=start] ion-button')[0],
                intro: response.intro_3,
                position: 'right'
              },
              {
                element: document.querySelectorAll('ion-menu-button')[0],
                intro: response.intro_4,
                position: 'left'
              },
              {
                element: intro5element,
                intro: response.intro_5,
                position: 'bottom'
              },
              {
                intro: response.intro_finished
              }
            ]
          });
        });
    });
  }

  async init() {
    try {
      await this.loadServerSideData();
    } catch(e) {
      console.warn('setup init failed', e);
    }
  }

  async isIntroCardHidden(cardName: string, bl: boolean|null = null) {
    let key: string = `introCardHidden_${cardName}`;
    if(bl !== null) {
      this.cache.set(key, bl);
    } else {
      const fromCache: cacheItem = await this.cache.get(key, -1);
      return (fromCache && fromCache.data ? !!fromCache.data : false);
    }
  }

  async loadedSliderPage(bl: boolean|null = null) {
    if (bl !== null) {
      this._loadedSliderPage = !!bl;
      this.cache.set('loadedSliderPage', this._loadedSliderPage);
    } else {
      const fromCache: cacheItem = await this.cache.get('loadedSliderPage', -1);
      return fromCache && fromCache.data ? !!fromCache.data : this._loadedSliderPage;
    }
  }

  loadServerSideData(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {}, ['intro', 'data'], {}, blForceRefresh)
      .then((response: any) => {
        console.log('intro data', response);
        resolve(response);
      })
      .catch((error: any) => {
        console.warn('setup: loading server-side intro data failed', error);
        reject(error);
      });
    })
  }

  onBeforeChange(target: any) {
    switch (this.introJS._currentStep) {
      case 2:
        this.events.publish('home:view:category', {
          uid: 'discover',
          speed: 500
        });
        this.introJS.refresh();
        break;
      case 5:
        this.events.publish('home:view:category', {
          uid: 'discover',
          speed: 500
        });
        this.introJS.refresh();
        break;
    }
  }

  async shouldUse(key: string|null = null, bl: boolean|null = null) {
    if (bl !== null) {
      let set = await this.userService.setClassification(key, bl);
      return set;
    } else {
      key = key || 'blShouldUseIntro';
      let should: cacheItem = await this.userService.getClassification(key);
      return !!should && (!!should.data !== false);
    }
  }

  async show() {
    this.introJS.start();
  }

}

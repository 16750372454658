import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private storage: Storage,
  ) {
    this.init();
  }

  clear() {
    return this.storage.clear();
  }

  get(key: string) {
    return this.storage.get(key);
  }

  init() {
    this.storage.create();  
  }

  remove(key: string) {
    return this.storage.remove(key);
  }

  set(key: string, value: any) {
    return this.storage.set(key, value);
  }

}

import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { UserService } from 'src/app/services/core/user.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  
  ordersKey: string = 'orders';
  orders: any = window.localStorage.getItem(this.ordersKey) ? JSON.parse(window.localStorage.getItem(this.ordersKey)) : [];
  
  plannedKey: string = 'plannedOrders';
  planned: any = window.localStorage.getItem(this.plannedKey) ? JSON.parse(window.localStorage.getItem(this.plannedKey)) : [];

  iCurrentOrder: number;

  tmpOrder: any;

  constructor(
    private AppCMS: AppcmsService,
    private basket: BasketService,
    private userService: UserService,
  ) {
  }

  addOrder(order: any) {
    return new Promise(async (resolve, reject) => {
      this.orders.push(order);
      let user = this.userService.getUser() || {};
      if(user && user.uid) {
        let basketInfo = await this.basket.calculateBasketInfo();
        
        user.classifications = user.classifications || {};

        user.classifications.points = parseFloat(user.classifications.points || 0);
        user.classifications.points += parseFloat(basketInfo.price_a + '');

        user.classifications.orders = user.classifications.orders || [];
        user.classifications.orders.push(order.id);

        this.userService.setUser(user, true).then(resolve).catch(reject);
      } else {
        this.setOrders(this.orders);
        resolve(this.orders);
      }
    });
  }

  async addPlannedOrder(order: any) {
    order.items = order.items || [];
    this.planned.push(order);

    let user = this.userService.getUser() || {};
    if(user && user.uid) {
      let basketInfo = await this.basket.calculateBasketInfo();
      
      user.classifications = user.classifications || {};

      user.classifications.points = parseFloat(user.classifications.points || 0);
      user.classifications.points += parseFloat(basketInfo.price_a + '');

      user.classifications.planned = user.classifications.planned || [];
      user.classifications.planned.push(order.id);

      this.userService.setUser(user, true);
    }

    return this.setPlanned(this.planned);
  }

  addToPlannedOrder(product: any, iCurrentOrder: number = null) {
    return new Promise((resolve, reject) => {
      this.planned[iCurrentOrder].items.push(product);
      this.setPlanned(this.planned);
      resolve(this.planned[iCurrentOrder]);
    });
  }

  createTmpOrder() {
    return this.setTmpOrder({});
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.orders.forEach((order: any) => {
        order.date_created_formatted = moment(order.date_created).format('DD.MM.YYYY, HH:mm');
        
        if(order.meta_data) {
          order.meta_data.forEach((metaDataItem: any) => {
            if(metaDataItem.key === '_orddd_lite_timestamp') {
              order.delivery_date = metaDataItem.value;
            }
          });
        }

        if(order.delivery_date && order.customer_note) {
          order.delivery_date += ' ' + order.customer_note.split('-')[0];
        }

        if(order.line_items) {
          order.line_items.forEach((item: any) => {
            item.price = (parseFloat(item.total) + parseFloat(item.total_tax)).toFixed(2);
          });
        }

      });
      resolve(this.orders);
    });
  }

  getByTime(time: number, deviceId: number) {
    return new Promise((resolve, reject) => {
      if(!deviceId) {
        reject('missing_device_uid');
      } else {
        this.AppCMS.loadPluginData('devices', {
          time: time,
        }, [deviceId, 'orders']).then(resolve).catch(reject);
      }
    });
  }

  getByUser(userId: number = null, blForceRefresh: boolean = false, options: any = {}) {
    userId = userId || this.userService.getUid();

    return this.AppCMS.loadPluginData(
      'orders',
      Object.assign(options, {
        
      }),
      ['user', userId],
      {},
      blForceRefresh
    );
  }

  getPlanned() {
    return this.planned;
  }

  getPlannedOrder() {
    return this.planned[this.iCurrentOrder || (this.planned.length-1) ];
  }

  getTmpOrder() {
    return this.tmpOrder;
  }

  setOrders(orders: any) {
    this.orders = orders;
    window.localStorage.setItem(this.ordersKey, JSON.stringify(this.orders));
  }

  setPlanned(orders: any) {
    this.planned = orders;
    window.localStorage.setItem(this.plannedKey, JSON.stringify(this.planned));
  }

  setPlannedOrder(iCurrentOrder: number) {
    this.iCurrentOrder = iCurrentOrder;
  }

  setTmpOrder(order: any) {
    this.tmpOrder = order;
    return this.tmpOrder;
  }

}

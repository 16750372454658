import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

import { AlertController } from "@ionic/angular";
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from "src/app/services/core/events.service";
import { FriendsService } from "src/app/services/social/friends.service";
import { NetworkService } from "src/app/services/core/network.service";
import { ScoreService } from "src/app/services/utils/score.service";
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';
import { PostsService } from "src/app/services/posts/posts.service";
import { MessagesService } from "src/app/services/social/messages.service";

@Injectable({
  providedIn: "root",
})
export class CollectionsService {

  _detailItem: collectionItem | null;

  collections: any = [];

  constructor(
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private events: EventsService,
    private friends: FriendsService,
    private messages: MessagesService,
    private network: NetworkService,
    private posts: PostsService,
    private score: ScoreService,
    private translations: TranslationService,
    private userService: UserService,
    private _sanitizer: DomSanitizer
  ) {
  }

  /**
   * @param itemId
   * @param type 
   * @param collectionData 
   * @returns 
   */
  add(itemId: number, type: string, collectionData: any = {}) {
    return new Promise((resolve, reject) => {
      let parentId = this.userService.getUid();

      let collectionItem: collectionItem = Object.assign(collectionData, {
        user: parentId,
        item: itemId,
        type: type,
      });

      this.AppCMS.loadPluginData(
        "pipeline",
        {
          item: collectionItem,
        },
        ["collections", "add"]
      )
        .then((createResponse: any) => {

          this.events.publish("toast", {
            message: 'added_to_profile',
            color: "primary",
          });

          this.cache.remove("collections_byUser_" + parentId);

          try {
            this.score.increase(25)
              .catch((error) => {
                console.error('score error', error);
              });
          } catch (e) {
            console.error('score error', e);
          }

          this.messages
            .sendPushToFriends({
              data: {
                action: "viewPost",
                item: createResponse.item,
              },
            })
            .catch((error: any) => {
              console.warn("push error", error);
              resolve(createResponse);
            });
          resolve(createResponse);
        })
        .catch(reject);
    });
  }

  /**
   * @param collection 
   * @returns 
   */
  create(collection: collectionItem) {
    return this.AppCMS.loadPluginData(
      "pipeline",
      {
        collection: collection,
      },
      ["collections", "create"]
    );
  }

  /**
   * @param item 
   * @param type 
   * @param collectionData 
   * @returns 
   */
  delete(collectionId: number) {
    return new Promise((resolve, reject) => {
      let parentId: number = this.userService.getUid();

      this.AppCMS.loadPluginData(
        "pipeline",
        {
          item: collectionId,
        },
        ["collections", "delete"]
      )
        .then((deleteResponse: any) => {
          this.events.publish('profile:refresh');

          this.events.publish("toast", {
            message: 'removed_from_collections',
            color: "primary",
          });

          this.cache.remove("collections_byUser_" + parentId);
          resolve(deleteResponse);
        })
        .catch(reject);
    });
  }

  detailItem(item: collectionItem | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  /**
   * @param type 
   * @returns 
   */
  get(type: string) {
    return new Promise((resolve, reject) => {
      this.getByProfileUid()
        .then((collections: any) => {
          resolve(collections[type] || []);
        })
        .catch(reject);
    });
  }

  /**
   * @param profileId 
   * @param blForceRefresh 
   * @param options 
   * @returns 
   */
  getByProfileUid(
    profileId: number = null,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return new Promise(async (resolve, reject) => {
      const user = this.userService.getUser();
      if (!user || !user.uid) {
        reject("error_missing_user");
      } else {
        const key: string = "collections_byUser_" + profileId + "_" + options,
          fromCache: cacheItem = await this.cache.get(key, 30 * 60);

        if (fromCache && fromCache.data && !blForceRefresh) {
          resolve(this.parseCollections(fromCache.data));
        } else {

          const filter: any = Object.assign(options.filter || {}, {
            user: profileId || user.uid,
          });

          const params: any = Object.assign(options, {
            filter: filter,
          });

          this.AppCMS.loadPluginData("pipeline", params, ["collections"])
            .then((collectionsResponse: collectionsResponse) => {
              this.friends
                .asCols(profileId, null, blForceRefresh)
                .then((friendsRows: user[]) => {

                  const collections = Object.assign(collectionsResponse || {}, {
                    people: friendsRows,
                  });

                  this.collections = this.parseCollections(collections);

                  this.cache.set(key, this.collections);
                  resolve(this.collections);
                })
                .catch(reject);
            })
            .catch(reject);
        }
      }
    });
  }

  /**
   * 
   * @todo Improve this method to call the collection directly
   * 
   * @param collectionId
   * @param blForceRefresh 
   * @returns 
   */
  getByUid(
    collectionId: number = null,
    blForceRefresh: boolean = false,
  ) {
    return new Promise(async (resolve, reject) => {
      const user = this.userService.getUser();
      if (!user || !user.uid) {
        reject("error_missing_user");
      } else {
        const key: string = "collections_byUid_" + collectionId,
          fromCache: cacheItem = await this.cache.get(key, 30 * 60);

        if (fromCache && fromCache.data && !blForceRefresh) {
          resolve(this.parseCollections(fromCache.data));
        } else {

          let filter: any = {
            uid: collectionId,
          };

          let params: any = {
            filter: filter,
          };

          this.AppCMS.loadPluginData("pipeline", params, ["collections"])
            .then((collectionsResponse: collectionsResponse) => {
              if (collectionsResponse.custom) {

                const collectionLookup: collectionsResponseItem[] = (collectionsResponse.custom as any).filter((collectionsResponseItem: collectionsResponseItem) => {
                  return collectionsResponseItem.uid === collectionId;
                });

                const collection = this.parseCollection(collectionLookup && collectionLookup[0] ? collectionLookup[0] : []);

                this.cache.set(key, collection)
                  .then(() => {
                    resolve(collection);
                  })
                  .catch(reject);
              } else {
                reject(collectionsResponse);
              }
            })
            .catch(reject);
        }
      }
    });
  }

  /**
   * @param profileId 
   * @param blForceRefresh 
   * @param options 
   * @returns 
   */
  getCustomCollectionNames(
    profileId: number = null,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    return new Promise((resolve, reject) => {
      options.filter = options.filter || {};
      options.filter.type = "custom";

      if (!options.hasOwnProperty("people")) {
        options.people = false;
      }

      this.getByProfileUid(profileId, blForceRefresh, options)
        .then((collections: any) => {
          resolve(collections.custom || []);
        })
        .catch(reject);
    });
  }

  /**
   * @param profileId 
   * @param blForceRefresh 
   * @param options 
   * @returns 
   */
  async getNamesByProfileUid(
    profileId: number = null,
    blForceRefresh: boolean = false,
    options: any = {}
  ) {
    let collections = await this.getByProfileUid(
      profileId,
      blForceRefresh,
      options
    );
    return Object.keys(collections);
  }

  /**
   * Check if an item is inside a user's collection
   * (check if it's bookmarked)
   * 
   * @param item
   * @returns bool
   */
  inCollection(item: any) {
    return new Promise(async (resolve, reject) => {
      let blConnected: boolean = await this.network.isConnected();

      if (!blConnected) {
        this.network.showOfflineMessage();
        resolve({});
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          {
            uid: item.uid,
            parent: this.userService.getUid(),
          },
          ["collections", "inCollection"]
        )
          .then((response: any) => {
            resolve(!!response.result);
          })
          .catch(reject);
      }
    });
  }

  /**
   * Parse a single collection
   * 
   * @param collectionItem
   * @returns 
   */
  parseCollection(collectionItem: any) {
    collectionItem.uid =
      collectionItem.uid || collectionItem.ID || collectionItem.id;

    collectionItem.icon = collectionItem.icon || 'folder-outline',
      collectionItem.type = collectionItem.type || collectionItem.post_type;
    collectionItem.style = collectionItem.style || {};
    collectionItem.color = collectionItem.color || "light";

    let wpImage = collectionItem.images
      ? collectionItem.images[0].src
      : (collectionItem.thumbnail || collectionItem.image);

    if (wpImage && wpImage.length) {
      collectionItem.thumbnail = wpImage;
      collectionItem.style.backgroundUrl =
        this._sanitizer.bypassSecurityTrustStyle(`url('${wpImage}')`);
    } else if (
      collectionItem.style &&
      collectionItem.style.backgroundUrl &&
      collectionItem.style.backgroundUrl.changingThisBreaksApplicationSecurity
    ) {
      collectionItem.style.backgroundUrl =
        this._sanitizer.bypassSecurityTrustStyle(
          collectionItem.style.backgroundUrl
            .changingThisBreaksApplicationSecurity
        );
    } else
      if (collectionItem.items && collectionItem.items[0]) {
        collectionItem.thumbnail = collectionItem.items[0].thumbnail || collectionItem.items[0].image;
        collectionItem.style.backgroundUrl = this._sanitizer.bypassSecurityTrustStyle(`url('${collectionItem.thumbnail}')`);
      } else
        if (collectionItem.image && collectionItem.image.length) {
          collectionItem.thumbnail = collectionItem.image;
          collectionItem.style.backgroundUrl = this._sanitizer.bypassSecurityTrustStyle(`url('${collectionItem.image}')`);
        }

    if (collectionItem.items) {
      collectionItem.items = this.posts.getFullPosts(collectionItem.items);
    }

    delete collectionItem.added;
    delete collectionItem.color;
    delete collectionItem.comment_status;
    delete collectionItem.comment_count;
    delete collectionItem.filter;
    delete collectionItem.ID;
    delete collectionItem.ping_status;
    delete collectionItem.to_ping;
    delete collectionItem.pinged;
    delete collectionItem.post_author;
    delete collectionItem.post_type;
    delete collectionItem.menu_order;
    delete collectionItem.post_content_filtered;
    delete collectionItem.post_status;
    delete collectionItem.post_date;
    delete collectionItem.post_modified;
    delete collectionItem.post_name;
    delete collectionItem.post_parent;
    delete collectionItem.post_password;

    return collectionItem;
  }

  /**
   * Parse a list of collections
   * 
   * @param collections 
   * @returns 
   */
  parseCollections(collections: any) {
    Object.keys(collections).forEach((collectionName: string) => {
      if (
        collections[collectionName] &&
        collections[collectionName].items &&
        collections[collectionName].items.length
      ) {
        collections[collectionName].items.forEach((collectionItem: any, index: number) => {
          collections[collectionName].items[index] = this.parseCollection(collectionItem);
        });
      } else
        if (typeof collections[collectionName] === 'object' && collections[collectionName].length) {
          collections[collectionName].forEach((collectionItem: any, index: number) => {
            collections[collectionName][index] = this.parseCollection(collectionItem);
          });
        }
    });

    return collections;
  }

  /**
   * Remove an item from a collection
   * 
   * @param item 
   * @param collection 
   * @returns 
   */
  removeFromCollection(item: any, collection: string) {
    item.uid = item.uid || item.id;
    return new Promise((resolve, reject) => {
      let parentId = this.userService.getUid();
      if (!parentId) {
        reject("error_missing_parent_uid");
      } else if (!item.uid) {
        reject("error_missing_item_uid");
      } else {
        this.AppCMS.loadPluginData(
          "pipeline",
          {
            filter: {
              item: item.uid,
              user: parentId,
            },
          },
          ["collections", "delete"]
        )
          .then((response: any) => {
            this.events.publish('profile:refresh');

            this.events.publish("toast", {
              message: 'removed_from_profile',
              color: "primary",
            });

            this.cache.remove("collections_byUser_" + parentId);
            resolve(response);
          })
          .catch(reject);
      }
    });
  }

  showCreateFolderDialog() {
    return new Promise((resolve, reject) => {
      this.translations
        .get([
          "cancel",
          "create_folder_dialog_header",
          "create_folder_dialog_message",
          "enter_folder_name",
          "folder_name",
          "okay",
        ])
        .subscribe(async (translations: any) => {
          const alert: any = await this.alertCtrl.create({
            header: translations.enter_folder_name,
            message: translations.create_folder_dialog_message,
            inputs: [
              {
                type: "text",
                name: "folder_name",
                placeholder: translations.folder_name,
              },
            ],
            buttons: [
              {
                text: translations.okay,
                handler: (data: any) => {
                  let error: string | null = null;

                  if (!data.folder_name || !data.folder_name.length) {
                    error = "missing_folder_name";
                    return false;
                  }

                  if (error) {
                    alert.inputs[0].cssClass = "danger";
                    this.events.publish("error", error);
                    return false;
                  }

                  this.create({
                    name: data.folder_name,
                    type: "custom",
                  })
                    .then(resolve)
                    .catch(reject);
                },
              },
              {
                text: translations.cancel,
                role: "cancel",
              },
            ],
          });

          alert.onWillDismiss().then((data: any) => {
            resolve(data);
          });

          alert.present();
        });
    });
  }

  /**
   * Update a collection
   * 
   * @param collection 
   * @returns {
   *  success: bool
   * }
   */
  update(collection: collectionsResponseItem, settings: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      collection: collection,
      settings: settings,
    }, ['collections', 'update']);
  }

}
import { Injectable } from "@angular/core";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';

import { TranslateService } from "@ngx-translate/core";
import { Translation, Language } from '@capacitor-mlkit/translation';

@Injectable({
  providedIn: "root",
})
export class TranslationService {

  _detailItem: translationItem|null;
  
  historyKey: string = 'pipeline_translation_history';

  translationServiceName: string = 'googleextend';

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private translateService: TranslateService
  ) {

  }

  async addToHistory(item: any) {
    let history: any[] = await this.getHistory();
    history.push(item);
    
    return this.setHistory(history);
  }

  deleteDownloadedModel = async () => {
    await Translation.deleteDownloadedModel({
      language: Language.English,
    });
  };
  
  detailItem(item: translationItem|null = null) {
    
    if(item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  downloadModel = async () => {
    await Translation.downloadModel({
      language: Language.English,
    });
  };

  get(key: string | string[]) {
    return this.translateService.get(key);
  }

  getDownloadedModels = async () => {
    const { languages } = await Translation.getDownloadedModels();
    return languages;
  };

  async getHistory() {
    const fromCache: cacheItem|null = await this.cache.get(this.historyKey, -1);
    return (!!fromCache && !!fromCache.data ? (fromCache.data || []) : []);
  }

  getRemoteTranslations(blForceRefresh: boolean = false, options: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', options, ['translations'], null, blForceRefresh);
  }

  getTranslationServiceName() {
    return this.translationServiceName;
  }

  instant(key: string|string[]) {
    return this.translateService.instant(key);
  }

  setTranslationService(service: string) {
    this.translationServiceName = service;
    return this;
  }

  setHistory(history: any[]) {
    return this.cache.set(this.historyKey, history);
  }

  translate(text: string, target: string|null = null, source: string|null = null) {
    return new Promise(async (resolve, reject) => {
      let service: string = this.getTranslationServiceName();
      const key: string = `translation_${service}_${source}_${target}_${text}`;
      const fromCache: cacheItem = await this.cache.get(key, 60 * 60 * 60);

      if (fromCache && fromCache.data) {
        resolve(fromCache.data);
      } else {
        this.AppCMS.loadPluginData(
          service,
          {
            source: source,
            target: target,
            text: text,
          },
          ["translate"]
        )
          .then((response: any) => {
            if (response.success && response.response) {
              this.cache.set(key, response.response);
              resolve(response.response);
            } else {
              reject(response);
            }
          })
          .catch(reject);
      }
    });
  }

  translateItems(items: any) {
    return new Promise((resolve, reject) => {
      let iCurrent = 0;
      items.forEach((item: any) => {
        this.translate(item.post_content)
          .then((translation: translationItem) => {
            item.post_content = translation;

            this.translate(item.post_title)
              .then((translation: translationItem) => {
                iCurrent++;
                item.post_title = translation;
                item.name = translation;
                if (iCurrent === items.length) {
                  resolve(items);
                }
              })
              .catch((error: any) => {
                iCurrent++;
                if (iCurrent === items.length) {
                  console.warn("finished with error", error);
                  resolve(items);
                }
              });
          })
          .catch((error: any) => {
            iCurrent++;
            this.translate(item.post_title)
              .then((translation: translationItem) => {
                item.post_title = translation;
                if (iCurrent === items.length) {
                  resolve(items);
                }
              })
              .catch((error: any) => {
                if (iCurrent === items.length) {
                  console.warn("finished with error", error);
                  resolve(items);
                }
              });
          });
      });
    });
  }

  translateoffline = async (input: string, target: string, source: string) => {

    const { text } = await Translation.translate({
      text: `${input}`,
      sourceLanguage: (source as any),
      targetLanguage: (target as any),
    } as any);

    return text;
  };

  translatePost(post: post, target: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline',
      {
        post: post,
        target: target,
      },
      ["translations", 'translate', 'post'],
      {},
      blForceRefresh,
    )
  }

}
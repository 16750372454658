import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';

import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { IAPError, IAPProduct, IAPQueryCallback, InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { StripeService } from '../payments/stripe.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  appConfig: pipelineAppConfig;

  basketProductPrices: any = {
    'de.tie.vip.basic': 1.09,
    'page.pipeline.creators.vip': 1.09,
    'page.pipeline.creators.basic': 4.49,
    'page.pipeline.creators.premium': 9.99,
  };

  productList: any[];

  constructor(
    private configService: ConfigService,
    private events: EventsService,
    private platform: Platform,
    private store: InAppPurchase2,
    private tools: ToolsService,
    private loading: LoadingController,
    private stripe: StripeService,
  ) {
    this.appConfig = this.configService.getConfig();
  }
  
  getBasketProduct(product: any) {
    let productList: any = this.getBasketProductList();

    let select = productList.filter((productListItem: any) => {
      return productListItem.id === (product.name || product);
    });

    return (select && select[0] ? select[0] : null);
  }

  getBasketProductList() {
    let productList = this.getProductList();

    if(productList && productList.length) {
      productList.forEach((basketProduct: any) => {
        basketProduct.price = this.basketProductPrices[basketProduct.id];
      });
    }

    return productList;
  }

  getProductList() {
    if(!this.productList) {
      this.productList = [
        {
          id: `${this.appConfig.appId}.vip.discount`, // VIP-TICKET (50% off) for BASIC users
          type: this.store.CONSUMABLE,
        },
        {
          id: `${this.appConfig.appId}.vip`,          // NORMAL VIP TICKET
          type: this.store.CONSUMABLE,
        },
        {
          id: `${this.appConfig.appId}.basic`,        // BASIC ABO
          type: this.store.PAID_SUBSCRIPTION,
        },
        {
          id: `${this.appConfig.appId}.premium`,      // PREMIUM ABO
          type: this.store.PAID_SUBSCRIPTION,
        },
      ];
    }
    return this.productList;
  }

  init() {
    let productList = this.getProductList();

    // After platform ready
    this.store.verbosity = this.store.DEBUG;
    this.store.register(productList);

    if(productList && productList.length) {
      productList.forEach((product: any) => {
        this.initProduct(product);
      });
    }

    this.store.error( (err) => {
      console.error('Store Error ' + JSON.stringify(err));
    });

    this.store.ready(() =>  {
      console.log('purchase: store', 'ready');
    });

    this.store.validator = (product: IAPProduct, callback) => {
      this.validator(product, callback);
    };

    this.store.refresh();
  }

  initProduct(product: any) {

    // Register event handlers for the specific product
    this.store.when(product.id).registered( (product: IAPProduct) => {
      console.log(product.id,'Registered: ' + JSON.stringify(product));
    });

    this.store.when(product.id).approved(this.onProductApproved);
    this.store.when(product.id).updated(this.onProductUpdated);
    this.store.when(product.id).cancelled(this.onProductCancelled);
    this.store.when(product.id).error(this.onProductError);
  }

  onProductApproved: IAPQueryCallback = (product: IAPProduct) => {
    console.log('onProductApproved', product);
    this.events.publish('purchase:approved', product);
  }

  onProductCancelled: IAPQueryCallback = (product: IAPProduct) => {
    console.log('onProductCancelled', product);
    this.events.publish('purchase:cancelled', product);
  }

  onProductError: IAPQueryCallback = (error: IAPError) => {
    console.warn('onProductError', error);
    this.events.publish('purchase:error', error);
  }

  onProductUpdated: IAPQueryCallback = (product: IAPProduct) => {
    console.info('onProductUpdated', product);
    this.events.publish('purchase:updated', product);
    //this.refreshProduct(product);
  }

  purchase(product: any) {
    return new Promise((resolve, reject) => {
      if(this.platform.is('ios')) {
        this.purchaseUsingApple(product).then(resolve).catch(reject);
      } else
      if(this.platform.is('android')) {
        this.purchaseUsingGoogle(product).then(resolve).catch(reject);
      } else {
        this.purchaseUsingWebPayments(product).then(resolve).catch(reject);
      }
    });
  }

  purchaseUsingApple(product: any) {

    if(this.tools.isWeb()) {
      return this.purchaseUsingWebPayments(product);
    }

    return new Promise(async (resolve, reject) => {
      const loading: any = await this.loading.create();
      loading.present();

      this.store.order(product.name || product)
      .then(
        (response: any) => {
          loading.dismiss();
          resolve(response);
        },
        (error: any) => {
          loading.dismiss();
          console.error('in app purchase error', error);
          reject(error.errorMessage || error);
        }
      );
    });
  }

  purchaseUsingGoogle(product: any) {

    if(this.tools.isWeb()) {
      return this.purchaseUsingWebPayments(product);
    }

    return new Promise((resolve, reject) => {
      this.store.order(product.name || product)
      .then(
        (response: any) => {
          resolve(response);
        },
        (error: any) => {
          console.log('in app purchase error', error);
          reject(error.errorMessage || error);
        }
      );
    });
  }
  
  purchaseUsingWebPayments(product: any)
  {
    return new Promise(async (resolve, reject) => {

      // set current product as stripe payment summary items
      this.stripe.setPaymentSummaryItems([product]);

      try {
        let stripeProcess: any = await this.stripe.purchaseUsingWeb();
        console.log('stripeProcess', stripeProcess);

        resolve(stripeProcess);
      } catch(e) {
        console.warn('stripe payment sheet failed', e);
        reject(e);
      }

      /*
      let product = this.getBasketProduct(productName);
  
      this.translations.get([
        productName
      ])
      .subscribe((translations: any) => {
        this.basket.add({
          uid: product.id,
          name: translations[productName],
          price: product.price,
        })
        .then(() => {
          this.modalService.dismiss(null, 'done');
          this.events.publish('view:basket');
        })
        .catch(reject);
      });
      */
    });
  }

  validator(product: IAPProduct, callback) {
    
    const transactionType: string = (this.platform.is('ios') ? 'ios' : 'android');
    const transactionReceipt: string = (this.platform.is('ios') ?
      (product.transaction as any).appStoreReceipt :
      (product.transaction as any).receipt);
    
    console.log('> transactionType', transactionType);
    console.log('> transactionReceipt', transactionReceipt);

    callback(true, product.transaction);
    //this.store.dispatch(new RefreshUserAction());
    
    //this.showSuccessPurchaseMessage();
    product.finish();
    
    console.log('inAppPurchase.validator', JSON.stringify(product));
  }

}

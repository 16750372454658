<ion-header class="ion-no-border">

    <ion-toolbar class="ion-no-border">

        <ion-buttons slot="start">
            <ion-back-button *ngIf="!isModal"></ion-back-button>
            <ion-button *ngIf="isModal" icon-only (click)="dismiss()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>

    </ion-toolbar>

    <ion-title *ngIf="!isModal && page && page.name" [innerHTML]="page.name"></ion-title>

</ion-header>

<ion-content class="ion-padding">

    <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
    </ion-refresher>

    <ion-header *ngIf="page && page.name" collapse="condense" class="ion-no-border">
        <h1 [innerHTML]="page.name"></h1>
    </ion-header>

    <ion-spinner name="circular" [hidden]="page"></ion-spinner>

    <p *ngIf="page && page.value" [innerHTML]="page.value"></p>

</ion-content>
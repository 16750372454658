import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ModalService } from 'src/app/services/core/modal.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

import { RatePostPage } from 'src/app/pages/ratings/rate-post/rate-post.page';

@Injectable({
  providedIn: 'root'
})
export class RatingsService {

  constructor(
    private AppCMS: AppcmsService,
    private modalService: ModalService,
    private toast: ToastService,
    private translation: TranslationService,
    private userService: UserService,
  ) {

  }

  get(filter: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: filter,
    }, ['ratings']);
  }

  getAiRatings(filter: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: filter,
    }, ['ratings', 'ai']);
  }
  
  getByPosts(filter: any = {}) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: filter,
    }, ['ratingsByPosts']);
  }

  rate(post: post) {
    return new Promise(async (resolve, reject) => {
      const user = this.userService.getUser();

      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return false;
      }

      const modal: any = await this.modalService.create({
        component: RatePostPage,
        componentProps: {
          post: post,
        },
        animated: true,
        presentingElement: await this.modalService.getTop(),
      });

      modal.onWillDismiss().then((response: any) => {
        if(response && response.data) {
          this.submit(response.data).then(resolve).catch(reject);
        }
      });

      this.modalService.present(modal);
    });
  }

  showRatingErrorMessage(error: string = null) {
    this.translation.get('pipeline_ai_rating_failed')
    .subscribe((response: any) => {
      this.toast.show(error || (response || 'pipeline_ai_rating_failed'));
    });
  }

  showRatingSuccessMessage() {
    this.translation.get('pipeline_ai_rating_success')
    .subscribe((response: any) => {
      this.toast.show(response || 'pipeline_ai_rating_success');
    });
  }

  submit(rating: any) {
    return new Promise((resolve, reject) => {
      rating = JSON.parse(JSON.stringify(rating));
      delete rating.post.relatedArticles;

      const user = this.userService.getUser() || {};
      rating.user = user.uid;

      if(rating && rating.post && rating.post.uid) {
        rating.post = rating.post.uid;
      }

      delete rating.post_content_formatted;
      delete rating.thumbnailBackground;
      delete rating.style;
      
      this.AppCMS.loadPluginData('pipeline', {
        rating: rating,
      }, ['ratings', 'submit']).then(resolve).catch(reject);
    });
  }
  
  submitAiRating(item: any, rating: number, type: string) {
    item = JSON.parse(JSON.stringify(item));
    item.rating = rating;
    
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData('pipeline', {
        item: item,
        rating: rating,
        type: type,
      }, ['ai', 'rate'])
      .then((response: any) => {
        this.showRatingSuccessMessage();
        resolve(response);
      })
      .catch((error: any) => {
        this.showRatingErrorMessage(error);
        reject(error);
      });
    });
  }

}

<ion-header class="ion-no-border">
  
  <ion-toolbar class="ion-no-border container">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
      
    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="submitMultiple()" shape="round" color="primary" fill="solid">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'done'|translate"></ion-label>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>
  
  <pipeline-selection-toolbar [(items)]="view.data" [options]="view.selectionOptions" [(view)]="view" (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>

</ion-header>

<ion-content fullscreen="true">
  <div class="container">

    <pipeline-no-entries-card *ngIf="view.data && !view.data.length"></pipeline-no-entries-card>

    <ion-radio-group [(ngModel)]="view.item" *ngIf="view.data && view.data.length" [disabled]="!!view.multiple">
      <ion-item *ngFor="let item of view.data; trackBy:trackItems" [disabled]="!!item.disabled">
              
        <pipeline-media-template-preview *ngIf="(!item.photo && !item.thumbnail) && ((view.type === 'template') && !!item.view && !!item.custom && !!item.config && !!item.config.layers)" [template]="item" [(view)]="item.view"></pipeline-media-template-preview>
        <pipeline-video-template-preview *ngIf="(!item.photo && !item.thumbnail) && ((view.type === 'template') && !item.custom && !!item.config && !!item.config.Comp && !!item.config._meta)" [template]="item"></pipeline-video-template-preview>      

        <ion-icon *ngIf="!!item.icon && (!item.photo && !item.thumbnail)" [name]="item.icon" slot="start"></ion-icon>

        <ion-thumbnail *ngIf="(item.photo || item.thumbnail) && !item.videoSrc" slot="start">
          <ion-img [src]="(item.photo || item.thumbnail) || fallbackImg" (ionError)="thumbnailLoadingFailed(item)"></ion-img>
        </ion-thumbnail>
        
        <video *ngIf="!!item.guid || !!item.videoSrc" [poster]="fallbackImg" [src]="item.videoSrc || item.guid" playsinline webkit-playsinline muted="muted" loop autoplay></video>

        <ion-checkbox alignment="end" [(ngModel)]="item.checked" [disabled]="item.disabled" justify="space-between" labelPlacement="start" *ngIf="view.multiple" (click)="onMultiItemSelected(item)">
          <p [innerHTML]="((!!view.labelPrefix ? view.labelPrefix : '') + item[view.labelKey])|translate"></p>
          <small *ngIf="!!view.subLabelKey" [innerHTML]="((!!view.subLabelPrefix ? view.subLabelPrefix : '') + item[view.subLabelKey])|translate"></small>
        </ion-checkbox>
        
        <ion-radio alignment="end" (click)="select(item)" [disabled]="item.disabled" [value]="item[view.valueKey]" justify="space-between" *ngIf="!view.multiple" labelPlacement="start">
          <p [innerHTML]="((!!view.labelPrefix ? view.labelPrefix : '') + item[view.labelKey])|translate"></p>
          <small *ngIf="!!view.subLabelKey" [innerHTML]="((!!view.subLabelPrefix ? view.subLabelPrefix : '') + item[view.subLabelKey])|translate"></small>
        </ion-radio>

      </ion-item>

    </ion-radio-group>

  </div>
</ion-content>
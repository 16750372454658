import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';

import { EditorService } from 'src/app/services/utils/editor.service';

import editorJsHTML from "editorjs-html";

@Component({
  selector: 'pipeline-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements AfterViewInit {
  @Input() disabled: boolean;
  @Input() editor: any;

  blockDetectOnChange: boolean = true;

  //editor: any;

  @ViewChild('editorJs', { read: ElementRef }) private editorElement: ElementRef;

  @Input() id: string | null;
  @Input() input: string;
  @Output() inputChange = new EventEmitter();

  saveProcess: any;

  constructor(
    private editorService: EditorService,
    private zone: NgZone,
  ) {

  }

  async initEditor() {

    if (!this.editorElement || !this.editorElement.nativeElement) {
      return false;
    }

    const edjsParser = editorJsHTML();

    this.blockDetectOnChange = true;

    this.editor = await this.editorService.create(this.editorElement.nativeElement, {
      onChange: async (api: any) => {

        if (!!this.blockDetectOnChange) {
          return false;
        }

        const content = await api.saver.save();

        let blockStrings: string[] = edjsParser.parse(content),
          html = blockStrings.join("\n");

        this.zone.run(() => {
          this.input = `${html || ''}`;
          this.inputChange.emit(this.input);
        });
      },
    });

    setTimeout(() => {

      try {
        if(!!this.editor) {
          this.editor.addEventListener('focusin', (event: any = null) => {
            console.log('editor: focusin', event);
          });
  
          this.editor.addEventListener('focusout', (event: any = null) => {
            console.log('editor: focusout', event);
          });
        }
      } catch (e) {
        console.warn('adding editor focus events failed', e);
      }

      this.blockDetectOnChange = false;
      this.renderInput();
    }, 50);
  }

  ngAfterViewInit() {
    this.initEditor();
  }
  
  ngOnInit() {
  }

  public async renderInput() {

    try {
      if (!this.input || !this.input.length) {
        return false;
      }

      this.blockDetectOnChange = true;

      await this.editor.isReady;

      this.zone.run(() => {
        this.editor.blocks.renderFromHTML(this.input);

        setTimeout(() => {
          this.blockDetectOnChange = false;
        }, 50);

      });
    } catch (e) {
      console.warn('render input failed', e);
    }

  }

  public setInput(input: string) {
    this.zone.run(() => {
      this.input = input;
      this.renderInput();
    });
  }

}
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pipeline-post-reader',
  templateUrl: './post-reader.component.html',
  styleUrls: ['./post-reader.component.scss'],
})
export class PostReaderComponent implements OnInit {
  @Input() post: post;

  constructor() {

  }
  
  

  ngOnInit() {}

}
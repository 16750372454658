<ion-header class="ion-no-border">

  <ion-toolbar class="container ion-no-border">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>

    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>
  <pipeline-header-search-toolbar [(search)]="search" [(view)]="view" (onChange)="onSearchChanged($event)"></pipeline-header-search-toolbar>
  <pipeline-selection-toolbar *ngIf="!!view.languages" [(items)]="view.languages" [options]="view.selectionOptions" [(view)]="view" (onSelectionActionChanged)="onSelectionActionChanged($event)"></pipeline-selection-toolbar>
  
</ion-header>

<ion-content>
  
  <div class="container">

    <ion-spinner [hidden]="!view.loading"></ion-spinner>

    <div *ngIf="!!view.languages && !!view.languages.length">

      <ion-card *ngFor="let language of view.languages">

        <ion-list [hidden]="!!view.loading" lines="none">
          <ion-item [class.error]="language.state === 'error'" [class.success]="language.state === 'success'" [class.warning]="language.state === 'warning'">
            
            <ion-checkbox color="primary" slot="start" [(ngModel)]="language.checked" [value]="language.indent" (ionChange)="onItemChanged(language)"></ion-checkbox>
            
            <ion-icon name="checkmark-outline" color="success" slot="start" [hidden]="language.state != 'success'"></ion-icon>
            <ion-icon name="warning-outline" color="warning" slot="start" [hidden]="language.state != 'error' && language.state != 'warning'"></ion-icon>
    
            <ion-label class="ion-text-wrap">
              <h3 [innerHTML]="language.name|translate"></h3>
              <p *ngIf="!!language.post && (!!language.post.date_gmt || !!language.post.modified_gmt)">
                <span [innerHTML]="'date'|translate"></span>:
                <span [innerHTML]="language.post.modified_gmt || language.post.date_gmt"></span>
              </p>
            </ion-label>
            
            <ion-chip [hidden]="!language.post || (!!language.post && !!language.post.translated)" [innerHTML]="'primary'|translate"></ion-chip>

            <ion-spinner slot="end" [hidden]="!language.loading"></ion-spinner>

            <ion-button slot="end" (click)="create(language)" fill="clear" color="dark" [hidden]="(!!language.post && !!language.post.translated) || (!!language.post && !language.post.translated)" [disabled]="!!language.loading">
              <ion-icon name="add-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'create'|translate" [hidden]="!view.isDesktop" class="ion-text-wrap"></ion-label>
            </ion-button>

            <ion-button slot="end" (click)="update(language)" fill="clear" color="dark" [hidden]="(!language.post || !language.post.translated) || (!!language.post && !language.post.translated)" [disabled]="!!language.loading">
              <ion-icon name="sync-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'update'|translate" [hidden]="!view.isDesktop" class="ion-text-wrap"></ion-label>
            </ion-button>
    
            <ion-button slot="end" (click)="edit(language)" fill="clear" color="dark" [disabled]="!!language.loading" [hidden]="!language.post || !language.post.translated">
              <ion-icon name="create-outline" slot="start"></ion-icon>
              <ion-label [innerHTML]="'edit'|translate" [hidden]="!view.isDesktop" class="ion-text-wrap"></ion-label>
            </ion-button>
    
          </ion-item>
        </ion-list>

      </ion-card>

    </div>

  </div>

</ion-content>

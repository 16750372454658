<ion-header class="ion-no-border">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()" color="dark">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="submit()" color="dark">
          <ion-icon name="checkmark"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>  

  <ion-card>

    <ion-card-header>
      <ion-card-title [innerHTML]="'rate_post'|translate"></ion-card-title>
      <ion-card-subtitle [innerHTML]="'rate_post_subHeadline'|translate"></ion-card-subtitle>
    </ion-card-header>
    <ion-list>

      <ion-item>
        <ion-label>
          <h2 [innerHTML]="'quality'|translate"></h2>
          <p class="ion-text-wrap" [innerHTML]="'rate_quality_text'|translate"></p>
        </ion-label>
      </ion-item>
  
      <ion-item lines="none">
        <ion-range [(ngModel)]="rating.quality" [min]="1" [max]="5" [snaps]="true" [step]="1"></ion-range>
      </ion-item>
  
      <ion-item>
        <ion-label>
          <h2 [innerHTML]="'usefulness'|translate"></h2>
          <p class="ion-text-wrap" [innerHTML]="'rate_usefulness_text'|translate"></p>
        </ion-label>
      </ion-item>
  
      <ion-item lines="none">
        <ion-range [(ngModel)]="rating.usefulness" [min]="1" [max]="5" [snaps]="true" [step]="1"></ion-range>
      </ion-item>

      <ion-item>
        <ion-label>
          <h2 [innerHTML]="'rate_time'|translate"></h2>
          <p class="ion-text-wrap" [innerHTML]="'rate_time_text'|translate"></p>
        </ion-label>
      </ion-item>
  
      <ion-item lines="none">
        <ion-range [(ngModel)]="rating.time" [min]="1" [max]="5" [snaps]="true" [step]="1"></ion-range>
      </ion-item>
  
    </ion-list>
  </ion-card>

</ion-content>

import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
    providedIn: 'root'
})
export class AiTrainingService {

    constructor(
        private AppCMS: AppcmsService,
    ) {

    }

    schedule(options: any = {}) {
        return this.AppCMS.loadPluginData('pipeline', options, ['ai', 'training', 'schedule'], {}, true);
    }

    validate(options: any = {}) {
        return new Promise((resolve, reject) => {
            console.log('validate', options);

            if (!options || !options.model || !options.model.type) {
                reject('error_missing_model_type');
            } else
            if (!options || !options.provider || !options.provider.uid) {
                reject('error_missing_provider');
            } else {
                this.AppCMS.loadPluginData('pipeline', options, ['ai', 'training', 'validate'], {}, true).then(resolve).catch(reject);
            }
        });
    }

}
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  blIsModal: boolean = false;

  iOpenModals: number = 0;

  _modals: any = {};

  constructor(
    private modalCtrl: ModalController,
    private navCtrl: NavController,
  ) {
  }

  close() {
    const modalKeys = Object.keys(this._modals);
    const lastKey = modalKeys[modalKeys.length - 1];

    try {
      this._modals[lastKey].dismiss();
    } catch (e) {
      console.error('modalCtrl: close issue', e);
    }
  }

  closeAll() {
    try {
      const modalKeys = Object.keys(this._modals);

      modalKeys.forEach(async (modalKey: any, index: number) => {
        await this._modals[modalKey].dismiss();
        //delete this._modals[modalKey];
      });

    } catch (e) {
      console.warn('modals error', e);
    }
  }

  async create(modalOptions: any) {
    const keys = Object.keys(this._modals);

    modalOptions.componentProps = modalOptions.componentProps || {};
    modalOptions.componentProps.modal = this;
    modalOptions.componentProps.modalId = `modal_id_${keys.length + 1}`;

    modalOptions.cssClass = modalOptions.cssClass || 'defaultModal';

    const modal: any = await this.modalCtrl.create(modalOptions);

    if (modal.cssClass) {
      this._modals[modalOptions.componentProps.modalId] = modal;
    }

    return modal;
  }

  dismiss(data: any = null, role: string | null = null) {
    return new Promise((resolve, reject) => {
      try {
        this.modalCtrl.dismiss(data, role)
          .then(resolve)
          .catch((e: any) => {
            console.warn('closing modal failed (2)', e);
            this.navCtrl.pop();
          });
      } catch (e) {
        console.warn('closing modal failed (1)', e);
        this.navCtrl.pop();
      }
    });
  }

  async getController() {
    return this.modalCtrl;
  }

  async getTop() {
    const top = await this.modalCtrl.getTop();
    return top;
  }

  isModal() {
    return !!(this.iOpenModals > 0);
  }

  present(modal: any) {
    this.iOpenModals++;

    modal.onWillDismiss().then((data: any) => {
      this.iOpenModals--;
    });

    modal.present();
  }

}
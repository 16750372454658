import { Injectable } from '@angular/core';

import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  fallbackState: any;

  placeholder: any = {
    items: [{},{},{}]
  };

  state: state;

  visibleCategories: any[];

  constructor(
    private events: EventsService,
    private tools: ToolsService,
  ) {
  }

  calcPairs(items: any[], blShuffle: boolean = true, size: number = null) {
    let pairs: any[] = [],
        a: number = 0;

    size = size || (window.innerWidth >= 1024 ? 4 : (window.innerWidth >= 768 ? 6 : 12));
    
    if(items && items.length) {

      if(!!blShuffle) {
        items = this.tools.shuffle(items);
      }

      items.forEach((item: any, index: number) => {

        const x: number = (12 / size), 
              m: number = (index % x),
              bl: boolean = !!(m === (x - 1)) || ((x > 1) && (Math.random() < 0.15));

        pairs[a] = pairs[a] || [];
        pairs[a].push(item);
        
        if(bl) {
          a++;
        }
        
      });
    }
    
    return pairs;
  }

  async get() {
    return new Promise((resolve, reject) => {
      if(!this.state) {
        this.reset().then((state: state) => {
          this.state = state;
          resolve(state);
        }).catch(reject);
      } else {
        resolve(this.state);
      }
    });
  }

  getPlaceholder() {
    return this.placeholder;
  }

  isPostAllowed(post: post) {
    return !post.vip || (post.vip && post.purchased);
  }

  set(state: any) {
    this.state = state;
    this.events.publish('state:changed', this.state);
  }

  reset() {
    return new Promise(async (resolve, reject) => {
    });
  }

}

import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {

  _detailItem: integration | null;

  integrationsByType: any = {
    compute: ['aws', 'google_cloud', 'google_colab', 'hyperstack', 'lambdalabs', 'microsoft_azure'],
    messenger: ['discord', 'facebook', 'instagram', 'linkedin', 'onesignal', 'telegram', 'whatsapp'],
    newsletter: ['constantcontact', 'mailchimp', 'mailerlite'],
    social: ['discord', 'facebook', 'instagram', 'linkedin', 'meta', 'pinterest', 'reddit', 'snapchat', 'threads', 'tiktok', 'twitter', 'twitch', 'xing', 'youtube'],
    storage: ['aws', 'dropbox', 'ftp', 'google_drive', 'hyperstack', 'lambdalabs', 'microsoft_azure', 'nextcloud'],
  };

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
    private navCtrl: NavController,
  ) {

  }

  connect(options: any = {}) {
    return new Promise(async (resolve, reject) => {
      if (!!options && (!!options.error || !!options.message)) {
        reject(options.message || options.error);
      } else {

        const chooseConfig: chooseConfig = Object.assign((options || {}), {
          data: (options.data || []),
          labelKey: 'name',
          multiple: true,
          service: this,
          valueKey: 'uid',
        });

        this.chooser.choose(chooseConfig)
          .then((chooseResponse: chooseResponse) => {
            console.log('chooseResponse', chooseResponse);
            resolve(chooseResponse);
          })
          .catch(reject);
      }

    });
  }

  disable(integrationId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      integration: integrationId,
    }, ['integrations', 'disable']);
  }

  edit(integration: integration) {
    return new Promise(async (resolve, reject) => {
      try {
        this.detailItem(integration);

        this.navCtrl.navigateForward(`${integration.url || '/integration/settings/' + `${integration.name}`.replace('integration_', '').toLowerCase()}`);
      } catch (e) {
        reject(e);
      }
    });
  }

  enable(integrationId: number) {
    return this.AppCMS.loadPluginData('pipeline', {
      integration: integrationId,
    }, ['integrations', 'enable']);
  }

  detailItem(item: integration | null = null) {

    if (item !== null) {
      this._detailItem = item;
      return this;
    }

    return this._detailItem;
  }

  getApiRequestLog(options: any = {}, blForceRefresh: boolean = true) {
    return this.getLog(options, blForceRefresh);
  }

  getAvailable(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      filter: (typeof options === 'object' ? options : {}),
    }, ['integrations', 'list'], {}, blForceRefresh);
  }

  getByIndent(indent: string, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('pipeline', {
      indent: indent,
    }, ['integrations', 'single'], {}, blForceRefresh);
  }

  getByType(type: string | null = null) {
    if (!!type) {
      return this, this.integrationsByType[type];
    }
    return this.integrationsByType;
  }

  getEnabled(options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const available: any = await this.getAvailable(options, blForceRefresh);

        if (!!available && !!available.length) {
          resolve(available.filter((integration: integration) => {
            return !!integration.enabled;
          }));
        }

        resolve([]);
      } catch (e) {
        reject(e);
      }
    });
  }

  getLog(options: any = {}, blForceRefresh: boolean = true) {
    return this.AppCMS.loadPluginData('pipeline', options, ['integrations', 'log'], {}, blForceRefresh);
  }

  update(integration: integration) {
    return this.AppCMS.loadPluginData('pipeline', {
      integration: integration,
    }, ['integrations', 'update']);
  }

}
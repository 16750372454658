import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  _multiMode: boolean = false;

  key: string = 'pipeline_accounts';

  constructor(
    private cache: CacheService,
    private userService: UserService,
  ) {

  }

  async add(account: user) {
    let accounts: user[] = (await this.getAll() as user[]), blExists = false;

    accounts.forEach((_account: user) => {
      if (_account.uid === account.uid) {
        blExists = true;
      }
    });

    if (!blExists) {
      accounts.push(account);
    }

    return this.setAccounts(accounts);
  }

  getAll(options: any = null, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      const fromCache: cacheItem = await this.cache.get(this.key, -1);

      let accounts: user[] = [];
      const user = this.userService.getUser();

      if (blForceRefresh || (!fromCache || !fromCache.data)) {
        accounts.push(user);
      } else {
        accounts = fromCache.data;
      }

      resolve(accounts);
    });
  }

  isMultiMode() {
    return !!this._multiMode;
  }

  logout(user: user | null = null) {
    return new Promise((resolve, reject) => {
      this.getAll()
        .then((accounts: user[]) => {

          if (accounts && accounts.length) {
            accounts = accounts.filter((account: user) => {
              return account.uid !== user.uid;
            });
          }

          this.setAccounts(accounts);

          if (accounts && accounts[0] && accounts[0].uid) {
            this.userService.setUser(accounts[0]);
            resolve(accounts);
          } else {
            this.userService.logout().then(resolve).catch(reject);
          }

        })
        .catch(reject);
    });
  }

  setAccounts(accounts: user[]) {
    this.cache.set(this.key, accounts);
    return accounts;
  }

  setMultiMode(bl: boolean = false) {
    this._multiMode = !!bl;
  }

}
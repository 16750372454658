<!-- General -->
<div class="card ion-card" *ngIf="!!view && !!template && !!template.config">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('template')">
    <ion-icon name="information-circle-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_general'|translate"></h3>
      <p [innerHTML]="'video_template_general_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'template'">

    <ion-item [hidden]="!!mini">
      <ion-input [(ngModel)]="template.name" [label]="'video_template_name'|translate"
        (ionInput)="onViewInputChanged($event)"></ion-input>
    </ion-item>

    <ion-item [hidden]="!!mini">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_aspect_ratios'|translate"></ion-label>
      <ion-select [okText]="'okay'|translate" [cancelText]="'cancel'|translate" [(ngModel)]="template.aspect_ratios"
        multiple (ionChange)="onViewInputChanged($event)" [disabled]="!template.name && !mini">
        <ion-select-option *ngFor="let ratio of view.aspectRatios" [value]="ratio"
          [innerHTML]="ratio|translate"></ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="!!template.config && !!template.config.Comp">
      <ion-checkbox [disabled]="!template.name && !mini" [(ngModel)]="template.config.Comp.darkmode" slot="start"
        (ionChange)="onViewInputChanged($event)"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_darkmode'|translate"></ion-label>
    </ion-item>

    <ion-item *ngIf="!!template.config && !!template.config.Comp" [hidden]="template.type !== 'video'">
      <ion-checkbox [disabled]="!template.name && !mini" [(ngModel)]="template.config.Comp.intro" slot="start"
        (ionChange)="onViewInputChanged($event)"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_intro'|translate"></ion-label>
    </ion-item>

    <ion-item *ngIf="!!template.config && !!template.config.Comp" [hidden]="template.type !== 'video'" lines="none">
      <ion-checkbox [disabled]="!template.name && !mini" [(ngModel)]="template.config.Comp.video" slot="start"
        (ionChange)="onViewInputChanged($event)"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_video'|translate"></ion-label>
    </ion-item>

  </div>
</div>

<!-- Accent -->
<div *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.accent" class="card ion-card"
  [hidden]="!!view.cards && !view.cards.accent">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('accent')"
    [disabled]="!template.name && !mini">
    <ion-icon name="color-palette-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_accent'|translate"></h3>
      <p [innerHTML]="'video_template_accent_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'accent'">

    <div class="color-picker-wrapper" [hidden]="!!view.darkMode">

      <ion-item lines="none">
        <ion-label [innerHTML]="'video_template_accent_color'|translate"></ion-label>
      </ion-item>

      <pipeline-color-picker (colorChange)="_colorChanged($event, 'accent', 'color')"
        [color]="template.config.Comp.accent.color" [parentKey]="'accent'" [key]="'color'"></pipeline-color-picker>

    </div>

    <div class="color-picker-wrapper" [hidden]="!view.darkMode">
      <ion-item lines="none">
        <ion-label [innerHTML]="'video_template_accent_color_dark'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'accent', 'color_dark')"
        [color]="template.config.Comp.accent.color_dark" [parentKey]="'accent'"
        [key]="'color_dark'"></pipeline-color-picker>
    </div>

  </div>

</div>

<!-- Fonts -->
<div class="card ion-card" [hidden]="!template.custom || (!!view.cards && !view.cards.fonts)">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('fonts')"
    [disabled]="!template.name || (!view.fonts || !view.fonts.length)">
    <ion-icon name="text-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_fonts'|translate"></h3>
      <p [innerHTML]="'video_template_fonts_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'fonts'">
    <ion-grid *ngIf="!!view.fonts && !!view.fonts.length" class="font-chooser-grid">
      <ion-row>
        <ion-col *ngFor="let font of view.fonts">
          <ion-card class="font-card">
            <ion-item lines="none">
              <ion-checkbox slot="start" [(ngModel)]="font.checked" (ionChange)="onFontSelected(font)"></ion-checkbox>
              <pipeline-google-font-preview-card [font]="font"></pipeline-google-font-preview-card>
            </ion-item>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>

<!-- Logo -->
<div class="card ion-card" *ngIf="!!template.config && !!template.config.Comp && !!template.config._meta"
  [hidden]="!!view.cards && !view.cards.logo">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('logo')"
    [disabled]="!template.name && !mini">
    <ion-icon name="eye-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_logo'|translate"></h3>
      <p [innerHTML]="'video_template_logo_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'logo'">

    <ion-item [color]="view.logo_error ? 'danger' : 'solid'">

      <ion-thumbnail slot="start" (click)="uploadAttachment('logo_src')">
        <ion-img [src]="template.config._meta.logo_src || fallbackImg"
          (ionError)="attachmentFailed('logo_src')"></ion-img>
      </ion-thumbnail>

      <ion-input type="text" [label]="'logo'|translate" [(ngModel)]="template.config._meta.logo_src"
        (ionInput)="onAttachmentUrlPasted('logo_src')"
        [placeholder]="'attachment_paste_placeholder'|translate"></ion-input>

    </ion-item>

    <ion-item *ngIf="!!template.config && !!template.config.Comp">
      <ion-checkbox alignment="end" justify="space-between" labelPlacement="start"
        [(ngModel)]="template.config.Comp.logo_bg_auto_color" (ionChange)="onViewInputChanged($event)">
        <p [innerHTML]="'video_template_logo_bg_auto_color'|translate"></p>
      </ion-checkbox>
    </ion-item>

    <div *ngIf="!!template.config && !!template.config.Comp" class="color-picker-wrapper">
      <ion-item>
        <ion-label [innerHTML]="'video_template_logo_bg_color'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'logo_bg_color')"
        [color]="template.config.Comp.logo_bg_color"></pipeline-color-picker>
    </div>

    <ion-item lines="none">
      <ion-checkbox alignment="end" justify="space-between" labelPlacement="start"
        [(ngModel)]="view.logo_bg_color_transparent" (ionChange)="onLogoBgColorTransparentToggle()">
        <p class="ion-text-wrap" [innerHTML]="'use_transparent_background'|translate"></p>
      </ion-checkbox>
    </ion-item>

  </div>

</div>

<!-- Audio -->
<div *ngIf="!!template.config && !!template.config.Comp" class="card ion-card"
  [hidden]="template.type !== 'video' || (!!view.cards && !view.cards.video)">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('audio')"
    [disabled]="!template.name && !mini">
    <ion-icon name="musical-notes-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_audio'|translate"></h3>
      <p [innerHTML]="'video_template_audio_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'audio'">

    <ion-item>
      <ion-checkbox [(ngModel)]="template.config.Comp.audio_muted" (ionChange)="onViewInputChanged($event)"
        slot="start"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_audio_muted'|translate"></ion-label>
    </ion-item>

    <ion-item lines="none">
      <ion-checkbox [(ngModel)]="template.config.Comp.text2voice" (ionChange)="onViewInputChanged($event)"
        slot="start"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_text2voice'|translate"></ion-label>
    </ion-item>

  </div>

  <ion-radio-group (ionChange)="onViewInputChanged($event)" [(ngModel)]="view.audioMode"
    [hidden]="view.card != 'audio'">

    <ion-list-header>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_audio_mode'|translate"></ion-label>
    </ion-list-header>

    <ion-item>
      <ion-radio value="shuffle" slot="start" [disabled]="!!template.config.Comp.audio_muted"></ion-radio>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_audio_shuffle'|translate"></ion-label>
    </ion-item>

    <ion-item>
      <ion-radio value="pick" slot="start" [disabled]="!!template.config.Comp.audio_muted"></ion-radio>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_audio_pick'|translate"></ion-label>
    </ion-item>

  </ion-radio-group>

  <ion-radio-group *ngIf="view.audioMode === 'pick'" [hidden]="view.card != 'audio'">

    <ion-item *ngFor="let audio of view.audios; let i = index;">
      <ion-button slot="start" icon-only fill="clear" color="dark">
        <ion-icon [name]="audio.playing ? 'pause-outline' : 'play-outline'"></ion-icon>
      </ion-button>
      <ion-radio slot="end" [value]="i"></ion-radio>
      <ion-label [innerHTML]="audio.label" class="ion-text-wrap"></ion-label>
    </ion-item>

  </ion-radio-group>
</div>

<!-- Title -->
<div class="card ion-card" *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.title"
  [hidden]="!!view.cards && !view.cards.title">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('title')"
    [disabled]="!template.name && !mini">
    <ion-icon name="text-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_title'|translate"></h3>
      <p [innerHTML]="'video_template_title_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'title'">

    <ion-item>
      <ion-input [(ngModel)]="template.config.Comp.title.text" [label]="'video_template_title_text'|translate"
        (ionInput)="onViewInputChanged($event)"></ion-input>
    </ion-item>

    <div class="color-picker-wrapper" [hidden]="!!view.darkMode">
      <ion-item lines="none">
        <ion-label [innerHTML]="'video_template_color'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'title', 'color')"
        [color]="template.config.Comp.title.color"></pipeline-color-picker>
    </div>

    <div class="color-picker-wrapper" [hidden]="!view.darkMode">
      <ion-item lines="none">
        <ion-label [innerHTML]="'video_template_color_dark'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'title', 'color_dark')"
        [color]="template.config.Comp.title.color_dark"></pipeline-color-picker>
    </div>

  </div>
</div>

<!-- Subtext -->
<div class="card ion-card" *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.subtext"
  [hidden]="!!view.cards && !view.cards.subtext">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('subtext')"
    [disabled]="!template.name && !mini">
    <ion-icon name="chatbox-ellipses-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_subtext'|translate"></h3>
      <p [innerHTML]="'video_template_subtext_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'subtext'">

    <ion-item>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_subtext_text'|translate"
        position="floating"></ion-label>
      <ion-textarea [(ngModel)]="template.config.Comp.subtext.text"
        (ionInput)="onViewInputChanged($event)"></ion-textarea>
    </ion-item>

    <div class="color-picker-wrapper" [hidden]="!!view.darkMode">
      <ion-item lines="none">
        <ion-label [innerHTML]="'video_template_color'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'subtext', 'color')"
        [color]="template.config.Comp.subtext.color"></pipeline-color-picker>
    </div>

    <div class="color-picker-wrapper" [hidden]="!view.darkMode">
      <ion-item lines="none">
        <ion-label [innerHTML]="'video_template_color_dark'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'subtext', 'color_dark')"
        [color]="template.config.Comp.subtext.color_dark"></pipeline-color-picker>
    </div>

  </div>

</div>

<!-- Call 2 Action -->
<div class="card ion-card" *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.call2action"
  [hidden]="!!view.cards && !view.cards.call2action">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('call2action')"
    [disabled]="!template.name && !mini">
    <ion-icon name="magnet-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_call2action'|translate"></h3>
      <p [innerHTML]="'video_template_call2action_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'call2action'">

    <ion-item>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_call2action_text'|translate"
        position="floating"></ion-label>
      <ion-input [(ngModel)]="template.config.Comp.call2action.text"
        (ionInput)="onViewInputChanged($event)"></ion-input>
    </ion-item>

    <div class="color-picker-wrapper" [hidden]="!!view.darkMode">
      <ion-item>
        <ion-label [innerHTML]="'video_template_color'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action', 'color')"
        [color]="template.config.Comp.call2action.color"></pipeline-color-picker>
    </div>

    <div class="color-picker-wrapper" [hidden]="!view.darkMode">
      <ion-item>
        <ion-label [innerHTML]="'video_template_color_dark'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action', 'color_dark')"
        [color]="template.config.Comp.call2action.color_dark"></pipeline-color-picker>
    </div>

    <ion-item [hidden]="!!view.darkMode">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_cursor_color'|translate"></ion-label>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action', 'cursor_color')"
        [color]="template.config.Comp.call2action.cursor_color"></pipeline-color-picker>
    </ion-item>

    <ion-item [hidden]="!view.darkMode">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_cursor_color_dark'|translate"></ion-label>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action', 'cursor_color_dark')"
        [color]="template.config.Comp.call2action.cursor_color_dark"></pipeline-color-picker>
    </ion-item>

    <ion-item [hidden]="!!view.darkMode" lines="none">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_cursor_stroke'|translate"></ion-label>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action', 'cursor_stroke')"
        [color]="template.config.Comp.call2action.cursor_stroke"></pipeline-color-picker>
    </ion-item>

    <ion-item [hidden]="!view.darkMode" lines="none">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_cursor_stroke_dark'|translate"></ion-label>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action', 'cursor_stroke_dark')"
        [color]="template.config.Comp.call2action.cursor_stroke_dark"></pipeline-color-picker>
    </ion-item>

  </div>
</div>

<!-- Call 2 Action Link -->
<div class="card ion-card"
  *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.call2action_link"
  [hidden]="!!view.cards && !view.cards.call2action_link">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('call2action_link')"
    [disabled]="!template.name && !mini">
    <ion-icon name="link-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_call2action_link'|translate"></h3>
      <p [innerHTML]="'video_template_call2action_link_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'call2action_link'">

    <ion-item>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_call2action_link_text'|translate"
        position="floating"></ion-label>
      <ion-input [(ngModel)]="template.config.Comp.call2action_link.text"
        (ionInput)="onViewInputChanged($event)"></ion-input>
    </ion-item>

    <div class="color-picker-wrapper" [hidden]="!!view.darkMode">
      <ion-item>
        <ion-label [innerHTML]="'video_template_color'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action_link', 'color')"
        [color]="template.config.Comp.call2action_link.color"></pipeline-color-picker>
    </div>

    <div class="color-picker-wrapper" [hidden]="!view.darkMode">
      <ion-item>
        <ion-label [innerHTML]="'video_template_color_dark'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'call2action_link', 'color_dark')"
        [color]="template.config.Comp.call2action_link.color_dark"></pipeline-color-picker>
    </div>

    <ion-item lines="none">
      <ion-checkbox [(ngModel)]="template.config.Comp.call2action_link.link_highlight" slot="start"
        (ionChange)="onViewInputChanged($event)"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_call2action_link_highlight'|translate"></ion-label>
    </ion-item>

  </div>
</div>

<!-- Source -->
<div class="card ion-card" *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.source"
  [hidden]="!!view.cards && !view.cards.source">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('source')"
    [disabled]="!template.name && !mini">
    <ion-icon name="person-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_source'|translate"></h3>
      <p [innerHTML]="'video_template_source_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'source'">

    <ion-item>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_source_text'|translate"
        position="floating"></ion-label>
      <ion-textarea [(ngModel)]="template.config.Comp.source.text"
        (ionInput)="onViewInputChanged($event)"></ion-textarea>
    </ion-item>

    <div class="color-picker-wrapper" [hidden]="!!view.darkMode">
      <ion-item>
        <ion-label [innerHTML]="'video_template_color'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'source', 'color')"
        [color]="template.config.Comp.source.color"></pipeline-color-picker>
    </div>

    <div class="color-picker-wrapper" [hidden]="!view.darkMode">
      <ion-item>
        <ion-label [innerHTML]="'video_template_color_dark'|translate"></ion-label>
      </ion-item>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'source', 'color_dark')"
        [color]="template.config.Comp.source.color_dark"></pipeline-color-picker>
    </div>

    <!-- Use source avatar? -->
    <ion-item>
      <ion-checkbox [(ngModel)]="template.config.Comp.source.avatar" slot="start"
        (ionChange)="onViewInputChanged($event)"></ion-checkbox>
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_source_avatar'|translate"></ion-label>
    </ion-item>

    <!-- Configure source avatar -->
    <ion-item *ngIf="!!template.config._meta" [hidden]="!template.config.Comp.source.avatar"
      [color]="view.avatar_error ? 'danger' : 'solid'" lines="none">

      <ion-thumbnail slot="start" (click)="uploadAttachment('avatar_src')">
        <ion-img [src]="template.config._meta.avatar_src || fallbackImg"
          (ionError)="attachmentFailed('avatar_src')"></ion-img>
      </ion-thumbnail>

      <ion-label position="stacked" [innerHTML]="'avatar_src'|translate"></ion-label>
      <ion-input type="text" [(ngModel)]="template.config._meta.avatar_src"
        (ionInput)="onAttachmentUrlPasted('avatar_src')"
        [placeholder]="'attachment_paste_placeholder'|translate"></ion-input>

    </ion-item>

  </div>
</div>

<!-- Background -->
<div class="card ion-card" *ngIf="!!template.config && !!template.config.Comp && !!template.config.Comp.bg"
  [hidden]="!!view.cards && !view.cards.bg">

  <ion-button fill="clear" color="dark" expand="block" (click)="toggleCard('bg')" [disabled]="!template.name && !mini">
    <ion-icon name="image-outline" slot="start"></ion-icon>
    <ion-label class="ion-text-wrap">
      <h3 [innerHTML]="'video_template_bg'|translate"></h3>
      <p [innerHTML]="'video_template_bg_description'|translate"></p>
    </ion-label>
  </ion-button>

  <div [hidden]="view.card != 'bg'">

    <ion-item *ngIf="!!template.config._meta" [color]="view.bg_error ? 'danger' : 'solid'">

      <ion-thumbnail slot="start" (click)="uploadAttachment('bg_src')">
        <ion-img [src]="template.config._meta.bg_src || fallbackImg" (ionError)="attachmentFailed('bg_src')"></ion-img>
      </ion-thumbnail>

      <ion-label position="stacked" [innerHTML]="'background_image'|translate"></ion-label>
      <ion-input type="text" [(ngModel)]="template.config._meta.bg_src" (ionInput)="onAttachmentUrlPasted('bg_src')"
        [placeholder]="'attachment_paste_placeholder'|translate"></ion-input>

    </ion-item>

    <ion-grid *ngIf="!!view.mediaList && view.mediaList.length">
      <ion-row>
        <ion-col *ngFor="let media of view.mediaList">
          <div class="card ion-card" (click)="chooseMediaFromList(media)"
            [class.active]="template.photo == media.thumbnail">
            <ion-button size="small" icon-only color="light" fill="clear" (click)="removeMediaFromList(media)">
              <ion-icon name="trash-outline"></ion-icon>
            </ion-button>
            <ion-thumbnail>
              <ion-img [src]="media.thumbnail"></ion-img>
            </ion-thumbnail>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-item [hidden]="!!view.darkMode" lines="none">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_color'|translate"></ion-label>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'bg', 'color')"
        [color]="template.config.Comp.bg.color"></pipeline-color-picker>
    </ion-item>

    <ion-item [hidden]="!view.darkMode" lines="none">
      <ion-label class="ion-text-wrap" [innerHTML]="'video_template_color_dark'|translate"></ion-label>
      <pipeline-color-picker (colorChange)="_colorChanged($event, 'bg', 'color_dark')"
        [color]="template.config.Comp.bg.color_dark"></pipeline-color-picker>
    </ion-item>

  </div>

</div>
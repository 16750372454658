import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {

  constructor(
    private toastCtrl: ToastController,
    private translations: TranslationService,
  ) {

  }

  copyText(text: string) {
    return new Promise((resolve, reject) => {
      this.copyTextUsingClipboardOrFallball(text)
      .then(() => {
        
        this.translations.get('copy_to_clipboard_success')
        .subscribe(async (translation) => {
          let toast = await this.toastCtrl.create({
            message: translation,
            duration: 1000
          });
          toast.present();
        });

        resolve(true);
      })
      .catch(reject);
    });
  }

  copyTextUsingClipboardOrFallball(text: string) {
    return new Promise(async (resolve, reject) => {
      try {
        await navigator.clipboard.writeText(text);
        resolve(true);
      } catch (err) {
        console.warn('> copying text using clipboard api failed', err);
        this.copyTextFallback(text).then(resolve).catch(reject);
      }
    });
  }

  copyTextFallback(text: string) {
    return new Promise((resolve, reject) => {
      var textArea = document.createElement("textarea");
  
      textArea.style.position = 'fixed';
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = "0";
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      
      textArea.value = text;
  
      document.body.appendChild(textArea);
      textArea.select();

      try {
        let exec = document.execCommand('copy');
        resolve(!!exec);
      } catch (err) {
        reject(err);
      }

      document.body.removeChild(textArea);
    });
  }

}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'pipeline-view-mode-picker',
  templateUrl: './view-mode-picker.component.html',
  styleUrls: ['./view-mode-picker.component.scss']
})
export class ViewModePickerComponent implements OnDestroy, OnInit {

  @Input() view: any;

  @Output() changed = new EventEmitter();

  constructor(
    private viewService: ViewService,
  ) {
  }

  async calcExpertMode() {
    try {
      this.view.expertMode = await this.viewService.isExpertMode();
    } catch(e) {
      console.log('loading view mode settings failed', e);
    }
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.calcExpertMode();
  }
  
  toggleView() {
    this.view.expertMode = !this.view.expertMode;

    this.viewService.isExpertMode(this.view.expertMode);

    this.changed.emit(this.view.expertMode);
  }

}
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { AiBridgeService } from 'src/app/services/ai/ai-bridge.service';
import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { CrawlerService } from "src/app/services/utils/crawler.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IconPickerService } from 'src/app/services/utils/icon-picker.service';
import { IconsService } from "src/app/services/utils/icons.service";
import { IntroService } from 'src/app/services/utils/intro.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { TemplatesService } from 'src/app/services/media/templates.service';
import { TemplateValidationService } from 'src/app/services/media/template-validation.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ProjectsService } from 'src/app/services/core/projects.service';
import { SetupService } from 'src/app/services/utils/setup.service';
import { StablediffusionService } from "src/app/services/media/stablediffusion.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.page.html',
  styleUrls: ['./project.page.scss'],
})
export class ProjectPage implements OnInit {
  @ViewChild('infoPopover') infoPopover: any;

  aiSettingsOptions: aiSettingsOptions = {
    operations: [
      'image_to_video',
      'text_generation',
      'text_to_image',
    ],
  };

  cards: any = {
    ai: {
      open: true,
    }
  };

  fallbackImg: string = './assets/img/fallback.webp';

  isInfoPopoverOpen: boolean = false;

  languagesOptions: languagesSelectOptions = {
    all: true,
    cached: false,
    default: null,
    mode: "view",
    multiple: true
  };

  mainLanguageOptions: languagesSelectOptions = {
    all: true,
    cached: false,
    default: null,
    mode: "view",
  };

  project: project = {
    active: true,
    icon: 'folder-outline',
    description: '',
    title: '',
  };

  search: searchOptions = {
    keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
    query: '',
  };

  state: state = {};

  template: mediaTemplate;

  view: any = {
    aiOptions: {
    },
    card: 'accent',
    cards: {
      accent: true,
      call2action: true,
      call2action_link: true,
      bg: true,
      fonts: true,
      logo: true,
      source: true,
      subtext: true,
      title: true,
    },
    cloneKeys: [
      'ads',
      'campaigns',
      'connections',
      'images',
      'mail',
      'newsletters',
      'posts',
      'products',
      'settings',
      'shortcodes',
      'statistics',
      'sources',
      'tasks',
      'videos',
    ],
    colSizes: {
      content: (window.innerWidth > 768 ? 9 : 12),
      menu: (window.innerWidth > 768 ? 3 : 12),
    },
    expertMode: false,
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    interests: [
      {
        checked: true,
        icon: 'sparkles-outline',
        name: 'ai',
        uid: 'ai',
      },
      {
        icon: 'images-outline',
        name: 'media',
        uid: 'media',
      },
      {
        icon: 'calendar-outline',
        name: 'campaigns',
        uid: 'campaigns',
      },
      {
        icon: 'share-social-outline',
        name: 'social',
        uid: 'social',
      },
      /*
      {
        icon: 'storefront-outline',
        name: 'ecommerce',
        uid: 'ecommerce',
      },
      */
    ],
    introCard: {
      uid: 'project_admin_top_card',
      text: 'project_admin_top_card_text',
      title: 'project_admin_top_card_title',
    },
    itemSize: 64,
    menu: {
      items: [
        {
          icon: 'image-outline',
          name: 'ai_sd_settings',
          uid: 'assets',
        },
        {
          icon: 'film-outline',
          name: 'corporate_identity',
          uid: 'creatives',
        }
      ],
    },
    projects: [],
    providers: [
      {
        name: 'getgenius',
        photo: './assets/img/icon.webp',
        uid: 'getgenius',
      },
      {
        name: 'integration_google_colab',
        photo: './assets/img/integrations/icons/google.webp',
        uid: 'google_colab',
      },
      {
        name: 'integration_huggingface',
        photo: './assets/img/integrations/icons/huggingface.webp',
        uid: 'huggingface',
      },
      {
        name: 'integration_lambdalabs',
        photo: './assets/img/integrations/icons/lambdalabs.webp',
        uid: 'lambdalabs',
      },
      {
        name: 'integration_microsoft_azure',
        photo: './assets/img/integrations/icons/microsoft_azure.webp',
        uid: 'microsoft_azure',
      },
    ],
    recommendations: {
      negative_prompt: [],
      prompt_suffix: [],
    },
    route: 'project',
    segment: 'general',
    title: 'create_project',
  };

  constructor(
    private aiBridge: AiBridgeService,
    private aiTools: AiToolsService,

    private crawler: CrawlerService,
    private events: EventsService,
    private iconPicker: IconPickerService,
    private icons: IconsService,
    private introService: IntroService,
    private media: MediaextendService,
    private modalService: ModalService,
    private projectsService: ProjectsService,
    private setupService: SetupService,
    private sd: StablediffusionService,
    private templates: TemplatesService,
    private templateValidation: TemplateValidationService,
    private tools: ToolsService,
    private viewService: ViewService,
  ) {
  }

  addMember() {
    this.projectsService.addUserToProject(this.project.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  addRecommendation(tag: any, key: string) {
    this.project.config.assets[key] = this.project.config.assets[key] || [];
    this.project.config.assets[key].push(tag);

    this.view.recommendations[key] = this.view.recommendations[key].filter((item: any) => {
      return item.uid !== tag.uid;
    });

    if (!this.view.recommendations[key] || !this.view.recommendations[key].length) {
      this.loadRecommendationsByKey(key);
    }
  }

  addTeam() {
    this.projectsService.addTeamToProject(this.project.uid)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  async aiCreate() {
    this.view.loading = true;

    try {

      // create project
      const create: any = await this.saveASync(false);

      if (!!create && !!create.item && !!create.item.uid) {

        // switch to project
        await this.projectsService.setCurrent(create.item);

        // run project setup
        await this.runAiProjectSetup(create.item);

        this.project = Object.assign(this.project, create.item);
      }

      this.view.loading = false;

      this.calcViewVars();

    } catch (e) {
      this.view.loading = false;
      this.events.publish('error', e);
    }
  }

  calcColSizes() {
    this.view.colSizes = {
      content: (window.innerWidth > 768 ? 9 : 12),
      menu: (window.innerWidth > 768 ? 3 : 12),
    };
  }

  async calcIntroCard() {
    let uid: string = `project_admin_top_card`;

    switch (this.view.segment) {
      case 'ai':
        uid = 'pipeline_project_look_and_feel_assets';
        break;
      case 'look_and_feel':
        uid = 'pipeline_project_look_and_feel_creatives';
        break;
    }

    this.view.introCard.text = `${uid}_text`;
    this.view.introCard.title = `${uid}_title`;
    this.view.introCard.uid = uid;
    this.view.introCard.hidden = await this.introService.isIntroCardHidden(uid);
  }

  calcPrompts() {

    if (!this.project || !this.project.config || !this.project.config.assets) {
      return false;
    }

    // parse positive suffix
    if (!!this.project.config.assets.prompt_suffix && (typeof this.project.config.assets.prompt_suffix === 'string')) {

      const positiveTags: any[] = `${this.project.config.assets.prompt_suffix}`.split(',').map((tag: string) => {
        tag = this.tools.trim(tag);
        return { title: tag, uid: tag };
      });

      this.project.config.assets.prompt_suffix = (positiveTags || []);
    }

    // parse negative prompt
    if (!!this.project.config.assets.negative_prompt && (typeof this.project.config.assets.negative_prompt === 'string')) {

      const negativeTags: any[] = `${this.project.config.assets.negative_prompt}`.split(',').map((tag: string) => {
        tag = this.tools.trim(tag);
        return { title: tag, uid: tag };
      });

      this.project.config.assets.negative_prompt = (negativeTags || []);
    }

  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
    this.view.title = (!!this.project && !!this.project.uid ? this.project.title : 'create_project');

    this.calcColSizes();
    this.calcIntroCard();
  }

  calculateAiOptions() {
    if (!!this.project && !!this.project.config && !!this.project.config.assets) {
      this.view.aiOptions = Object.assign(this.view.aiOptions || {}, this.project.config.assets);
    }
  }

  chooseIcon() {
    this.iconPicker.pick()
      .then((response: any) => {
        if (!!response && !!response.icon) {
          this.project.icon = response.icon;
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  dismiss(data: any = null, role: string | null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  doRefresh() {

  }

  async fetchProjectUrl(project: project) {

    if (!project || !project.link || !project.link.length) {
      return false;
    }

    this.crawler
      .setNestingDepth(10)
      .setUrl(project.link)
      .useProxy(true);

    try {

      const exec: any = await this.crawler.run({
        onCrawledLinksChanged: (event: any) => {
          // returns updated crawled links list
          console.log('onCrawledLinksChanged', event);
        },
        onCurrentLinkChanged: (event: any) => {
          // full link list changed
          console.log('onCurrentLinkChanged', event);
        },
        onFoundLinksChanged: (event: any) => {
          // returns new links found
          console.log('onFoundLinksChanged', event);
        },
        onPageChanged: (event: any) => {
          // if page has been changed
          console.log('onPageChanged', event);
        },
        onPageLoaded: (event: any) => {
          // if page has been loaded
          console.log('onPageLoaded', event);
        },
      });

      console.log('fetchProjectUrl: exec', exec);
    } catch (e) {
      console.error('fetchProjectUrl: error', e);
    }
  }

  async generatePhoto(blForce: boolean = false) {

    if (!blForce && (!!this.project.photo && !!this.project.photo.length)) {
      return false;
    }

    this.view.loadingPhoto = true;

    try {
      const response: any = await this.icons.generate(this.project.title);

      if (!!response && !!response.images && !!response.images[0]) {
        this.project.photo = response.images[0];

        if (!!this.project && !!this.project.uid) {
          this.update(false);
        }
      }

      this.view.loadingPhoto = false;
    } catch (e) {
      console.log('generate failed', e);
      this.view.loadingPhoto = false;
    }
  }

  ionViewWillLeave() {
    this.projectsService.detailItem({} as any);
  }

  loadImageModels(blForceRefresh: boolean = false) {
    this.aiTools.getAvailableImageModels({
      instances: (this.project.config.assets.instances || []),
    }, blForceRefresh)
      .then((response: any) => {
        const models: aiModel[] = (!!response && !!response.data ? response.data : []);
        this.view.models = (models || []);
      })
      .catch((error: any) => {
        console.warn('loading models failed', error);
      });
  }

  loadInstances(blForceRefresh: boolean = false) {
    this.sd.getAvailableInstances({}, blForceRefresh)
      .then((instances: any[]) => {
        this.view.instances = (instances || []);
      })
      .catch((error: any) => {
        console.warn('loading models failed', error);
      });
  }

  async loadProject() {

    if (!this.project || !this.project.uid) {
      return false;
    }

    try {
      this.project = await this.projectsService.getByUid(this.project.uid, true);
      this.calculateAiOptions();
    } catch (e) {
      console.warn('refreshing project failed', e);
    }

  }

  loadProjects(blForceRefresh: boolean = false) {
    this.projectsService.getActive({}, blForceRefresh)
      .then((projects: project[]) => {
        this.view.projects = (projects || []);
      })
      .catch((error: any) => {
        this.view.projects = [];
      });
  }

  loadRecommendations() {

    if (!this.project || !this.project.config || !this.project.config.assets) {
      return false;
    }

    const keys: string[] = ['prompt_suffix', 'negative_prompt'];

    keys.forEach(async (key: string) => {
      if (!!this.project.config.assets[key]) {
        this.loadRecommendationsByKey(key);
      }
    });
  }

  loadRecommendationsByKey(key: string) {

    if (!this.project.config.assets[key] || (typeof this.project.config.assets[key] !== 'object')) {
      return false;
    }

    const string: string = this.project.config.assets[key].map((item: any) => {
      return item.title;
    }).join(',');

    const history: any[] = [
      {
        role: 'system',
        content: `Return a flat json list of close or even better AI text to image keywords. 5 to 10 keywords only.
        Do not use any of the keywords already provided, and no duplicate keywords.

        IMPORTANT: Do not add additional text or any other output or explaination except the json only.

        Your output must be plain json and look like this:
        ["keyword1", "keyword2", "keyword3", ...]`,
      }
    ];

    this.aiBridge.execute({
      history: history,
      post_content: string,
    }, true)
      .then((response: any) => {
        if (!!response && !!response.output) {
          let recommendationsByKey: string[] = (response.output || '').split(',') || [];

          try {
            const split: string[] = response.output.split('['),
              json: string = `[${split[1]}`,
              list: string[] = (!!json ? JSON.parse(json) : []);

            if (!!list && !!list.length) {
              recommendationsByKey = list;
            }
          } catch (e) {
          }

          this.view.recommendations[key] = recommendationsByKey.map((part: string) => {
            part = this.tools.trim(part);

            return {
              title: part,
              uid: part,
            };
          });

        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  loadTemplate() {
    let template = this.templates.getDefaultConfig();

    //template.compositions = this.templates.getDefaultCompositions();
    template.name = this.project.title;

    if (!!this.project && !!this.project.config && !!this.project.config.Comp) {
      template.config = this.project.config;
    }

    this.template = template;
  }

  lookupUrl() {
    let url: string = `${this.project.link || ''}`;

    this.view.hasValidWebsiteResponse = false;

    const validate: any = this.tools.validateUrl(url);
    console.log('url validate: ', validate);

    this.view.isValidWebsiteUrl = validate.success;

    if (!this.view.isValidWebsiteUrl) {
      this.view.blockLookup = false;
      this.view.loading = false;
      this.view.urlError = true;

      return false;
    }

    if (!!this.view.blockLookup) {
      return false;
    }

    this.view.blockLookup = true;
    this.view.loading = true;
    this.view.urlError = false;

    // if http(s) was missing, validated url should be re-applied
    if (validate.url !== url) {
      this.project.link = validate.url;
    }

    this.aiTools.inspectURL(validate.url)
      .then((response: any) => {
        console.log('inspect response', response);

        this.view.blockLookup = false;
        this.view.hasValidWebsiteResponse = true;
        this.view.loading = false;
        this.view.urlError = false;

        if (!!response && !!response.ci) {

          if (!!response.ci.title && (!this.project.title || !this.project.uid || !this.view.startManually)) {
            this.project.title = `${response.ci.title}`;
          }

          if (!!response.ci.description) {
            this.project.description = `${response.ci.description}`;
          }

          if (!!response.ci.language) {
            this.project.language = `${response.ci.language}` || this.project.language;
            console.log('updated project.language to: ', this.project.language);
          }

          if (!!response.ci.icon) {
            this.project.photo = `${response.ci.icon}`;
          } else
            if ((!this.project.photo || !this.project.photo.length) || (this.project.photo.indexOf('fallback.') !== -1)) {
              this.generatePhoto(true);
            }

          if (!!this.template && !!this.template.config && !!this.template.config.Comp) {

            if (!!response.ci.primary_color && !!this.template.config.Comp.accent) {
              this.template.config.Comp.accent.color = response.ci.primary_color;
              this.template.config.Comp.accent.color_dark = response.ci.primary_color;
            }

            if (!!this.project.title && !!this.template.config.Comp.source && !!this.template.config.Comp.source.text) {
              this.template.config.Comp.source.text = this.project.title;
            }

          }

        }

      })
      .catch((error: any) => {
        this.view.blockLookup = false;
        this.view.hasValidWebsiteResponse = false;
        this.view.loading = false;
        this.view.urlError = true;

        console.warn('loading url failed', error);
      });
  }

  ngAfterContentInit() {
    this.calcViewVars();
  }

  ngOnInit() {
    let project: any | null = this.projectsService.detailItem() || this.project;

    if (!!project && !!project.uid) {
      project.config = project.config || {};
      project.config.languages = project.config.languages || [];

      this.project = project;
    }

    this.project = this.project || {};
    this.project.config = this.project.config || {};
    this.project.config.assets = this.project.config.assets || {};
    this.project.config.compute = this.project.config.compute || {};

    this.view.provider = this.view.providers[0];
    this.view.startManually = (!!this.project && !!this.project.uid);

    this.calculateAiOptions();
    this.calcViewVars();
    this.calcPrompts();

    this.loadProject();
    this.loadProjects();

    this.loadTemplate();
  }

  onAttributeAdd(event: any, key: string) {
    this.loadRecommendationsByKey(key);
  }

  onAttributeClicked(attribute: any) {
    console.log('on attribute clicked', attribute);
  }

  onAttributeRemove(event: any, key: string) {
    this.loadRecommendationsByKey(key);
  }

  onInstancesChanged(event: any = null) {
    this.loadImageModels();
  }

  onLanguageChanged(event: any = null) {
    this.project.config = this.project.config || {};
    this.project.config.languages = event;

    this.update(false);
  }

  onProviderChanged(event: any = null) {

  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
    this.calcColSizes();
  }

  onSearchChanged(searchOptions: any = null) {
  }

  async onSegmentChange(event: any) {

    if (!!this.project && !!this.project.title && !this.project.uid) {
      await this.save(false);
    }

    switch (event.detail.value) {
      case 'ai':
        this.loadInstances();
        this.loadImageModels();
        this.loadRecommendations();
        break;
    }

    this.calcIntroCard();
  }

  onWebsiteChanged() {
    try {
      this.lookupUrl();
    } catch (e) {
      console.warn('lookup failed', e);
    }
  }

  optimizAllAttributes() {
    const keys: string[] = ['prompt_suffix', 'negative_prompt'];

    keys.forEach(async (key: string) => {
      await this.optimizeAttributes(key);
    });
  }

  optimizeAttributes(key: string) {
    const project: project = JSON.parse(JSON.stringify(this.project));

    this.sd.optimizeProjectPromptAttributes(project, key)
      .then(async (response: any) => {

        if (!!response && !!response.items) {
          this.project.config.assets[key] = response.items;
        }

        return this.project;
      })
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  presentInfoPopover(e: Event, message: string) {
    this.view.infoPopoverContent = message;
    this.infoPopover.event = e;
    this.isInfoPopoverOpen = true;
  }

  async runAiProjectSetup(project: any = null) {
    return new Promise(async (resolve, reject) => {
      project = project || this.project;

      // fetch project url first
      try {
        await this.fetchProjectUrl(project);
      } catch (e) {
        console.warn('fetch failed', e);
      }

      setTimeout(async () => {
        try {
          this.runAiProjectSetupTools(project).then(resolve).catch(reject);
        } catch (e) {
          reject(e);
        }
      }, 2500);
    });
  }

  runAiProjectSetupTools(project: any = null) {
    return new Promise((resolve, reject) => {
      project = project || this.project;

      const selectedInterests: string[] = this.view.interests.filter((interest: any) => {
        return interest.checked;
      }).map((interest: any) => {
        return interest.name;
      });

      if (!selectedInterests || !selectedInterests.length) {
        this.view.loading = false;
        this.view.importStep = null;
        this.startManually();

        resolve(true);
      }

      this.view.loading = true;

      selectedInterests.forEach((interest: string, i: number) => {
        setTimeout(async () => {
          this.view.importStep = interest;

          try {
            let response: any = null;

            switch (interest) {
              case 'ai':
                response = await this.setupService.setupAiTools({ project: project }); break;
              case 'campaigns':
                response = await this.setupService.setupCampaigns({ project: project }); break;
              case 'ecommerce':
                response = await this.setupService.setupEcommerce({ project: project }); break;
              case 'media':
                response = await this.setupService.setupMediaLibrary({ project: project }); break;
              case 'social':
                response = await this.setupService.setupSocialMedia({ project: project }); break;
            }

            if (i === (selectedInterests.length - 1)) {
              this.view.loading = false;
              this.view.importStep = null;
              this.startManually();
              resolve(true);
            }
          } catch (e) {
            console.warn('partial setup failed: ', interest, e);

            if (i === (selectedInterests.length - 1)) {
              this.view.loading = false;
              this.view.importStep = null;
              this.startManually();
              resolve(true);
            }
          }
        }, (((i + 1) * 1000) + (i * 750)));
      });
    });
  }

  runSearch(event: any = null) {

  }

  save(blDismiss: boolean = true) {
    this.saveASync(blDismiss)
      .then((data: any) => {

        if (!!data && !!data.item) {
          this.project = data.item;
        }

        this.calcViewVars();

        if (!!blDismiss) {
          this.dismiss(data);
        }

      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  saveASync(blDismiss: boolean = true) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;

      const validation: any = this.templateValidation.validate(this.template);

      if (!validation.success) {
        this.events.publish('error', validation.error || 'project_look_and_feel_validation_failed');
        this.view.loading = false;
        return false;
      }

      this.project.config = this.project.config || {};
      this.project.config.assets = this.projectsService.calculateAssetsConfig(this.project);
      this.project.config.compute = this.project.config.compute || {};

      if (!!this.view.aiOptions) {
        this.project.config.assets = Object.assign(this.project.config.assets, this.view.aiOptions);
      }

      if (!!this.template && !!this.template.config) {
        this.project.config = Object.assign(this.project.config, this.template.config);
      }

      this.project.value = JSON.stringify(this.project.config);

      this.projectsService.save(this.project)
        .then((data: any) => {
          this.events.publish('project:created', data);
          this.view.loading = false;

          resolve(data);
        })
        .catch(reject);
    });
  }

  setComputeMode(iMode: number) {
    this.project.config.compute = this.project.config.compute || {};
    this.project.config.compute.mode = iMode || 0;
  }

  startManually() {
    this.view.startManually = true;
  }

  templateColorVarChanged(event: any = null) {
    //console.log('templateColorVarChanged', event);
  }

  templateVarsChanged(event: any = null) {
    //console.log('templateVarsChanged', event);
  }

  thumbnailLoadingFailed(item: any, event: any = null) {
    console.log('project: thumbnailLoadingFailed: item', item);
    console.log('project: thumbnailLoadingFailed: event', event);

    item.photo = this.fallbackImg;

    this.generatePhoto();
  }

  toggleType(type: any, iType: number) {
    this.view.interests[iType].checked = !this.view.interests[iType].checked;
  }

  toggleView() {
    this.view.expertMode = !this.view.expertMode;

    this.view.cards = {
      accent: true,
      call2action: true,
      call2action_link: true,
      bg: true,
      fonts: true,
      logo: true,
      source: true,
      subtext: true,
      title: true,
    };
  }

  update(blShouldDismiss: boolean = true) {
    this.view.loading = true;

    const validation: any = this.templateValidation.validate(this.template);

    if (!validation.success) {
      this.events.publish('error', validation.error || 'project_look_and_feel_validation_failed');
      this.view.loading = false;
      return false;
    }

    if (!!this.template && !!this.template.config) {
      this.project.value = Object.assign((this.project.config || {}), this.template.config);
    }

    this.project.config.assets = this.projectsService.calculateAssetsConfig(this.project);
    this.project.config.compute = this.project.config.compute || {};

    if (!!this.view.aiOptions) {
      this.project.config.assets = Object.assign(this.view.aiOptions, this.project.config.assets);
    }

    this.projectsService.update(this.project)
      .then(async (data: any) => {

        if (!!data && !!data.item) {
          this.project = Object.assign(this.project, data.item);
        }

        if (!!blShouldDismiss) {
          this.dismiss(data);
        }

        this.view.loading = false;

        // if updated project is current user project, update local stored project as well
        const currentProject: project = await this.projectsService.getCurrent();

        if (!!currentProject && !!this.project.uid && (this.project.uid === currentProject.uid)) {
          this.projectsService.setCurrent(this.project);
        }
      })
      .catch((error: any) => {
        this.events.publish('error', error);
        this.view.loading = false;
      });
  }

  uploadPhoto() {
    this.media.applyFromWeb(null, {
    })
      .then((response: any) => {
        let imageUrl: string | null = (typeof response === 'string' ? response : null);

        if (!!response && !!response.items && !!response.items[0] && !!response.items[0].thumbnail) {
          imageUrl = response.items[0].thumbnail;
        }

        this.project.photo = imageUrl;
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  updateProjectLanguage(event: any = null, blUpdate: boolean = true) {
    this.project.language = `${event}`;

    if (!!blUpdate) {
      this.update(false);
    }
  }

}
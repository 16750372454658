import { Injectable } from '@angular/core';

import { PexelsService } from 'src/app/services/stockphotos/pexels.service';
import { PixabayService } from 'src/app/services/stockphotos/pixabay.service';
import { UnsplashService } from 'src/app/services/stockphotos/unsplash.service';

@Injectable({
  providedIn: 'root'
})
export class StockphotosService {

  constructor(
    private pexels: PexelsService,
    private pixabay: PixabayService,
    private unsplash: UnsplashService,
  ) {

  }

  search(query: string, filter: any = {}, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      let providers: string[] = ['pexels', 'pixabay', 'unsplash'];
      let results: any[] = [], iLoaded: number = 0;

      providers.forEach(async (provider: string) => {

        try {
          let resultsByProvider: any = await this[provider].search(query, filter, blForceRefresh);
          iLoaded++;

          if (!!resultsByProvider && resultsByProvider.hasOwnProperty('results') && !!resultsByProvider.results.length) {
            results = Object.assign(results, resultsByProvider.results);
          } else
          if (!!resultsByProvider && resultsByProvider.hasOwnProperty('items') && !!resultsByProvider.items.length) {
            results = Object.assign(results, resultsByProvider.items);
          } else
          if (resultsByProvider && resultsByProvider.length) {
            results = Object.assign(results, resultsByProvider);
          } else {
            console.warn(`No search results for provider ${provider}`);
          }

          if(iLoaded === providers.length) {
            resolve({
              success: true,
              results: results,
            });
          }

        } catch (e) {
          iLoaded++;

          if(iLoaded === providers.length) {
            resolve({
              success: true,
              results: results,
            });
          }

          console.warn(`${provider} lookup failed:`, e);
        }

      });

    });
  }

}

import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';

import { TranslateService } from "@ngx-translate/core";

import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  defaultLanguage: string = 'en';

  displayLanguage: string;

  displayLanguage_cacheKey: string = 'user_display_language';

  requestLanguage: string;

  requestLanguage_cacheKey: string = 'user_request_language';

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private chooser: ChooserService,
    private events: EventsService,
    private translate: TranslateService,
    private userService: UserService,
  ) {
    this.setAppCMSLanguageRequestParam();
  }

  applyDisplayLanguage() {
    const language = this.userService.getLanguage();

    this.translate.use(language);
    moment.locale(language);

    const user: user = this.userService.getUser() || {};

    if (user && user.uid) {
      this.events.publish("home:refresh");
    }
  }

  async getDisplayLanguage(blReturnDefaultOnFallback: boolean = true) {

    if (!this.displayLanguage) {
      const fromCache: cacheItem = await this.cache.get(this.displayLanguage_cacheKey, -1);

      if (!!fromCache && !!fromCache.data) {
        this.displayLanguage = fromCache.data;
      } else {
        const defaultLanguage: string | null = (!!blReturnDefaultOnFallback ? this.defaultLanguage : null);
        const user: user = this.userService.getUser() || {};

        this.displayLanguage = (user && user.classifications ? (user.classifications.language || defaultLanguage) : defaultLanguage);
      }
    }

    return this.displayLanguage;
  }

  getDefaultLanguage() {
    return this.defaultLanguage;
  }

  getLanguages(blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      this.AppCMS.loadPluginData("pipeline", {}, ["languages"], {}, blForceRefresh)
        .then((languages: language[]) => {

          if (languages && languages.length) {
            languages.forEach((language: language) => {
              language.flag = './assets/img/flags/' + language.indent + '.svg';
            });
          }

          resolve(languages);
        })
        .catch(reject);
    });
  }

  async getRequestLanguage(blReturnDefaultOnFallback: boolean = true) {

    if (!this.requestLanguage) {
      const fromCache: cacheItem = await this.cache.get(this.requestLanguage_cacheKey, -1);

      if (!!fromCache && !!fromCache.data) {
        this.requestLanguage = fromCache.data;
      } else {
      }
    }

    return this.requestLanguage;
  }

  init() {
    this.initEvents();
    this.initDisplayLanguage();
    this.applyDisplayLanguage();
  }

  initEvents() {
    this.events.subscribe('appcms:user:updated', (user: user) => {
      this.setAppCMSLanguageRequestParam();
    });
  }

  initDisplayLanguage() {
    try {
      this.translate.setDefaultLang("en");

      const user: user = this.userService.getUser() || {},
        userLanguage: string | null = (!!user && !!user.classifications && !!user.classifications.language ? user.classifications.language : null),
        language: string = (userLanguage || (window.navigator.language || "en").split("-")[0]);

      if (language) {
        this.translate.use(language);
        moment.locale(language);
      } else {
        moment.locale("en");
      }
    } catch (e) {
      console.warn("language error", e);
      moment.locale("de");
    }
  }

  pick(options: any = null) {
    return new Promise(async (resolve, reject) => {
      const data: any = (!!options.data && !!options.data.length ? options.data : await this.getLanguages());

      const chooseConfig: chooseConfig = Object.assign((options || {}), {
        data: data,
        labelKey: 'name',
        multiple: !!options.multiple,
        service: this,
        title: (!!options.multiple ? 'languages' : 'language'),
        valueKey: 'indent',
      });

      this.chooser.choose(chooseConfig).then(resolve).catch(reject);
    });
  }

  async setAppCMSLanguageRequestParam() {
    this.AppCMS.setRequestParam('language', `${await this.getDisplayLanguage() || 'en'}`);
    this.initDisplayLanguage();
  }

  setDisplayLanguage(language: string) {
    this.displayLanguage = language;
    return this.cache.set(this.displayLanguage_cacheKey, language);
  }

  setRequestLanguage(language: string) {
    this.requestLanguage = language;
    return this.cache.set(this.requestLanguage_cacheKey, language);
  }

  async useTranslator() {
    return (await this.getDisplayLanguage()) !== this.getDefaultLanguage();
  }

}
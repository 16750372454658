<ion-card class="noEntriesCard">

  <ion-card-content>

    <ion-icon name="warning-outline"></ion-icon>

    <ion-card-title [innerHTML]="'no_entries'|translate"></ion-card-title>

    <!-- Call to action -->
    <ion-button fill="clear" (click)="ctaClick()" *ngIf="!!cta && !!cta.url">
      <ion-icon [name]="cta.icon" *ngIf="!!cta.icon" slot="start"></ion-icon>
      <ion-label [innerHTML]="(cta.label || 'add')|translate"></ion-label>
    </ion-button>
    
  </ion-card-content>

</ion-card>
import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { LanguageService } from 'src/app/services/core/language.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  apiUrl: string = 'https://search.pipeline.page/api/';

  proxyUrl: string = 'https://search.pipeline.page/api/searchengine/proxy.raw?url=';

  view: any = {};

  constructor(
    private AppCMS: AppcmsService,
    private cache: CacheService,
    private language: LanguageService,
    private tools: ToolsService,
  ) {

  }

  async addToHistory(query: string) {
    let history = await this.getHistory();

    query = this.tools.trim(query);

    if (history.indexOf(query) === -1) {
      history.push(query);
    }

    return this.cache.set('searchHistory', history);
  }

  async deleteHistoryItem(query: string) {
    let history = await this.getHistory();
    history = history.filter((historyItem: string) => {
      return historyItem !== query;
    });
    return this.cache.set('searchHistory', history);
  }

  findBlogAdminResults(query: string, options: any = {}) {
    return this.run({
      itemsKey: 'items',
      keys: ['title', 'excerpt', 'url', 'description', 'name', 'indent'],
      query: query,
    }, Object.assign(options, {
      active: false,
    }));
  }

  getApiUrl() {
    return this.apiUrl;
  }

  async getHistory() {
    const fromCache: cacheItem = await this.cache.get('searchHistory', (60 * 24 * 7)) || {};
    return fromCache.data || [];
  }

  getProxyUrl() {
    return this.proxyUrl;
  }

  prepareSearchResponse(response: searchResponse) {
    return response;
  }

  run(options: searchOptions, filter: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      const language = await this.language.getDisplayLanguage();

      const key: string = 'searchResponse_' + language + '_' + JSON.stringify(options) + '_' + JSON.stringify(filter),
        fromCache: cacheItem = await this.cache.get(key, 30 * 60);

      if (!options.query || !options.query.length) {
        reject('error_query_empty');
      } else
        if (options.query && (options.query.length < 3)) {
          reject('error_query_too_short');
        } else
          if (!blForceRefresh && (fromCache && fromCache.data)) {
            resolve(fromCache.data);
          } else {
            this.AppCMS.loadPluginData('pipeline', {
              search: options,
              filter: Object.assign({
                active: true,
              }, filter),
              language: language,
            }, ['search'], {
              apiUrl: this.apiUrl
            })
              .then((searchResponse: searchResponse) => {
                searchResponse = this.prepareSearchResponse(searchResponse);
                this.cache.set(key, searchResponse)
                  .then(() => {
                    resolve(searchResponse);
                  })
                  .catch(() => {
                    resolve(searchResponse);
                  });
              })
              .catch(reject);
          }
    });
  }

  search(options: searchOptions, items: any[]) {

    if (!!items && !!items.length) {

      let query: string = `${options.query}`.toLowerCase(),
        searchKeys: string[] = (options.keys || ['name', 'title', 'uid', 'url']);

      items = items.filter((item: any) => {
        let blShow: boolean = (!query || !query.length);

        if (!!query) {
          searchKeys.forEach((searchKey: string) => {
            if (item.hasOwnProperty(searchKey)) {
              blShow = (blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().indexOf(query) !== -1);
            }
          });
        }

        return blShow;
      });
    }

    return items;
  }

}
import { Injectable } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { EventsService } from "src/app/services/core/events.service";
import { FriendsService } from "src/app/services/social/friends.service";
import { PushnotificationsService } from 'src/app/services/core/pushnotifications.service';
import { ToolsService } from 'src/app/services/utils/tools.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class MessagesService {

  chatCache = window.localStorage.getItem("chats")
    ? JSON.parse(window.localStorage.getItem("chats"))
    : {};

  partnerId: number;

  user: user;

  constructor(
    private events: EventsService,
    private AppCMS: AppcmsService,
    private friends: FriendsService,
    private userProvider: UserService,
    private PushnotificationsProvider: PushnotificationsService,
    private _sanitizer: DomSanitizer,
    private tools: ToolsService,
    private translations: TranslationService,
    private userService: UserService,
  ) {
    this.user = this.userProvider.getUser();

    this.events.subscribe('message:send', (config: any) => {
      this.send(config)
        .catch((error: any) => {
          console.warn('messages: send event error (1)', error);
        });
    });

  }

  afterMessageSend(sendResponse: any, message: message) {
    return new Promise((resolve, reject) => {
      if(!!message.group) {
        this.afterMessageSendToGroup(sendResponse, message).then(resolve).catch(reject);
      } else
      if(!!message.partner) {
        this.userProvider.getByUid(message.partner)
        .then((partner: any) => {

          // send push if partner has registered to push
          if (partner.classifications && partner.classifications.pushId) {
            this.PushnotificationsProvider
              .send({
                include_player_ids: [partner.classifications.pushId],
                headings: {
                  en: this.user.firstname + " " + this.user.lastname,
                },
                contents: { en: message.description },
              })
              .then(() => {
                resolve(sendResponse);
              })
              .catch((pushError: any) => {
                console.warn('> pushError', pushError);
                resolve(sendResponse);
              });
          } else {
            resolve(sendResponse);
          }

        })
        .catch(reject);
      } else {
        reject('error_missing_group_and_partner_uids');
      }
    });
  }

  afterMessageSendToGroup(sendResponse: any, message: message) {
    return new Promise((resolve, reject) => {
      resolve(sendResponse);
    });
  }

  archiveMessage(message: inboxChatMessage) {
    return this.archiveMessages({
      partner: message.partner,
      user: (message.user && !!message.user.uid ? message.user.uid : message.user),
    });
  }

  archiveMessages(options: any) {
    return this.AppCMS.loadPluginData(
      "messages",
      {
        user: this.userProvider.getUid(),
        filter: (typeof options === 'object' ? options : {}),
      },
      ['archive']
    );
  }

  deleteConversation(partnerId: number) {
    return this.AppCMS.loadPluginData(
      "messages",
      {
        user: this.userProvider.getUid(),
        partnerId: partnerId,
      },
      ["deleteConversation"]
    );
  }

  deleteMessage(message: inboxChatMessage) {
    return this.AppCMS.loadPluginData(
      "messages",
      {
        user: this.userProvider.getUid(),
        uid: message.uid,
      },
      ['delete']
    );
  }

  getCachedChat(partnerId: number) {
    const chat = this.chatCache.hasOwnProperty(partnerId)
      ? JSON.parse(this.chatCache[partnerId])
      : [];

    if (chat.messages && chat.messages.length) {
      chat.messages.forEach((message: inboxChatMessage) => {
        if (message.item && typeof message.item === 'object') {
          message.item.style = {
            'backgroundUrl': message.item && message.item.thumbnail ? this._sanitizer.bypassSecurityTrustStyle(`url('${message.item.thumbnail}')`) : '',
          };
        }
      });
      chat.messages.sort((a: any, b: any) => {
        if (a.timestamp < b.timestamp) return -1;
        if (a.timestamp > b.timestamp) return 1;
        return 0;
      });
    }

    return chat;
  }

  getChat(partnerId: number, timestamp = undefined) {
    return this.AppCMS.loadPluginData("messages", {
      user: this.userProvider.getUid(),
      partnerId: partnerId,
    });
  }

  getChats() {
    return this.AppCMS.loadPluginData("messages", {
      user: this.userProvider.getUid(),
    });
  }

  getFullMessage(message: any) {
    const description = this.htmlDecode(message.description);

    message.message_type = `${description || ''}`.indexOf("<img") === 0 ? 'image' : 'text';

    if (message.message_type == 'image') {
      let image: any = document.createElement('div');
      image.innerHTML = description;

      const url: string|null = image.querySelector('img')?.getAttribute('src');

      message.description = "Bild";
      message.image = {
          url: url,
      };
    }

    if (message.item && typeof message.item === 'object') {
      message.item.style = {
        'backgroundUrl': message.item.thumbnail ? this._sanitizer.bypassSecurityTrustStyle(`url('${message.item.thumbnail}')`) : '',
      };
    }

    if(message.user && message.user.uid) {
      message.user = this.userService.getFullUser(message.user);
      message.user.displayName = this.userService.getDisplayName(message.user);
    }

    return message;
  }

  getPartnerId() {
    return this.partnerId;
  }

  htmlDecode(input: any) {
    var e = document.createElement("div");
    e.innerHTML = input;
    
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  markAsRead(messages: any[]) {
    return this.AppCMS.loadPluginData(
      "messages",
      {
        messages: messages,
      },
      ["markAsRead"]
    );
  }

  parse(message: any) {
    message.time_received = moment(message.last_updated).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    message.timestamp = moment(
      message.last_updated,
      "YYYY-MM-DD HH:mm:ss"
    ).unix();
    return message;
  }

  /*
  search(query: string) {
    return new Promise((resolve, reject) => {
      this.getChats()
        .then((chats) => {
          resolve(
            Object.keys(chats).filter((chatKey) => {
              let chat = chats[chatKey];
              console.log("chat", chat);
            })
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  */

  send(message: message) {

    if (!message.description && (message.data && message.data.description)) {
      message.description = message.data.description;
    }

    if (!message.item && (message.data && message.data.item)) {
      message.item = message.data.item;
    }

    if (!message.partner && (message.data && message.data.parent)) {
      message.partner = message.data.parent;
    }

    if (!message.group && (message.data && message.data.group)) {
      message.group = message.data.group;
    }

    if (!message.photo && (message.data && message.data.photo)) {
      message.photo = message.data.photo;
    }

    return new Promise((resolve, reject) => {

      if (!message.partner && !message.group) {
        reject('error_push_profile_missing');
        return;
      }

      if (!message.description) {
        reject('error_missing_push_description');
        return;
      }

      message.user = this.userProvider.getUid();

      this.AppCMS.loadPluginData("messages", {
        message: message
      }, ['send'])
        .then((sendResponse: any) => {
          this.afterMessageSend(sendResponse, message).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  sendFriendTagNotification(profileId: number, post: post) {
    return new Promise((resolve, reject) => {
      const user: user = this.userService.getUser() || {};

      if (!user || !user.uid) {
        reject('error_missing_user_uid');
        return;
      }

      this.userService.getByUid(profileId)
        .then((profile: any) => {

          if (!profile || !profile.uid) {
            reject('error_profile_not_found');
            return;
          }

          if (!profile.classifications.pushId || !profile.classifications.pushId.length) {
            reject('error_missing_profile_pushId');
            return;
          }

          let config = {
            title: (user.nickname || (user.firstname + ' ' + user.lastname[0] + '.')) + ' emp­fiehlt Dir:',
            description: post.name || 'Schau\' ihn Dir an',
            data: {
              action: 'viewPost',
              photo: post.thumbnail,
              parent: profile.uid,
              user: user.uid,
              item: post.uid,
              timestamp: Math.floor(new Date().getTime() / 1000),
            },
            recipients: [profile.classifications.pushId],
          };

          this.send(config).then(resolve).catch(reject);
        })
        .catch(reject);
    });
  }

  sendPushToFriend(partner: any, options: any = {}) {
    let friendId = typeof partner === 'object' ? partner.uid : partner;

    return new Promise((resolve, reject) => {
      options.data = options.data || {};

      const user = options.user ? options.user : this.userService.getUser() || {};

      if (!user || !user.uid) {
        reject('error_missing_user_uid');
      } else {
        this.userService.getByUid(friendId)
          .then((friend: user) => {
            options.data.timestamp = options.data.timestamp || moment().unix();

            this.translations.get([
              'view_notification_action',
              'user_likes_content',
            ])
              .subscribe((translations: any) => {
                
                let recipients = [friend.classifications.pushId],
                  fallbackDescription = translations.view_notification_action || 'view_notification_action',
                  itemLabel = options.data && options.data.item ? this.tools.decodeEntities(options.data.item.name || options.data.item.post_title) : 'etwas in pipeline',
                  title = translations.user_likes_content.replace(/\$ /g, '$').replace('$user', user.firstname).replace('$item', this.tools.trim(itemLabel)),
                  description = options.description || (options.data && options.data.item ? (options.data.item.excerpt ? options.data.item.excerpt.rendered : (options.data.item.content || options.data.item.post_content)) : fallbackDescription);

                description = description || fallbackDescription;
                description = this.tools.trim(description.replace(/<[^>]*>?/gm, ''));

                if (options.data.item && (options.data.item.ID || options.data.item.uid)) {
                  options.data.item = (options.data.item.ID || options.data.item.uid);
                }

                if (!recipients.length) {
                  console.warn('no recipients to send');
                  resolve({});
                  return;
                }

                options.data.user = user.uid;

                let config = {
                  title: title,
                  description: description.length > 100 ? description.substr(0, 100) + '...' : description,
                  data: options.data,
                  recipients: recipients,
                };

                this.events.publish('push:send', config);
                resolve(config);
              });
          })
          .catch(reject);
      }
    });
  }

  sendPushToFriends(options: any = {}) {
    return new Promise((resolve, reject) => {
      options.data = options.data || {};

      let user = options.user ? options.user : this.userService.getUser() || {};

      if (!user || !user.uid) {
        reject('error_missing_user_uid');
      } else {
        this.friends.getAll(user.uid)
          .then((friendsList: any) => {
            options.data.timestamp = options.data.timestamp || moment().unix();

            this.translations.get([
              'view_notification_action',
              'user_likes_content',
            ])
              .subscribe((translations: any) => {

                let recipients = [],
                  fallbackDescription = translations.view_notification_action || 'view_notification_action',
                  itemLabel = options.data && options.data.item ? (options.data.item.name || options.data.item.post_title) : 'etwas in pipeline',
                  title = translations.user_likes_content.replace(/\$ /g, '$').replace('$user', user.firstname).replace('$item', itemLabel),
                  description = options.data && options.data.item ? (options.data.item.excerpt ? options.data.item.excerpt.rendered : (options.data.item.content || options.data.item.post_content)) : fallbackDescription;

                description = description || fallbackDescription;
                description = description.replace(/<[^>]*>?/gm, '');

                if (options.data.item && (options.data.item.ID || options.data.item.uid)) {
                  options.data.item = (options.data.item.ID || options.data.item.uid);
                }

                if (!friendsList || !friendsList.length) {
                  console.warn('no friends to send');
                  resolve({});
                  return;
                }

                friendsList.forEach((friend: user) => {
                  if (friend && friend.classifications && !!friend.classifications.pushId && (Math.random() < 0.1)) {
                    recipients.push(friend.classifications.pushId);
                  }
                });

                if (!recipients.length) {
                  console.warn('no recipients to send');
                  resolve({});
                  return;
                }

                options.data.user = user.uid;

                const config = {
                  title: title,
                  description: description.length > 100 ? description.substr(0, 100) + '...' : description,
                  data: options.data,
                  recipients: recipients,
                };

                this.events.publish('push:send', config);
                resolve(config);
              });
          })
          .catch(reject);
      }
    });
  }

  setCachedChat(partnerId: number, chat) {
    this.chatCache[partnerId] = JSON.stringify(chat);
    window.localStorage.setItem('chats', JSON.stringify(this.chatCache));
    return this.getCachedChat(partnerId);
  }

  setPartnerId(partnerId: number) {
    this.partnerId = partnerId;
  }

  toCachedChat(partnerId: number, message) {
    this.chatCache[partnerId] = this.chatCache[partnerId]
      ? JSON.parse(this.chatCache[partnerId])
      : [];
    this.chatCache[partnerId].push(message);
    this.chatCache[partnerId] = JSON.stringify(this.chatCache[partnerId]);
    window.localStorage.setItem('chats', JSON.stringify(this.chatCache));
  }

  unarchiveMessage(message: inboxChatMessage) {
    return this.archiveMessages({
      partner: message.partner,
      user: (message.user && !!message.user.uid ? message.user.uid : message.user),
    });
  }

  unarchiveMessages(options: any) {
    return this.AppCMS.loadPluginData(
      "messages",
      {
        user: this.userProvider.getUid(),
        filter: (typeof options === 'object' ? options : {}),
      },
      ['unarchive']
    );
  }

}
<ion-header color="primary" class="ion-no-border">
  <ion-toolbar color="primary" class="ion-no-border">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title [innerHTML]="'cookies_headline'|translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="primary" class="ion-padding">
  <p [innerHTML]="'cookies_text'|translate"></p>
</ion-content>

<ion-footer color="primary" class="ion-no-border">
  <ion-toolbar color="primary" class="ion-no-border">
    <ion-grid>
      <ion-row>
        <ion-col size="6">
          <ion-button (click)="accept()" color="white" expand="block">
            <ion-label [innerHTML]="'okay'|translate"></ion-label>
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button (click)="more()" color="white" fill="outline" expand="block">
            <ion-label [innerHTML]="'more_about'|translate"></ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
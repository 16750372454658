<ion-header class="ion-no-border">
    <ion-toolbar class="container">
  
      <ion-buttons slot="start">
        <ion-button (click)="dismiss()" icon-only>
          <ion-icon name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
      
      <ion-title [innerHTML]="'create_coupon'|translate" *ngIf="view.mode === 'create'"></ion-title>
      <ion-title [innerHTML]="coupon.title" *ngIf="view.mode === 'edit'"></ion-title>
  
      <ion-buttons slot="end">
  
        <ion-button (click)="create()" *ngIf="view.mode === 'create'" fill="solid" color="primary" shape="round">
          <ion-icon name="checkmark-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'create'|translate" [hidden]="!view.isWeb"></ion-label>
        </ion-button>
  
        <ion-button (click)="update()" *ngIf="view.mode === 'edit'" fill="solid" color="primary" shape="round">
          <ion-icon name="checkmark-outline" slot="start"></ion-icon>
          <ion-label [innerHTML]="'update'|translate" [hidden]="!view.isWeb"></ion-label>
        </ion-button>
  
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  
  <ion-content>
  
    <div class="container">
  
      <ion-card>
  
        <ion-thumbnail [class.hasPhoto]="!!coupon.photo">
          <label class="custom-file-upload" (click)="uploadPhoto()">
            <img [src]="coupon.photo" [hidden]="!coupon.photo" class="thumbnail-preview" />
            <ion-skeleton-text animated class="image" [hidden]="!view.uploading"></ion-skeleton-text>
            <ion-icon name="image-outline" [hidden]="!!coupon.photo"></ion-icon>
          </label>
        </ion-thumbnail>
  
        <ion-list>
  
          <ion-item>
            <ion-label [innerHTML]="'coupon_name'|translate" position="stacked"></ion-label>
            <ion-input [(ngModel)]="coupon.title"></ion-input>
          </ion-item>
  
          <ion-item>
            <ion-label [innerHTML]="'coupon_description'|translate" position="stacked"></ion-label>
            <ion-textarea [(ngModel)]="coupon.description"></ion-textarea>
          </ion-item>
  
        </ion-list>
      </ion-card>
  
      <ion-card>
        <ion-grid>
          <ion-row>
            <ion-col>
              <ion-item lines="none">
                <ion-label [innerHTML]="'coupon_date_start'|translate" position="stacked"></ion-label>
                <ion-datetime displayFormat="YYYY-MM-DDTHH:mm:ss" presentation="date-time" [preferWheel]="true" [(ngModel)]="coupon.start_date"></ion-datetime>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item lines="none">
                <ion-label [innerHTML]="'coupon_date_end'|translate" position="stacked"></ion-label>
                <ion-datetime displayFormat="YYYY-MM-DDTHH:mm:ss" presentation="date-time" [preferWheel]="true" [(ngModel)]="coupon.end_date"></ion-datetime>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-card>
  
      <ion-card>
        <ion-list>
            
          <ion-item>
            <ion-label [innerHTML]="'coupon_code'|translate" position="stacked"></ion-label>
            <ion-input [(ngModel)]="coupon.indent"></ion-input>
          </ion-item>

          <ion-item>
            <ion-label [innerHTML]="'coupon_link'|translate" position="stacked"></ion-label>
            <ion-input [(ngModel)]="coupon.link"></ion-input>
          </ion-item>
  
        </ion-list>
      </ion-card>
  
    </div>
  
  </ion-content>
  
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { AiToolsService } from 'src/app/services/ai/ai-tools.service';
import { ConfigService } from "src/app/services/core/config.service";
import { EventsService } from 'src/app/services/core/events.service';
import { IntegrationsService } from 'src/app/services/integrations/integrations.service';
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ModalService } from 'src/app/services/core/modal.service';
import { PaginationService } from 'src/app/services/utils/pagination.service';
import { SidebarService } from 'src/app/services/utils/sidebar.service';
import { ToolsService } from "src/app/services/utils/tools.service";
import { ProjectsService } from 'src/app/services/core/projects.service';

import { HeaderSearchToolbarComponent } from 'src/app/components/generic/header/header-search-toolbar/header-search-toolbar.component';

import { AiModelPage } from '../ai-model/ai-model.page';

@Component({
  selector: 'app-ai-models',
  templateUrl: './ai-models.page.html',
  styleUrls: ['./ai-models.page.scss'],
})
export class AiModelsPage implements OnInit {
  @ViewChild('headerPopover') headerPopover;
  @ViewChild(HeaderSearchToolbarComponent) searchToolbar: any;

  isHeaderPopoverOpen: boolean = false;

  appConfig: pipelineAppConfig;

  cards: any = {};

  fallbackImg: string = './assets/img/fallback.webp';

  filter: any;

  integrationsByType: any;

  multiple: boolean;

  paginationConfig: paginationConfig = {
    itemsKey: 'models',
    limit: 500,
  };

  search: any = {
    itemsKey: 'models',
    keys: ['title', 'description', 'name', 'url', 'uid'],
    query: '',
  };

  state: any = {};

  view: any = {
    categories: [],
    categoryIcons: {
      "audio-classification": 'text-outline',
      "audio-to-text": 'recording-outline',
      "automatic-speech-recognition": 'recording-outline',
      checkpoint: "document-outline",
      "depth-estimation": "layers-outline",
      "feature-extraction": "layers-outline",
      "fill-mask": "sparkles-outline",
      hypernetwork: 'diamond-outline',
      "image-classification": 'text-outline',
      "image-feature-extraction": "sparkles-outline",
      "image-segmentation": 'locate-outline',
      "image-text-to-text": 'text-outline',
      "image-to-3d": 'cube-outline',
      "image-to-image": 'brush-outline',
      "image-to-text": 'text-outline',
      "image-to-video": 'film-outline',
      img2img: 'brush-outline',
      img2txt: 'text-outline',
      img2vid: 'film-outline',
      locon: 'diamond-outline',
      lora: 'diamond-outline',
      "mask-generation": "sparkles-outline",
      motionmodule: 'film-outline',
      "object-detection": "scan-outline",
      other: "ellipsis-horizontal-outline",
      poses: 'image-outline',
      "question-answering": "information-outline",
      summarization: 'text-outline',
      "table-question-answering": "information-outline",
      "text-classification": 'text-outline',
      "text2text-generation": 'text-outline',
      "text-generation": 'text-outline',
      "text-to-image": 'image-outline',
      "text-to-speech": 'mic-outline',
      "text-to-text": 'text-outline',
      "text-to-video": 'film-outline',
      "text-to-audio": 'mic-outline',
      textualinversion: 'text-outline',
      "time-series-forecasting": "time-outline",
      translation: "language-outline",
      txt2img: 'image-outline',
      txt2txt: 'text-outline',
      txt2vid: 'film-outline',
      txt2wav: 'mic-outline',
      vae: 'diamond-outline',
      "video-classification": 'text-outline',
      "video-to-text": 'text-outline',
      vid2txt: 'text-outline',
      upscaler: 'expand-outline',
      "visual-question-answering": "information-outline",
      wav2txt: 'recording-outline',
      wildcards: "document-outline",
      "zero-shot-classification": "layers-outline",
      "zero-shot-image-classification": 'text-outline',
      "zero-shot-object-detection": "scan-outline",
    },
    filters: {
      category: 'all',
      provider: 'all',
    },
    hideGetGeniusWallet: true,
    hideOrderByBtn: true,
    hideSearch: true,
    input: '',
    introCard: {
      uid: 'ai_models_top_card',
      lottieSrc: './assets/lottie/light_bulb.json',
      text: 'ai_models_top_card_text',
      title: 'ai_models_top_card_title',
    },
    mode: 'view',
    multiple: true,
    output: '',
    providers: [],
    route: 'ai/models',
    selectionOptions: [
      {
        icon: 'trash-outline',
        label: 'delete',
        uid: 'delete',
      },
      {
        icon: 'copy-outline',
        label: 'duplicate',
        uid: 'duplicate',
      }
    ],
    showMenuButton: true,
    showPagination: true,
    showProjectsSelect: true,
    showViewModeSelect: true,
    title: 'ai_models',
    viewType: 'grid',
  };

  constructor(
    private ai: AiToolsService,
    private configService: ConfigService,
    private events: EventsService,
    private integrations: IntegrationsService,
    private media: MediaextendService,
    private modalService: ModalService,
    private pagination: PaginationService,
    private projects: ProjectsService,
    private sidebar: SidebarService,
    private tools: ToolsService,
  ) {
    this.appConfig = this.configService.getConfig();
    this.integrationsByType = this.integrations.getByType();
  }

  async add(event: any = null) {

    const modal: any = await this.modalService.create({
      component: AiModelPage,
      animated: true,
      presentingElement: await this.modalService.getTop(),
      cssClass: 'defaultModal'
    });

    modal.onWillDismiss().then(() => {
      this.doRefresh();
    });

    this.modalService.present(modal);
  }

  async applyPaginationFromResponse(models: aiModel[], response: any) {

    if (!!response && !!response.pagination) {
      this.paginationConfig = this.paginationConfig || {};

      if (!!models && !!models.length) {
        this.paginationConfig.backup = models;
      }

      if (!!response.pagination.count) {
        this.paginationConfig.count = response.pagination.count;
      }

      if (!!response.pagination.max) {
        this.paginationConfig.limit = response.pagination.max;
      }
    }

    return response;
  }

  calcColSize() {
    this.view.isDesktop = this.tools.isDesktop();
    this.view.colSize = (!!this.view.isDesktop ? 4 : 12);
  }

  async calculatePagination(posts: post[] | null = null) {
    posts = (posts || this.view.posts_backup) || [];

    this.paginationConfig = this.paginationConfig || {};

    if (!!posts && !!posts.length) {
      this.view.posts_backup = (posts || []);
      this.paginationConfig.backup = JSON.parse(JSON.stringify([...posts as post[]]));
    }

    const calc: any = await this.pagination.calculateConfig(
      Object.assign(this.view, {
        posts: posts,
        posts_backup: posts,
      }),
      this.paginationConfig
    );

    if (!!calc && !!calc.view) {
      this.view = Object.assign(this.view, calc.view);
    }

    console.log('paginationConfig', this.paginationConfig);

    return this.view;
  }

  calcViewVars() {
    this.view.isModal = this.modalService.isModal();
    this.view.mode = (this.view.isModal ? 'pick' : 'view');

    if (this.multiple !== null && this.multiple !== undefined) {
      this.view.multiple = !!this.multiple;
    }

    this.calcColSize();
  }

  delete(model: aiModel) {

  }

  deleteSelected() {

    if (!this.view.selectedItems || !this.view.selectedItems.length) {
      return false;
    }

    new Promise((resolve, reject) => {
      let i: number = 0;

    })
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  dismiss(data: any = null) {
    this.modalService.dismiss(data);
  }

  doRefresh(event: any = null) {
    this.loadModels(true)
      .then(() => {
        if (!!event) {
          event.target.complete();
        }
        this.runSearch();
      })
      .catch((error: any) => {
        if (!!event) {
          event.target.complete();
        }
        this.events.publish('error', error);
      });
  }

  duplicateSelected() {

  }

  filterCategory(category: any) {
    this.view.filters.category = category.uid;

    let iVisible: number = 0;

    this.view.models.forEach((model: any) => {
      model.hidden = (category.uid !== 'all') && `${model.category || ''}`.toLowerCase() !== this.view.filters.category;

      if (!model.hidden) {
        iVisible++;
      }
    });

    this.view.hasFilter = true;
    this.view.filterHasResults = !!iVisible;
  }

  filterProvider(provider: any) {
    this.view.filters.provider = provider.uid;

    let iVisible: number = 0;

    this.view.models.forEach((model: any) => {
      model.hidden = (provider.uid !== 'all') && `${model.provider || ''}`.toLowerCase() !== this.view.filters.provider;

      if (!model.hidden) {
        iVisible++;
      }
    });

    this.view.hasFilter = true;
    this.view.filterHasResults = !!iVisible;
  }

  filterUI() {
    if (!!this.filter) {
      console.log('filter data', this.filter);

      if (!!this.filter.category) {
        this.filterCategory({
          uid: this.filter.category,
        });
      }
    }
  }

  initEvents() {
    this.view.events = {};

    this.view.events.projectCurrentUpdated = this.events.subscribe('project:current:updated', () => {
      this.doRefresh();
    });

  }

  ionViewWillEnter() {
    this.initEvents();

    this.loadModels()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  ionViewWillLeave() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  async loadCards() {
    try {
      this.cards = (await this.sidebar.getCards() || (this.cards || {}));
    } catch (e) {
      console.warn('loading cards states failed', e);
    }
  }

  loadModels(blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.view.loading = true;

      this.ai.getAvailableModels({}, blForceRefresh)
        .then((response: any) => {
          console.log('ai-models: response', response);

          const models: aiModel[] = (!!response && !!response.data ? response.data : []);

          // apply pagination (count) from response if provided
          this.applyPaginationFromResponse(models, response);

          this.view.models = models;
          this.view.models_backup = models;

          this.view.loading = false;

          let categoriesStrings: string[] = [],
            categories: any[] = [],
            providerStrings: string[] = [],
            providers: any[] = [];

          models.forEach((model: any) => {
            model.category = `${model.category || ''}`;

            const categoryId: string | null = (!!model.category ? model.category.toLowerCase() : null);
            const providerId: string | null = (!!model.provider ? `${model.provider || ''}`.toLowerCase() : null);

            if (!!categoryId && (categoriesStrings.indexOf(categoryId) === -1)) {

              categories.push({
                icon: this.view.categoryIcons[model.category.toLowerCase()],
                name: model.category,
                name_formatted: model.category.split('-').map((part: string) => { return this.tools.capitalize(`${part || ''}`); }).join(' '),
                uid: categoryId,
              });

              categoriesStrings.push(categoryId);
            }

            if (!!providerId && (providerStrings.indexOf(providerId)) === -1) {

              providers.push({
                name: model.provider,
                photo: model.photo,
                uid: providerId,
              });

              providerStrings.push(providerId);
            }

          });

          const allItem: any = { icon: 'list-outline', name: 'all', uid: 'all' };

          this.view.categories = [allItem].concat(categories);
          this.view.providers = [allItem].concat(providers);

          this.filterUI();

          try {
            this.searchToolbar.updateItems(models);
          } catch (e) {
            console.warn('searching failed', e);
          }

          this.calculatePagination();

          resolve(models);
        })
        .catch((error: any) => {
          this.view.loading = false;
          reject(error);
        });
    });
  }

  async loadPlatforms() {
    return new Promise((resolve, reject) => {
      this.integrations.getEnabled()
        .then((integrations: integration[]) => {

          if (!!integrations && !!integrations.length && !!this.integrationsByType.compute) {
            integrations = integrations.filter((integration: integration) => {
              integration.indent = integration.indent || `${integration.name}`.replace('integration_', '');
              return this.integrationsByType.compute.indexOf(integration.indent) !== -1;
            });
          }

          this.view.platforms = (integrations || []).map((integration: any) => {
            integration.checked = true;
            return integration;
          });

          //this.view.connections = (this.view.connections || this.view.platforms);

          resolve(this.view);
        })
        .catch(reject);
    });
  }

  async loadProject() {
    this.view.project = await this.projects.getCurrent();
  }

  ngOnInit() {
    this.calcViewVars();

    this.loadProject();
    this.loadCards();

    this.loadPlatforms();
  }

  onConnectionsFilterChanged(connections: any[] | null = []) {
    this.view.options = this.view.options || {};
    this.view.options.filters = this.view.options.filters || {};
    this.view.options.filters.connections = (connections || []).map((connection: integrationConnection) => {
      return connection.uid;
    });

    console.log('this.view.options.filters.connections', this.view.options.filters.connections);

    this.doRefresh();
  }

  onItemChecked(model: aiModel) {

    this.view.selectedItems = this.view.models.filter((_model: aiModel) => {
      return _model.checked;
    });

    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  @HostListener('window:resize')
  onResize() {
    this.calcColSize();
  }

  onSearchChanged(searchOptions: any = null) {
  }

  onSelectionActionChanged(event: any = null) {

    switch (this.view.selectionAction) {
      case 'delete':
        this.deleteSelected();
        break;
      case 'duplicate':
        this.duplicateSelected();
        break;
    }

  }

  onSingleSelectedModelChanged() {

    if (!!this.multiple || !this.view.singleSelectedModel) {
      return false;
    }

    this.view.singleSelectedModel.checked = true;
    this.view.selectedItems = [this.view.singleSelectedModel];
    this.view.hasSelectedItems = (!!this.view.selectedItems && !!this.view.selectedItems.length);
  }

  runSearch() {
    try {
      this.searchToolbar.runSearch();
    } catch (e) {
      console.error('firing toolbar search failed', e);
    }
  }

  showHeaderPopover(event: any = null) {
    this.headerPopover.event = event;
    this.isHeaderPopoverOpen = true;
  }

  submitSelected(event: any = null) {
    return this.dismiss({
      item: (!!this.view.selectedItems && !!this.view.selectedItems[0] ? this.view.selectedItems[0] : null),
      items: (this.view.selectedItems || []),
    });
  }

  tabChanged() {

  }

  thumbnailLoadingFailed(item: any) {
    item.photo = this.fallbackImg;
  }

  toggleCard(cardName: string) {

    if (!this.cards[cardName]) {
      this.cards[cardName] = {};
    }

    this.cards[cardName].open = !this.cards[cardName].open;

    this.sidebar.setCards(this.cards);
  }

  upload(params: any = {}) {
    params.mediaType = 'attachment';
    params.multiple = true;
    params.services = ['upload'];

    this.media.applyFromWeb(null, params)
      .then(() => {
        this.doRefresh();
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

  use(model: aiModel) {

  }

  viewModeChanged(event: any = null) {
    this.calcViewVars();
  }

}
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { AvatarService } from 'src/app/services/utils/avatar.service';
import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class FriendsService {

  constructor(
    private AppCMS: AppcmsService,
    private avatar: AvatarService,
    private cache: CacheService,
    private events: EventsService,
    private _sanitizer: DomSanitizer,
    private translations: TranslationService,
    private userService: UserService,
  ) {
  }

  addFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.userService.getUid()
        },
        ['add']
      )
      .then((response: any) => {
        this.cache.remove(`profile_${profileId}_{}`);
        this.sendFriendFollowedNotification(profileId)
        .catch((error: any) => {
          console.error('addFriend push error', error);
        });
        resolve(response);
      })
      .catch(reject);
    });
  }

  asCols(profileId: number = null, friends: user[] = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      new Promise((resolve, reject) => {
        if(friends) {
          resolve(friends);
        } else {
          this.getAll(profileId, {}, blForceRefresh).then(resolve).catch(reject);
        }
      })
      .then((friends: user[]) => {
        const user = this.userService.getUser() || {};
        let cols: any[] = [];
        
        if(friends && friends.length) {  
          friends = friends.filter((friend: user) => {
            return friend.uid !== user.uid && friend.uid !== profileId;
          });
          friends.forEach((person: user) => {
            const coverImg = this.userService.getCoverImage(person);
            const col: any = {
              avatar: person.photo || (this.avatar.getAvatars()[0].src),  
              category: 'people',
              isFriend: true,
              name: this.userService.getDisplayName(person),
              style: {
                'backgroundUrl': (!!coverImg && coverImg.length) ? this._sanitizer.bypassSecurityTrustStyle('url("' + coverImg + '")') : '',
              },
              type: 'person',
              uid: person.uid,
              verified: (this.userService.isType('Admin', person) || this.userService.isType('Blog', person)),
            };
            cols.push(col);
          }); 
        }
        resolve(cols);
      })
      .catch(reject);
    });
  }

  asRows() {
    return new Promise((resolve, reject) => {
      this.asCols()
      .then((cols: any) => {
        resolve([{ cols: cols}]);
      })
      .catch(reject);
    });
  }

  getAll(profileId: number = null, options: any = {}, blForceRefresh: boolean = false) {
    return new Promise(async (resolve, reject) => {
      profileId = profileId || this.userService.getUid();

      this.AppCMS.loadPluginData('friends', Object.assign(options, {
        user: profileId,
        expanded: false,
      }), ['all'], {}, blForceRefresh)
      .then((friends: user[]) => {
        
        if(friends && friends.length) {
          friends = friends.filter((friend: user) => {
            return friend && friend.uid;
          });
          friends = this.userService.parseUsers(friends);
        }

        resolve(friends);
      })
      .catch(reject);
    });
  }

  isFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          userId: this.userService.getUid()
        },
        ['isFriend']
      )
      .then((response: any) => {
        resolve(!!response.value);
      })
      .catch(reject);
    });
  }

  removeFriend(profileId: number) {
    return new Promise((resolve, reject) => {
      this.AppCMS.loadPluginData(
        'friends',
        {
          partner: profileId,
          user: this.userService.getUid()
        },
        ['remove']
      )
      .then(response => {
        this.cache.remove(`profile_${profileId}_{}`);
        resolve(response);
      })
      .catch(reject);
    });
  }

  search(query: string, blForceRefresh: boolean = true) {
    return new Promise(async (resolve, reject) => {
      query = query.replace(/\s+$/, '');

      /*
      this.searchService.run(
        {
          query: query,
          filter: {
            active: true,
          }
        },
        {},
        blForceRefresh
      )
      .then((response: searchResponse) => {
        resolve(response);
      })
      .catch(reject);
      */
     
    });
  }

  sendFriendFollowedNotification(profileId: number) {

    return new Promise((resolve, reject) => {
      const user: user = this.userService.getUser() || {};

      if(!user || !user.uid) {
        reject('error_missing_user_uid');
        return;
      }

      this.userService.getByUid(profileId)
      .then((profile: user) => {

        if(!profile || !profile.uid) {
          reject('error_profile_not_found');
          return;
        }

        if(profile.uid === user.uid) {
          reject('error_profile_uid_is_user_uid');
          return;
        }
        
        if(!profile.classifications.pushId || !profile.classifications.pushId.length) {
          reject('error_missing_profile_pushId');
          return;
        }
  
        this.translations.get([
          'user_is_following_you',
          'view_profile_action',
        ])
        .subscribe((translations: any) => {

          let config = {
            title: translations.user_is_following_you.replace(/\$ /g, '$').replace('$user', (user.nickname || (user.firstname + ' ' + user.lastname[0] + '.'))),
            description: translations.view_profile_action || 'view_profile_action',
            data: {
              action: 'viewProfile',
              parent: profile.uid,
              user: user.uid,
              timestamp: Math.floor(new Date().getTime() / 1000),
            },
            recipients: [profile.classifications.pushId],
          };

          this.events.publish('push:send', config);

          resolve(config);
        });
      })
      .catch(reject);
    });
  }

}

<ion-header class="ion-no-border">
  <ion-toolbar class="ion-no-border">
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  
  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content fullscreen="true">
  
  <ion-card *ngIf="basketStorage && !basketStorage.length">
    <ion-card-header>
      <ion-card-title [innerHTML]="'empty_basket'|translate"></ion-card-title>
      <p [innerHTML]="'empty_basket_text'|translate"></p>
    </ion-card-header>
    <ion-card-content>
      <ion-button (click)="shop()" expand="block" fill="outline" color="dark">
        <ion-icon name="chevron-back-outline" slot="start"></ion-icon>
        <ion-label [innerHTML]="'go_to_home'|translate"></ion-label>
      </ion-button>
    </ion-card-content>
  </ion-card>

  <ion-card *ngIf="basketStorage && basketStorage.length">
    <ion-card-content>
      <ion-card-title
        [innerHTML]="'confirm_order_headline'|translate"
      ></ion-card-title>
      <ion-card-subtitle
        [innerHTML]="'confirm_order_subheadline'|translate"
      ></ion-card-subtitle>
    </ion-card-content>
    <ion-list lines="none">
      <ion-item *ngFor="let item of basketStorage; let i = index">
        <ion-thumbnail slot="start" *ngIf="item.images && item.images[0]">
          <ion-img [src]="item.images[0].src"></ion-img>
        </ion-thumbnail>
        <ion-label (click)="viewItem(item, i)" class="ion-text-wrap">
          {{(item.amount || 1)}}x <span [innerHTML]="item.name"></span><br />
          <b *ngIf="item.price">
            {{((item.amount || 1) * item.price).toFixed(2)}}€
          </b>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-card>
</ion-content>

<ion-footer class="ion-no-border" *ngIf="basketStorage && basketStorage.length">
  <ion-list lines="none">
    <ion-item>
      <ion-label [innerHTML]="'total_price'|translate"></ion-label>
      <ion-note slot="end" *ngIf="basketInfo.price_a">
        {{basketInfo.price_a}}€
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-label [innerHTML]="'payment_method'|translate"></ion-label>
      <ion-chip
        slot="end"
        (click)="changePayment()"
        *ngIf="paymentOptions.paymentMethod"
      >
        {{paymentOptions.paymentMethod|translate}}
      </ion-chip>
    </ion-item>
  </ion-list>
  <ion-toolbar>
    <ion-button [disabled]="block" (click)="pay()" expand="block" color="primary">
      <ion-icon name="checkmark" slot="start"></ion-icon>
      <ion-label [innerHTML]="'order_now'|translate"></ion-label>
    </ion-button>
  </ion-toolbar>
</ion-footer>

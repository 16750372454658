<ion-card class="sidebar-accordion-card ai-styles-card">

  <ion-card-header [hidden]="!view.expertMode">
    <ion-card-title [innerHTML]="'ai_style'|translate"></ion-card-title>


    <ion-button *ngIf="!!cards && !!cards.ai_style" class="card-toggle" (click)="toggleCard()" icon-only color="dark"
      fill="clear">
      <ion-icon [name]="!!cards && !!cards.ai_style && !!cards.ai_style.open ? 'chevron-up' : 'chevron-down'"></ion-icon>
    </ion-button>

  </ion-card-header>

  <ion-spinner [hidden]="!view.loadingStyles"></ion-spinner>

  <div [hidden]="!!view.loadingStyles || !!cards && !!cards.ai_style && !cards.ai_style.open" *ngIf="!!view.aiStyleTypes && !!styles">
    
    <ion-list lines="none" *ngFor="let type of view.aiStyleTypes; let iType = index;">

      <!-- type header -->
      <ion-item [disabled]="!!disabled" [hidden]="!view.expertMode && !!iType">

        <ion-icon *ngIf="!!typeIcons[type]" [name]="typeIcons[type]" slot="start"></ion-icon>

        <ion-select interface="popover" [disabled]="!!disabled"
          [label]="(!!view.expertMode ? ('ai_styles_type_' + type) : 'ai_style')|translate"
          [placeholder]="'choose_ai_style'|translate" labelPlacement="floating" (ionChange)="styleChanged(type)"
          [(ngModel)]="view.style[type]" [okText]="'okay' | translate" [cancelText]="'cancel' | translate">
          <ion-select-option *ngFor="let style of styles[type]" [innerHTML]="style.name | translate"
            [value]="style.uid"></ion-select-option>
        </ion-select>

        <ion-button [disabled]="!!disabled" slot="end" fill="clear" color="dark" icon-only
          (click)="configureStyle(type)"
          *ngIf="!!view.style[type] && (view.style[type] !== 'auto') && (view.style[type] !== 'custom')">
          <ion-icon name="cog-outline"></ion-icon>
        </ion-button>

      </ion-item>

      <ion-list [hidden]="!view.expertMode && !!iType"
        *ngIf="!!view.style && !!view.style[type] && (view.style[type] === 'custom')">

        <!-- custom style input -->
        <ion-item lines="none" [disabled]="!!disabled">
          <ion-icon name="build-outline" slot="start"></ion-icon>
          <ion-textarea [disabled]="!!disabled" type="text" rows="4" [(ngModel)]="view.style[type].input"
            [placeholder]="'ai_style_custom_placeholder'|translate"></ion-textarea>
        </ion-item>

      </ion-list>

    </ion-list>

  </div>

</ion-card>
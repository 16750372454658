import { NgModule } from '@angular/core';

// pipes
import { TranslateAndFillVarsPipe } from './pipes/translateAndFillVars';

@NgModule({
    declarations: [
        TranslateAndFillVarsPipe
    ],
    exports: [
        TranslateAndFillVarsPipe
    ],
})
export class SharedModule { }
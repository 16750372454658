import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

import { ModalService } from 'src/app/services/core/modal.service';
import { ClipboardService } from 'src/app/services/utils/clipboard.service';

@Component({
  selector: 'app-sharing-modal',
  templateUrl: './sharing-modal.page.html',
  styleUrls: ['./sharing-modal.page.scss'],
})
export class SharingModalPage implements OnInit {

  shareOptions: any = {};

  state: state = {};
  
  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    title: 'share',
  };

  constructor(
    private clipboard: ClipboardService,
    private modalService: ModalService,
    private navParams: NavParams,
  ) {
    this.shareOptions = this.navParams.get('shareOptions');
  }

  copyToClipboard() {
    let text = `${this.shareOptions.title || ''}\n${this.shareOptions.message || ''}\n${this.shareOptions.url || ''}`;

    this.clipboard.copyText(text)
    .catch((error) => {
      console.warn('error!');
    })
  }

  async dismiss(data: any = null, role: string|null = 'dismiss') {
    (await this.modalService).dismiss(data, role);
  }

  ngOnInit() {
  }

}

import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { SettingsService } from "src/app/services/core/settings.service";
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class TestingService {

  group: testingGroup;

  groups: testingGroup[] = [
  ];

  key: string = 'testing_group';

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private settings: SettingsService,
    private userService: UserService,
  ) {

  }

  assignToRandomGroup() {
    const randomGroup = this.getRandomGroup();

    this.setGroup(randomGroup);
  }

  getAllGroups() {
    return this.groups;
  }

  getGroupByIndent(groupIndent: string) {

    const filter = this.groups.filter((group: testingGroup) => {
      return group.indent === groupIndent;
    });

    return filter && filter[0] ? filter[0] : null;
  }

  async getCurrentGroup(blFallback: boolean = true) {
    const fromCache: cacheItem = await this.cache.get(this.key, 60 * 60);
    
    if(!this.group && fromCache && fromCache.data) {
      this.group = JSON.parse(fromCache.data);
    }

    if(!this.group && blFallback) {
      return this.getRandomGroup();
    }

    return this.group;
  }

  async getCurrentGroupConfig(blFallback: boolean = true) {
    let group = await this.getCurrentGroup();

    if(!group && blFallback) {
      group = this.getRandomGroup();
    }

    return group.config;
  }

  async getCurrentGroupIndent(blFallback: boolean = true) {
    let group = await this.getCurrentGroup();

    if(!group && blFallback) {
      group = this.getRandomGroup();
    }

    return group.indent;
  }

  getRandomGroup() {
    const groups = this.getAllGroups();
    return groups[Math.floor(Math.random() * groups.length)];
  }

  init() {
    /*
    const user: user = this.userService.getUser();

    if(user) {
      this.onUserUpdated(user);
    } else
    if(!this.group) {
      this.assignToRandomGroup();
    }

    this.events.subscribe('appcms:user:updated', (user: user) => {
      this.onUserUpdated(user);
    });
    */
  }

  isBetaTester() {
    return !!this.userService.getClassification('isBetaTester');
  }

  async onUserUpdated(user: user) {
    try {
      if(user && user.uid) {
        const serverSideGroup: string = (await this.settings.getSetting(this.key) as string);
  
        if(serverSideGroup) {
          const groupByIndent = this.getGroupByIndent(serverSideGroup);
    
          if(groupByIndent) {
            this.group = groupByIndent;
          } else {
            this.assignToRandomGroup();
          }
    
        } else {
          this.assignToRandomGroup();
        }
      }
    } catch(e) {
      console.warn('onUserUpdated failed', e);
    }
  }

  setGroup(group: testingGroup) {
    this.group = group;
    this.cache.set(this.key, this.group);
    this.events.publish('testing:group:updated', this.getCurrentGroup());

    return this.syncUserTestingGroup();
  }

  async syncUserTestingGroup() {
    const user = this.userService.getUser();

    if(user && user.uid) {
      let settings = await this.settings.getSettings();
      settings[this.key] = this.group.indent;

      return this.settings.updateSettings(settings);
    }

  }

}
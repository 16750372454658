import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

import { TranslateService } from '@ngx-translate/core';

import { BrowserService } from 'src/app/services/utils/browser.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

@Injectable({
  providedIn: 'root'
})
export class WebService {

  appDownloadUrl: string = "https://get.pipeline.page/";

  constructor(
    private alertCtrl: AlertController,
    private browser: BrowserService,
    private tools: ToolsService,
    private translate: TranslateService,
  ) {
    
  }

  appFeaturesRequested() {
    this.translate
      .get([
        "app_required_header",
        "app_required_subHeader",
        "app_required_message",
        "okay",
        "cancel",
      ])
      .subscribe(async (response: any) => {
        const alert: any = await this.alertCtrl.create({
          header: response.app_required_header,
          subHeader: response.app_required_subHeader,
          message: response.app_required_message,
          buttons: [
            {
              text: response.okay || "Okay",
              handler: () => {
                if(this.tools.getOS() === 'ios') {
                  this.browser.create(`${this.appDownloadUrl}/ios`);
                } else
                if(this.tools.getOS() === 'ios') {
                  this.browser.create(`${this.appDownloadUrl}/android`);
                } else {
                  this.browser.create(this.appDownloadUrl);
                }
              },
            },
            {
              text: response.cancel || "Cancel",
              role: "cancel",
            },
          ],
        });
        await alert.present();
      });
  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  params: any = {};

  constructor() {
  }

  get(key: string) {
    return this.params[key];
  }

  set(key: string, value: any) {
    this.params[key] = value;
  }

}

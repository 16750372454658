<ion-header class="ion-no-border">

  <ion-toolbar class="ion-no-border">

    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons slot="end">

      <ion-button (click)="update()" shape="round" [color]="view.isDesktop ? 'primary' : 'dark'" [fill]="view.isDesktop ? 'solid' : 'clear'">
        <ion-icon name="checkmark-outline" slot="start"></ion-icon>
        <ion-label [hidden]="!view.isDesktop" [innerHTML]="'update'|translate"></ion-label>
      </ion-button>

    </ion-buttons>
  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>
  
  <ion-toolbar>
    <ion-segment scrollable *ngIf="view.genders" [(ngModel)]="view.gender">
      <ion-segment-button *ngFor="let gender of view.genders" [value]="gender">
        <ion-label [innerHTML]="('gender_' + gender)|translate"></ion-label>
      </ion-segment-button>
    </ion-segment>
  </ion-toolbar>

</ion-header>

<ion-content>
  <ion-grid *ngIf="view.avatars">
    <ion-row>
      <ion-col size="6" *ngFor="let avatar of view.avatars" [hidden]="view.gender !== avatar.gender" (click)="use(avatar)" [class.active]="view.avatar && (view.avatar.src == avatar.src)">
        <img [src]="avatar.src" />
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

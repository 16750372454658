import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController, NavParams } from '@ionic/angular';
import { ModalService } from 'src/app/services/core/modal.service';
import { PostsService } from 'src/app/services/posts/posts.service';

import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { BrowserService } from 'src/app/services/utils/browser.service';
import { EventsService } from "src/app/services/core/events.service";
import { LogfileService } from "src/app/services/core/logfile.service";
import { ProfileService } from 'src/app/services/social/profile.service';
import { ShopService } from 'src/app/services/ecommerce/shop.service';
import { UserService } from "src/app/services/core/user.service";
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DetailPage implements OnInit {

  basketStorage: any;

  product: product = {};
  _product: product;

  productId: number;

  showVariationSelect: boolean = false;

  @ViewChild('productImagesSlider') slider: ElementRef | undefined;

  sliderOptions: any = {
    loop: true,
    zoom: false,
    autoHeight: true,
    slidesPerView: (window.innerWidth >= 1024) ? 3 : 1,
  };

  uiMetaData: any = {
    defaultSelected: {
    },
    isMultiple: {
    }
  };

  user: user;
  
  viewData: any = {
    external: true,
  };

  constructor(
    private basket: BasketService,
    private browser: BrowserService,
    private changeDetector: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private events: EventsService,
    private navCtrl: NavController,
    private log: LogfileService,
    private modalService: ModalService,
    private navParams: NavParams,
    private posts: PostsService,
    private profile: ProfileService,
    private shop: ShopService,
    private tools: ToolsService,
    private userService: UserService,
    private zone: NgZone,
  ) {

    this.basket.getBasket()
    .then((basket: basketStorage) => {
      this.basketStorage = basket;
    });

    this.product = this.navParams.get('product') || this.product;

    this.user = this.userService.getUser() || {};

    if(this.product && this.product.uid) {
      this.loadProduct();
    }

  }

  add() {
    if(this.showVariationSelect) {
      return false;
    }
    this.addToBasket(false);
    
    this.basket.getBasket()
    .then((basket: basketStorage) => {
      this.basketStorage = basket;
      this.detectChanges();
    });
  }

  addToCollection() {
    this.product.added = true;
    this.detectChanges();
  }

  calcShowVariationSelection() {
    this.showVariationSelect = this.product && this.product.type === 'variable' && !this._product;
      this.detectChanges();
  }

  calcViewVars() {
    this.viewData.isDesktop = this.tools.isDesktop();
    this.viewData.isModal = this.modalService.isModal();
    this.viewData.isWeb = this.tools.isWeb();
  }

  async dismiss(data: any = null, role: string|null = 'dismiss', event: any = null) {

    if(!!event && !!event.target) {
      event.target.complete();
      this.detectChanges();
    }
    
    this.modalService.dismiss(data, role);
  }

  _basket() {
    this.dismiss(null, 'done');
    this.events.publish('view:basket');
  }

  addToBasket(redirect: boolean = true) {

    if(this.viewData.external) {
      return this.view();
    }

    let product = this._product || this.product;
    product.amount = this._product ? 0 : (product.amount || 0);
    product.amount++;
    
    this.detectChanges();
    
    this.basket.add(product)
    .then(() => {
      if(redirect) {
        this.dismiss(null, 'done');
        this.events.publish('view:basket');
      } else {
        this.product.amount = this.product.amount || 1;
        this.detectChanges();
      }
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });

  }

  checkInCollection() {
    this.profile.inCollection(this.product)
    .then((blInCollection: boolean) => {
      this.product.added = blInCollection;
      this.detectChanges();
    })
    .catch((error: any) => {
      if(error === 'Error_cannot_find_post') {
        this.viewData.productNotExists = true;
        this.detectChanges();
      } else {
        this.events.publish('error', error);
      }
    });
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetector.detectChanges();
    });
  }
  
  ionViewWillEnter() {
    this.user = this.userService.getUser() || {};
    this.detectChanges();

    this.basket.getBasket()
    .then((basket: basketStorage) => {
      this.basketStorage = basket;
      this.detectChanges();
    });

    this.checkInCollection();

    if(this.slider) {
      this.slider.nativeElement.swiper.update();
      this.detectChanges();
    }
  }

  loadProduct() {
    this.shop.getProductByUid(this.product.uid)
    .then((product: any) => {
      this.product = Object.assign(product, (this.product || {}));
      this.onProductLoaded();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  loadRelatedPostToView(postId: number) {
    this.posts.getPostByUid(postId)
    .then((post: post) => {
      this.product.pipeline.postData = post;
      this.product.pipeline.postData.post_content_formatted = this.domSanitizer.bypassSecurityTrustHtml(post.post_content);
    })
    .catch((error: any) => {
      console.warn('cannot load related post', error);
    });
  }

  logProductView() {
    this.log.add({
      class: 'profile_view',
      group: 'profile_view_' + this.product.uid,
      description: (this.user.nickname || this.user.firstname) + ' hat sich das Produkt "' + this.product.name + '" angesehen',
      inAppLink: this.product.uid,
    })
    .catch((error: any) => {
      console.warn('log error:', error);
    });
  }

  minus() {

    if(this.showVariationSelect) {
      return false;
    }

    let product = this._product || this.product;
    this.basket.removeFromBasket(product)
    .then(async () => {
      this.basketStorage = await this.basket.getBasket();
      this.product.amount = this.product.amount > 0 ? this.product.amount-- : 0;
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
    
    this.onProductLoaded();
  }

  onLinkClick(event: any) {
    let url = event.target.getAttribute('data-url');
    if(url) {
      if(url[0] === '/' && this.product.pipeline.postData.host) {
        url = this.product.pipeline.postData.host + url;
      }
      if(url.indexOf('http') === -1) {
        url = 'https://' + url;
      }
      this.browser.create(url);
    }
    event.preventDefault();
    return false;
  }

  onPageClick(event: any) {
    if(event && event.target && event.target.nodeName && event.target.nodeName === 'A') {
      return this.onLinkClick(event);
    }
  }

  onProductLoaded() {

    if(this.product.image && !this.product.images) {
      this.product.images = [{
        src: this.product.image,
      }];
    }

    if(this.product.description && this.product.description.length) {
      this.product.description_formatted = this.domSanitizer.bypassSecurityTrustHtml(this.product.description);
    }

    if(!this.product.metaData) {
      let defaultMetaData = {};
      if(this.product.attributes && this.product.attributes.length) {
        this.product.attributes.forEach((attribute: any) => {
          defaultMetaData[attribute.name] = '';
        });
      }
      this.product.metaData = defaultMetaData;
    }

    if(this.product.pipeline) {
      if(this.product.pipeline.post) {
        this.loadRelatedPostToView(this.product.pipeline.post);
      }
    }

    this.calcShowVariationSelection();
    this.detectChanges();
  }

  removeFromCollection() {
    this.profile.removeFromCollection(this.product, 'products')
    .then((response: any) => {
      this.product.added = !response.success;
      this.detectChanges();
    })
    .catch((error: any) => {
      this.events.publish('error', error);
    });
  }

  
  
  toggleCollection() {
    if(this.product.added) {
      this.removeFromCollection();
    } else {
      this.addToCollection();
    }
  }

  updateProduct() {
    switch(this.product.type) {
      case 'variable':
        this.shop.getVariationByProduct(this.product)
        .then((product: product) => {
          if(product) {
            let imageIndex: number;
            this._product = product;
            this.product.price_html = product.price_html || (product.price + '€');

            if(product.image && product.image.src && product.image.src.length && this.product.images && this.product.images.length) {
              this.product.images.forEach((image: any, _imageIndex: number) => {
                if(image.src === product.image.src) {
                  imageIndex = _imageIndex;
                  this._product.image = image;
                }
              });
            }

            if(imageIndex !== null) {
              this.slider.nativeElement.swiper.slideTo(imageIndex+1);
            }

            let index = this.basket.getEditItemIndex();

            if(index) {
              this.basket.updateIndex(index, this.basket.toBasketStorageItem(product));
            }
            
            this.onProductLoaded();
          }
        })
        .catch((error: any) => {
          this.events.publish('error', error);
        });
        break;
    }
  }

  view() {
    let url = (this.product.permalink || (this.product as any).url);
    if(url) {
      this.browser.create(url);
    }
  }

  viewCategory(category: any) {
    this.navCtrl.navigateForward('/category/' + category.id);
  }

}
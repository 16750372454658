import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

import { SplineViewerComponent } from './spline-viewer.component';

@NgModule({
  declarations: [
    SplineViewerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
  ],
  exports: [
    SplineViewerComponent,
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ]
})
export class SplineViewerComponentModule { }
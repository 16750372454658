import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

import { AiToolsService } from "src/app/services/ai/ai-tools.service";

import { DaniService } from 'src/app/services/getgenius/dani.service';
import { EventsService } from 'src/app/services/core/events.service';
import { LanguageService } from "src/app/services/core/language.service";
import { MediaextendService } from "src/app/services/media/mediaextend.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';

import { SpeechRecognition } from "@capacitor-community/speech-recognition";

import { SplineViewerComponent } from 'src/app/components/generic/spline/spline-viewer/spline-viewer.component';

@Component({
  selector: 'getgenius-dani',
  templateUrl: './getgenius-dani.component.html',
  styleUrls: ['./getgenius-dani.component.scss'],
})
export class GetgeniusDaniComponent implements AfterContentInit, OnDestroy, OnInit {
  @Input() config: any = {};
  @Input() state: any;

  @Output() chatChanged = new EventEmitter();
  @Output() voicesChanged = new EventEmitter();

  @ViewChild('historyWrapper', { read: ElementRef }) private historyWrapper: ElementRef;
  @ViewChild('daniChatSettingsPopover') daniChatSettingsPopover: any;
  @ViewChild(SplineViewerComponent) splineViewer: any;

  addActions: any[] = [
    {
      icon: 'musical-notes-outline',
      label: 'audio',
      uid: 'audio',
    },
    {
      icon: 'document-text-outline',
      label: 'document',
      uid: 'document',
    },
    {
      icon: 'image-outline',
      label: 'image',
      uid: 'image',
    },
    {
      disabled: true,
      icon: 'film-outline',
      label: 'movie',
      uid: 'movie',
    },
  ];

  animationClass: string = 'full';

  blBlockRunAiPrompt: boolean = false;

  blInitialized: boolean = false;

  blListening: boolean = false;

  blLoading: boolean = false;

  blSpeechRecognitionAvailable: boolean = false;

  chat: any[] = [];

  iExecutionTry: number = 0;

  inputPrompt: string = '';

  isSettingsPopoverOpen: boolean = false;

  splineOptions: splineOptions = {
    zoom: (window.outerWidth > 768 ? 0.6 : 0.3),
  };

  urlsByState: any = {};

  user: user;

  view: any = {
    introCard: {
      uid: 'getgenius_dani_chat_top_card',
      text: 'getgenius_dani_chat_top_card_text',
      title: 'getgenius_dani_chat_top_card_title',
    },
  };

  voices: any[] = [];

  webRecognition: any;

  webSpeechSynthesis: any;

  constructor(
    private aiTools: AiToolsService,

    private changeDetectorRef: ChangeDetectorRef,
    private dani: DaniService,
    private events: EventsService,
    private language: LanguageService,
    private mediaService: MediaextendService,
    private navCtrl: NavController,
    private tools: ToolsService,
    private userService: UserService,
    private viewService: ViewService,
    private zone: NgZone,
  ) {
    this.urlsByState = this.dani.getUrlsByState();
    this.user = this.userService.getUser();
  }

  addChatMessage(message: any) {
    this.setChat([...this.chat, message || {}]);
  }

  addFile(event: any = null) {
    this.daniChatSettingsPopover.event = event;
    this.isSettingsPopoverOpen = true;
  }

  public applyLoadingOptions() {
    if (!!this.config && !!this.config.zoom) {
      this.setZoom(this.config.zoom);
    }
  }

  audio() {
    return this.upload();
  }

  calcViewVars() {
    this.view = this.viewService.calcVars();
    this.chat = this.chat || [];

    this.state = this.state || {};
    this.state.hasMessages = !!(!!this.chat && !!this.chat.length);
    this.state.inConversation = !!(!!this.chat && !!this.chat.length && (this.chat.length > 1));

    this.splineOptions = this.splineOptions || {};
    this.splineOptions.zoom = (!!this.view.isDesktop ? 0.65 : 0.35);

    try {
      this.setSize(window.outerWidth, window.outerHeight);
    } catch (e) {
      console.warn('setting dani size failed', e);
    }
  }

  public async checkPermissions() {
    return new Promise((resolve, reject) => {
      if (this.tools.isWeb()) {
        try {

          const SpeechRecognition: any = this.getWebSpeechRecognition();
          //const SpeechGrammarList = (window as any).SpeechGrammarList || (window as any).webkitSpeechGrammarList;
          //const SpeechRecognitionEvent = (window as any).SpeechRecognitionEvent || (window as any).webkitSpeechRecognitionEvent;

          resolve(!!SpeechRecognition);
        } catch (e) {
          reject(e);
        }
      } else {
        SpeechRecognition.checkPermissions()
          .then((response: any) => {
            resolve(!!response && !!response.speechRecognition && (response.speechRecognition === 'granted'));
          })
          .catch(reject);
      }
    })
  }

  createTicket() {

    const inputs: any[] = [
      {
        icon: 'eye-outline',
        name: 'subject',
        placeholder: 'subject',
        type: 'text',
        uid: 'subject',
      },
      {
        icon: 'text-outline',
        name: 'input',
        placeholder: 'message',
        type: 'textarea',
        uid: 'input',
      },
      {
        icon: 'person-outline',
        name: 'name',
        placeholder: 'name',
        type: 'text',
        uid: 'name',
      },
      {
        icon: 'mail-outline',
        name: 'email',
        placeholder: 'your_email',
        type: 'email',
        uid: 'email',
      },
      {
        icon: 'call-outline',
        name: 'phone',
        placeholder: 'phone',
        type: 'tel',
        uid: 'phone',
      },
    ];

    inputs.forEach((input: any, index: number) => {

      setTimeout(() => {

        this.addChatMessage({
          icon: input.icon,
          mode: 'input',
          input: `${input.name}`,
          placeholder: `${input.placeholder}`,
          role: 'system',
          type: input.type || 'text',
          uid: `${input.uid}`,
        });

        setTimeout(() => {
          this.scrollDown();
        }, 100);

      }, (index * 200));

    });

    this.inputPrompt = '';
  }

  detectChanges() {
    this.zone.run(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  document() {
    return this.upload();
  }

  async getLangCode() {
    const lang: string = (await this.language.getDisplayLanguage() || this.userService.getLanguage());
    //console.log('lang', lang);

    let langCode: string = `${lang}_${lang.toUpperCase()}`;
    //console.log('langCode (a)', langCode);

    if (langCode === 'en_EN') {
      langCode = 'en_US';
    }

    return langCode;
  }

  getWebSpeechRecognition() {
    return (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
  }

  handleUploadResponse(response: any) {
    console.log('handleUploadResponse', response);
  }

  image() {
    return this.upload();
  }

  initChat() {
    this.initEvents();

    if (!!this.config.hasChat) {
      this.zone.run(() => {
        let chat: any = (this.chat || []);

        if (!!this.config && !!this.config.chat) {
          chat = this.config.chat;
        }

        this.setChat(chat);

        setTimeout(() => {

          if (!!this.config.userCanWrite && !this.chat.length) {
            this.addChatMessage({
              mode: 'view',
              input: `getgenius_dani_chat_intro_message`,
              role: 'assistant',
            });
          }

          this.calcViewVars();
        }, 250);
      });
    }
  }

  initEvents() {

    this.events.subscribe('getgenius:dani:chat:add', (message: inboxChatMessage) => {
      this.zone.run(() => {

        this.addChatMessage({
          input: message.description,
        });

        this.animationClass = 'profile';
      });
    });

    this.events.subscribe('getgenius:dani:spline:set', (url: string) => {
      this.setSplineUrl(url);
    });

  }

  async initSpeechRecognition() {
    try {
      if (this.tools.isWeb()) {
        try {
          const SpeechRecognition: any = this.getWebSpeechRecognition();
          const langCode: string = await this.getLangCode();
          //console.log('langCode (a)', langCode);

          this.webRecognition = new SpeechRecognition();
          //const speechRecognitionList = new SpeechGrammarList();
          //speechRecognitionList.addFromString(grammar, 1);
          //recognition.grammars = speechRecognitionList;
          this.webRecognition.continuous = false;
          this.webRecognition.lang = langCode;
          this.webRecognition.interimResults = false;
          this.webRecognition.maxAlternatives = 1;

          this.blSpeechRecognitionAvailable = !!SpeechRecognition;

          if (!this.webSpeechSynthesis) {
            this.webSpeechSynthesis = window.speechSynthesis;
          }

          // in Google Chrome the voices are not ready on page load
          if ("onvoiceschanged" in this.webSpeechSynthesis) {
            this.webSpeechSynthesis.onvoiceschanged = () => {
              this.loadVoices();
            };
          } else {
            this.loadVoices();
          }

          this.view.webSpeechSynthesisAvailable = !!this.webSpeechSynthesis;

        } catch (e) {
          console.warn('speech web init failed', e);
          this.blSpeechRecognitionAvailable = false;
        }
      } else {
        SpeechRecognition.available()
          .then((response: any) => {
            this.blSpeechRecognitionAvailable = !!response && !!response.available;
          })
          .catch((e: any) => {
            console.warn('init speech recognition failed (2)', e);
          });
      }
    } catch (e) {
      console.warn('init speech recognition failed (1)', e);
    }
  }

  public listen() {

  }

  async loadVoices() {

    const allVoices: any[] = this.webSpeechSynthesis.getVoices(),
      langCode: string = (await this.getLangCode()).replace('_', '-'),
      allowedVoiceNames: string[] = ['Eddy', 'Flo', 'Reed', 'Martin', 'Rocko'];

    const voicesByLanguage = allVoices.filter((voice: any) => {
      return voice.lang === langCode;
    });

    const preferedVoices: any[] = voicesByLanguage.filter((voice: any) => {
      let bl: boolean = false;
      allowedVoiceNames.forEach((voicePart: string) => {
        bl = !!bl || (voice.name.indexOf(voicePart) !== -1);
      });
      return bl;
    });

    this.voices = (!!preferedVoices && !!preferedVoices.length ? preferedVoices : (!!voicesByLanguage && !!voicesByLanguage.length ? voicesByLanguage : allVoices));
    this.voicesChanged.emit(this.voices);
  }

  movie() {
    return this.upload();
  }

  ngAfterContentInit() {
    this.calcViewVars();

    this.initSpeechRecognition();
    this.initChat();

    this.applyLoadingOptions();

    this.blInitialized = true;
  }

  ngOnChanges() {
    this.applyLoadingOptions();
  }

  ngOnDestroy() {
    if (!!this.view && !!this.view.events) {
      this.events.stop(this.view.events);
    }
  }

  ngOnInit() {
    this.calcViewVars();
    this.applyLoadingOptions();
  }

  @HostListener('window:resize')
  onResize() {
    this.calcViewVars();
  }

  public play() {
    return this.splineViewer.play();
  }

  public async record() {

    if (!!this.tools.isWeb()) {

      try {
        this.webSpeechSynthesis.cancel();
      } catch (e) {
      }

      // start listening
      this.webRecognition.start();
      this.blListening = true;

      // on error
      this.webRecognition.onerror = (event) => {
        console.error('speech recognition error', event);
      };

      // handle no match
      this.webRecognition.onnomatch = (event) => {
        console.log('no match', event);
      };

      // on input update, set response to chat message textarea
      this.webRecognition.onresult = (event) => {
        if (!!event && !!event.results && event.results[0] && event.results[0][0] && event.results[0][0].transcript) {
          this.inputPrompt = event.results[0][0].transcript;
        }
      };

      // on speech end, stop listening and send input
      this.webRecognition.onspeechend = () => {
        this.webRecognition.stop();
        this.blListening = false;

        setTimeout(() => {
          if (!!this.inputPrompt) {
            this.runAiPrompt();
          }
        }, 100);
      };

      return false;
    }

    let check: any = false;

    try {
      check = await this.checkPermissions();
    } catch (e) {
      check = false;
    }

    if (!check) {

      try {
        SpeechRecognition.requestPermissions();
      } catch (e) {
        this.events.publish('error', e);
      }

      return false;
    }

    try {

      const langCode: string = await this.getLangCode();
      //console.log('speech langCode', langCode);

      SpeechRecognition.start({
        language: langCode,
        maxResults: 2,
        prompt: "Say something",
        partialResults: true,
        popup: true,
      });

      SpeechRecognition.removeAllListeners();

      SpeechRecognition.addListener("partialResults", (data: any) => {
        console.log("partialResults was fired", data.matches);
        this.inputPrompt = (!!data.matches && !!data.matches[0] ? data.matches[0] : '');
      });

      this.blListening = true;

    } catch (e) {
      this.events.publish('error', e);
      this.blListening = false;
    }
  }

  public resend(message: any) {
    this.inputPrompt = `${message.input || ''}`;
    this.runAiPrompt();
  }

  public runAiPrompt(options: any = {}) {

    if (!!this.blBlockRunAiPrompt) {
      return false;
    }

    this.zone.run(() => {
      this.blBlockRunAiPrompt = true;

      setTimeout(() => {
        this.blBlockRunAiPrompt = false;
      }, 500);

      this.chat = this.chat || [];
      this.iExecutionTry = (!!options && !!options.iTry ? options.iTry : 0);
      this.blLoading = true;

      this.setSplineUrl(this.urlsByState.WORKING);

      setTimeout(() => {
        this.scrollDown();

        /*
        if (this.tools.isDesktop()) {
          this.setZoom(0.5);
        } else {
          this.setZoom(0.5);
        }
        */
      }, 100);

      let history: any[] = [];

      // calculate history
      if (!!this.chat && !!this.chat.length) {
        history = this.chat.map((historyItem: any) => {
          return {
            input: `${historyItem.post_content || historyItem.input}`,
            role: (historyItem.role || 'user'),
            mode: historyItem.mode || 'view',
          };
        });
      }

      const message: any = {
        input: `${this.inputPrompt}`,
        role: 'user',
        mode: 'view',
      };

      // add input to chat
      if (!!this.inputPrompt) {

        this.addChatMessage(message);

        console.log('before send: chat is now', this.chat);
      }

      this.calcViewVars();

      const config: any = this.config || {};

      this.dani.sendChat(
        {
          history: history,
          post_content: `${this.inputPrompt || ''}`,
        },
        false,
        config,
        {
          language: config.language,
          useFunctions: true,
        }
      )
        .then((response: any) => {
          console.log('send response', response);

          this.blLoading = false;

          const zoom: number = (window.outerWidth > 768 ? 0.6 : 0.3);

          this.setZoom(zoom);
          this.setSplineUrl(this.urlsByState.IDLE);

          const checkFunctionCall = this.aiTools.hasFunctionCallResponse(response);
          const blHasFunctionCall: boolean = !!(!!checkFunctionCall && !!checkFunctionCall.match);

          if (blHasFunctionCall && !!checkFunctionCall.details) {

            switch (checkFunctionCall.details.name) {
              case 'create_ticket':
                this.createTicket();
                break;
              default:
                this.aiTools.executeFunctionCall(response)
                  .then((functionCallResponse: aiFunctionCallResponse) => {
                    this.inputPrompt = '';
                  })
                  .catch((error: any) => {
                    console.warn('executing function call failed', error);
                  });
                break;
            }

          } else
            if (!!response && !!response.output) {
              const split: string[] = this.tools.splitTripleBackticksWithCodeBlocks(`${response.output}`);

              this.setChat([...history, message]);
              console.log('set chat to: ', this.chat);

              split.forEach((part: string, splitIndex: number) => {
                setTimeout(() => {
                  if (!!part) {
                    this.zone.run(() => {

                      this.addChatMessage({
                        mode: 'view',
                        input: `${this.tools.nl2br(part)}`,
                        role: 'assistant',
                      });

                      console.log('after send: updated this.chat', this.chat);

                      setTimeout(async () => {
                        this.speakText(part);

                        setTimeout(async () => {
                          this.scrollDown();
                        }, 100);
                      });
                    });
                  }
                }, (splitIndex) * 300);
              });

              this.inputPrompt = '';
              this.calcViewVars();
            }

          setTimeout(() => {
            this.scrollDown();
          }, 200);

        })
        .catch((error: any) => {
          console.warn('send response error', error);

          this.blLoading = false;
          this.setSplineUrl(this.urlsByState.IDLE);

          if (!!error && (error === 'Error_pipeline_ai_resource_busy') && (3 > this.iExecutionTry)) {
            setTimeout(() => {
              this.runAiPrompt({
                iTry: ((this.iExecutionTry || 0) + 1),
              });
            }, 500);
          } else {
            this.events.publish('error', error);
          }
        });
    });
  }

  async runItemSelectionOption(item: any, option: any) {
    try {

      if (!option || !option.uid) {
        return false;
      }

      const exec: any = await this[option.uid](item);

      this.isSettingsPopoverOpen = false;

    } catch (e) {
      console.warn('executing single selection on item failed', e);
      this.events.publish('error', e);
    }
  }

  public scrollDown() {
    try {
      this.historyWrapper.nativeElement.scrollTop = this.historyWrapper.nativeElement.scrollHeight;
    } catch (e) {
      console.warn('scroll down failed', e);
    }
  }

  public send(blSubmit: boolean = false, event: any = null) {

    try {
      if (!!event) {
        event.preventDefault();
      }
    } catch (e) {
      console.warn('e', e);
    }

    try {
      if (!!this.blListening) {
        this.stopListening();
      }
    } catch (e) {
      console.warn('stopping to listen failed', e);
    }

    this.runAiPrompt();
  }

  public setChat(chat: any[]) {
    this.zone.run(() => {
      this.chat = (chat || []);

      this.detectChanges();

      this.chatChanged.emit(this.chat);

      setTimeout(() => {
        this.calcViewVars();
        this.scrollDown();
      });
    });
  }

  public async setSplineUrl(url: string) {
    try {

      this.splineOptions = {
        ...this.splineOptions,
        path: url,
      };

      if (!this.splineViewer) {
        return false;
      }

      await this.splineViewer.updateAnimation(this.splineOptions);

    } catch (e) {
      console.warn('setting url failed', e);
    }
  }

  public setSize(width: number, height: number) {
    return this.splineViewer.setSize(width, height);
  }

  public setZoom(zoom: number) {

    this.splineOptions = {
      ...this.splineOptions,
      zoom: zoom,
    };

    if (!this.splineViewer) {
      return false;
    }

    return this.splineViewer.setZoom(zoom);
  }

  speakText(text: string) {

    if (!text || (!!this.config && !this.config.allowSpeak)) {
      return false;
    }

    if (!!this.webSpeechSynthesis) {
      //this.webSpeechSynthesis.speak(text);

      const utterThis = new SpeechSynthesisUtterance(text);

      if (!!this.voices && !!this.voices[0]) {
        utterThis.voice = this.voices[0];
      }

      this.webSpeechSynthesis.speak(utterThis);
    }
  }

  public stop() {

    if (!this.splineViewer) {
      return false;
    }

    return this.splineViewer.stop();
  }

  public stopListening() {
    this.blListening = false;

    try {
      SpeechRecognition.stop();
    } catch (e) {
      console.warn('stopping failed', e);
    }

    this.runAiPrompt();
  }

  toChatPage() {
    this.navCtrl.navigateForward('/dani/chat');
  }

  toggleSpeak() {
    this.view.shouldSpeak = !this.view.shouldSpeak;
  }

  trackItems(index: number, itemObject: any) {
    return itemObject.uid;
  }

  upload(params: any = {}) {
    this.mediaService.applyFromWeb(null, params)
      .then((response: any) => {
        this.handleUploadResponse(response);
      })
      .catch((error: any) => {
        if (!!error) {
          this.events.publish('error', error);
        }
      });
  }

}
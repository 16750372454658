import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';

import { PaginationService } from 'src/app/services/utils/pagination.service';
import { ToolsService } from "src/app/services/utils/tools.service";

@Component({
  selector: 'pipeline-header-search-toolbar',
  templateUrl: './header-search-toolbar.component.html',
  styleUrls: ['./header-search-toolbar.component.scss'],
})
export class HeaderSearchToolbarComponent implements OnInit {
  @Input() items: any[];
  @Input() placeholder: string;
  @Input() search: searchOptions;
  @Input() view: any;

  @Output() onChange = new EventEmitter();

  constructor(
    private pagination: PaginationService,
    private tools: ToolsService,
    private zone: NgZone,
  ) {

  }

  getItemsKeys() {
    return (this.search.itemsKeys || [this.search.itemsKey || 'items']);
  }

  ngOnInit() {

  }

  public async runSearch(event: any = null, options: any = {}) {
    this.zone.run(() => {
      let allMatches: any[] = [], updateView: any = {};

      const itemsKeys: string[] = this.getItemsKeys();
      const searchKeys: string[] = (this.search.keys || ['name', 'title', 'uid', 'url']);
      const query: string = this.tools.trim(`${this.search.query}`.toLowerCase()); // !!event && !!event.detail && !!event.detail.value ? `${event.detail.value}`.toLowerCase() : '');
      
      let data: any = (this.view || {});

      if (!!options) {
        data = Object.assign(data, options);
      }

      itemsKeys.forEach(async (key: string) => {
        if (data.hasOwnProperty(key)) {
          const backupKey: string = `${key}_backup`;
          let items: any[] = [], allItems: any[] = [];

          if (!data.hasOwnProperty(backupKey) && data[key] && data[key].length) {
            data[backupKey] = data[key].map((item: any) => ({ ...item, view: undefined }));
          }

          if (data[backupKey] && data[backupKey].length) {
            allItems = JSON.parse(JSON.stringify(data[backupKey]));
          }

          if (!!allItems) {
            allItems.forEach((item: any) => {
              let blShow: boolean = !query || !query.length;

              if (!!query) {
                searchKeys.forEach((searchKey: string) => {
                  if (item.hasOwnProperty(searchKey)) {
                    blShow = blShow || `${item[searchKey] ? item[searchKey] : ''}`.toLowerCase().includes(query);
                  }
                });
              }

              // if item has attributes
              /*
              if(!!item.attributes) {

              }
              */

              // if item has tags
              /*
              if(!!item.tags) {

              }
              */
             
              if (blShow) {
                items.push(item);
                allMatches.push(item);
              }
            });
          }

          updateView[key] = (items || []);

          if (!!data.paginationConfig) {
            // run + apply pagination if config is provided

            const calc: any = await this.pagination.calculateConfig(
              Object.assign(data, updateView),
              Object.assign(data.paginationConfig, {
                backup: updateView[key],
              }),
            );

            if (!!calc && !!calc.view) {
              data = Object.assign(data, calc.view);
            }

          }
        }
      });

      if (!data.paginationConfig) {
        // if no pagination defined, update the view manually
        data = Object.assign(data, updateView);
      }

      this.onChange.emit({
        items: allMatches,
        search: this.search,
        view: data,
      });
    })
  }

  public updateItems(items: any[], key: string = 'items') {
    this.view[key] = items;
    this.view[`${key}_backup`] = items;

    return this;
  }

}
<ion-list lines="none" *ngIf="post">
  <ion-item *ngIf="post.uid" [hidden]="!userService.isType('Admin')">
    <ion-icon name="server-outline" slot="start"></ion-icon>
    <ion-label>
      <h2 [innerHTML]="'uid'|translate"></h2>
      <p [innerHTML]="post.uid"></p>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="post.date_gmt_formatted && (post.date_gmt_formatted !== 'Invalid date')">
    <ion-icon name="calendar-clear-outline" slot="start"></ion-icon>
    <ion-label>
      <h2 [innerHTML]="'date_created'|translate"></h2>
      <p [innerHTML]="post.date_gmt_formatted"></p>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="post.modified_gmt_formatted && (post.modified_gmt_formatted !== 'Invalid date')">
    <ion-icon name="calendar-clear-outline" slot="start"></ion-icon>
    <ion-label>
      <h2 [innerHTML]="'date_modified'|translate"></h2>
      <p [innerHTML]="post.modified_gmt_formatted"></p>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="post.timestamp_formatted && (post.timestamp_formatted !== 'Invalid date')">
    <ion-icon name="calendar-outline" slot="start"></ion-icon>
    <ion-label>
      <h2 [innerHTML]="'date_indexed'|translate"></h2>
      <p [innerHTML]="post.timestamp_formatted"></p>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="post.rating && post.rating.count">
    <ion-icon name="flag-outline" slot="start"></ion-icon>
    <ion-label>
      <h2 [innerHTML]="'rating'|translate"></h2>
      <p [innerHTML]="post.rating.count"></p>
    </ion-label>
  </ion-item>
  <ion-item *ngIf="post.host">
    <ion-icon name="link-outline" slot="start"></ion-icon>
    <ion-label>
      <h2 [innerHTML]="'source'|translate"></h2>
      <p [innerHTML]="post.host"></p>
    </ion-label>
  </ion-item>
</ion-list>
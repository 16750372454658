<ion-header color="primary" class="ion-no-border">
  
  <ion-toolbar color="primary" class="ion-no-border">
    
    <ion-buttons slot="start">
      <ion-back-button *ngIf="!view.isModal"></ion-back-button>

      <ion-button (click)="dismiss()" icon-only *ngIf="!!view.isModal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>

  <pipeline-header-toolbar [(state)]="state" [(view)]="view"></pipeline-header-toolbar>

</ion-header>

<ion-content color="primary">

  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content pullingIcon="arrow-down"></ion-refresher-content>
  </ion-refresher>
  
  <ion-card *ngIf="view.mode === 'default'">
    <ion-card-header>
      <ion-card-title [innerHTML]="view.headline|translate"></ion-card-title>
      <ion-card-subtitle>Du hast gerade Deinen <span [innerHTML]="view.currentPostsByUser"></span>. Beitrag veröffentlicht!</ion-card-subtitle>
    </ion-card-header>
  </ion-card>

  <ion-card *ngIf="view.mode === 'race'">
    <ion-card-header>
      <ion-card-title [innerHTML]="view.headline|translate"></ion-card-title>
      <ion-card-subtitle>
        Du holst {{view.race_partner}} ein!<br>
        Noch {{view.race_difference}} Beiträge, und Du liegst vorne!
      </ion-card-subtitle>
    </ion-card-header>
  </ion-card>

  <ion-card *ngIf="board">
    <ion-list *ngIf="board.data && board.data.labels">
      <ion-item *ngFor="let label of board.data && board.data.labels; let i = index">
        <ion-note slot="start" color="primary">
          <h1 *ngIf="i === 0">
            <b [innerHTML]="'#' + (i+1)"></b>
          </h1>
          <h4 [innerHTML]="'#' + (i+1)" *ngIf="i > 0 && i < 3"></h4>
          <b [innerHTML]="'#' + (i+1)" *ngIf="i >= 3"></b>
        </ion-note>
        <ion-label>
          <p [innerHTML]="label"></p>
        </ion-label>
        <ion-note slot="end" *ngIf="board.data && board.data.datasets && board.data.datasets[0] && board.data.datasets[0].data && board.data.datasets[0].data[i]" [innerHTML]="board.data.datasets[0].data[i]"></ion-note>
      </ion-item>
    </ion-list>
  </ion-card>

</ion-content>

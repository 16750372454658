import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { EventsService } from 'src/app/services/core/events.service';
import { TranslationService } from 'src/app/services/core/translation.service';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  toastsBlocked: boolean = false;

  constructor(
    private events: EventsService,
    private toast: ToastController,
    private translations: TranslationService,
  ) {

  }

  init() {
    this.events.subscribe("toast", async (toastConfig: toastConfig = {}) => {
      this.show(toastConfig.message, toastConfig.color || 'primary', toastConfig);
    });
  }

  show(text: string, color: string = 'primary', config: any | null = {}) {
    config = config || {};
    config.color = config.color || color;
    config.duration = config.duration || 2000;
    
    if (!this.toastsBlocked) {
      this.toastsBlocked = true;

      this.translations.get([text])
        .subscribe(async (translations: any) => {

          const toast: any = await this.toast.create(
            Object.assign(
              (config || {}),
              {
                message: translations[text] || (text || "unknown_error"),
              },
            )
          );

          setTimeout(() => {
            this.toastsBlocked = false;
          }, config.duration);

          toast.present();
        });
    }
  }

}

import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class UnsplashService {

  constructor(
    private AppCMS: AppcmsService,
  ) {

  }

  search(query: string, options: any = {}, blForceRefresh: boolean = false) {
    options = options || {};
    options.query = query;

    return this.AppCMS.loadPluginData('unsplash', options, ['search'], {}, blForceRefresh);
  }

}

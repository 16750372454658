import { Injectable } from '@angular/core';

import { AppcmsService } from 'src/app/services/core/appcms.service';

@Injectable({
  providedIn: 'root'
})
export class CouponsService {

  constructor(
    private AppCMS: AppcmsService,
  ) {
  }

  create(coupon: coupon) {
    return this.AppCMS.loadPluginData('coupons', { coupon: coupon }, ['create']);
  }

  delete(uid: number) {
    return this.AppCMS.loadPluginData('coupons', { uid: uid, }, ['delete']);
  }

  getActive(options: any = {}, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData('coupons', options, null, {}, blForceRefresh);
  }

  getAll(options: any = {}, blForceRefresh: boolean = false, params: any = {}) {
    return this.getActive(options, blForceRefresh);  
  }

  getByUid(uid: number) {
    return this.AppCMS.loadPluginData('coupons', {}, [uid]);
  }

  update(coupon: coupon) {
    return this.AppCMS.loadPluginData('coupons', { coupon: coupon }, ['update']);
  }

}

import { Injectable } from "@angular/core";

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ChooserService } from 'src/app/services/utils/chooser.service';

@Injectable({
  providedIn: "root",
})
export class PagesService {

  blIsModal: boolean;

  current: page;

  constructor(
    private AppCMS: AppcmsService,
    private chooser: ChooserService,
  ) {
  }

  create(page: page) {
    return this.AppCMS.loadPluginData(
      "pages",
      {
        page: page,
      },
      ["create"]
    );
  }

  delete(pageId: number) {
    return this.AppCMS.loadPluginData(
      "pages",
      {},
      ["delete", pageId]
    );
  }

  getAll(options: any = null, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData("pages", {}, [], {}, blForceRefresh);
  }

  getByUid(uid: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData("pages", {}, [uid], {}, blForceRefresh);
  }

  getByCategory(categoryId: number, blForceRefresh: boolean = false) {
    return this.AppCMS.loadPluginData("pages", { category: categoryId }, [], {}, blForceRefresh);
  }

  getCurrent() {
    return this.current;
  }

  getHandler(key: string) {
    return null; // this.handler[key];
  }

  isModal(bl: boolean | null = null) {
    if (bl !== null) {
      this.blIsModal = bl;
    }
    return this.blIsModal;
  }

  async pick(options: any = null) {
    options = options || {};

    const chooseConfig: chooseConfig = Object.assign(options, {
      data: await this.getAll(),
      labelKey: 'name',
      service: this,
      valueKey: 'uid',
    });

    return this.chooser.choose(chooseConfig);
  }

  setCurrent(page: page) {
    this.current = page;
  }

  update(page: page) {
    return this.AppCMS.loadPluginData(
      "pages",
      {
        page: page,
      },
      ["update"]
    );
  }

}
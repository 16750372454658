import { Injectable } from '@angular/core';

import {
    ApplePayEventsEnum, CreatePaymentSheetOption,
    GooglePayEventsEnum,
    PaymentFlowEventsEnum,
    PaymentSheetEventsEnum,
    Stripe
} from '@capacitor-community/stripe';

import { firstValueFrom } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { ToolsService } from 'src/app/services/utils/tools.service';

import { loadStripe } from '@stripe/stripe-js';

@Injectable({
    providedIn: 'root'
})
export class StripeService {

    appConfig: pipelineAppConfig;

    checkout: any;

    paymentSummaryItems: any[];

    processSheet: 'willReady' | 'Ready' = 'willReady';
    processFlow: 'willReady' | 'Ready' | 'canConfirm' = 'willReady';
    processApplePay: 'willReady' | 'Ready' = 'willReady';
    processGooglePay: 'willReady' | 'Ready' = 'willReady';

    stripeWebService: any;

    isApplePayAvailable = false;
    isGooglePayAvailable = false;

    constructor(
        private AppCMS: AppcmsService,
        private configService: ConfigService,
        private events: EventsService,
        private http: HttpClient,
        private tools: ToolsService,
    ) {
        this.appConfig = this.configService.getConfig();

        this.init();
    }

    confirmPaymentFlow() {
        return Stripe.confirmPaymentFlow();
    }

    async createApplePay() {
        const { paymentIntent } = await firstValueFrom(this.http.post<{
            paymentIntent: string;
        }>(this.AppCMS.getRequestUrl('stripe', ['intent'], {}), {}));

        await Stripe.createApplePay({
            paymentIntentClientSecret: paymentIntent,
            paymentSummaryItems: [{
                label: 'Product Name',
                amount: 1099.00
            }],
            merchantIdentifier: 'merchant.com.getcapacitor.stripe',
            requiredShippingContactFields: ['postalAddress', 'phoneNumber', 'emailAddress', 'name'],
            countryCode: 'US',
            currency: 'USD',
        });
    }

    createCheckoutSession() {

        const data: any = {
            paymentSummaryItems: this.getPaymentSummaryItems(),
        };

        return this.AppCMS.loadPluginData('stripe', {
            data: data,
        }, ['createCheckoutSession']);
    }

    async createGooglePay() {
        
        const { paymentIntent } = await firstValueFrom(this.http.post<{
            paymentIntent: string;
        }>(this.AppCMS.getRequestUrl('stripe', ['intent'], {}), {}));

        await Stripe.createGooglePay({
            paymentIntentClientSecret: paymentIntent,
            paymentSummaryItems: [{
                label: 'Product Name',
                amount: 1099.00
            }],
            merchantIdentifier: 'merchant.com.getcapacitor.stripe',
            countryCode: 'US',
            currency: 'USD',
        });
    }

    async createPaymentFlow(withCustomer = true) {
        if (withCustomer) {
            const { paymentIntent, ephemeralKey, customer } = await firstValueFrom(this.http.post<{
                paymentIntent: string;
                ephemeralKey: string;
                customer: string;
            }>(this.AppCMS.getRequestUrl('stripe', ['intent'], {}), {}));

            await Stripe.createPaymentFlow({
                paymentIntentClientSecret: paymentIntent,
                customerEphemeralKeySecret: ephemeralKey,
                customerId: customer,
                merchantDisplayName: 'rdlabo',
            });
        } else {

            const { paymentIntent } = await firstValueFrom(this.http.post<{
                paymentIntent: string;
            }>(this.AppCMS.getRequestUrl('stripe', ['intent', 'without-customer'], {}), {}));

            await Stripe.createPaymentFlow({
                paymentIntentClientSecret: paymentIntent,
                merchantDisplayName: 'rdlabo',
            });
        }
    }

    async createPaymentSheet(withCustomer = true, withBillingDetails = false) {
        if (withCustomer) {

            const { paymentIntent, ephemeralKey, customer } = await firstValueFrom(this.http.post<{
                paymentIntent: string;
                ephemeralKey: string;
                customer: string;
            }>(this.AppCMS.getRequestUrl('stripe', ['intent'], {}), {}));

            await Stripe.createPaymentSheet({
                paymentIntentClientSecret: paymentIntent,
                customerEphemeralKeySecret: ephemeralKey,
                customerId: customer,
                merchantDisplayName: 'rdlabo',
            });
        } else {

            const { paymentIntent } = await firstValueFrom(this.http.post<{
                paymentIntent: string;
            }>(this.AppCMS.getRequestUrl('stripe', ['intent', 'without-customer']), {}, {}));

            const base: CreatePaymentSheetOption = {
                paymentIntentClientSecret: paymentIntent,
                merchantDisplayName: 'rdlabo',
            };

            if (withBillingDetails) {
                Object.assign(base, {
                    billingDetailsCollectionConfiguration: {
                        email: 'always',
                        name: 'always',
                        phone: 'always',
                        address: 'full'
                    }
                })
            }

            await Stripe.createPaymentSheet(base);
        }
    }

    async createPaymentSheetWithSetupIntent() {

        const { setupIntent, ephemeralKey, customer } = await firstValueFrom(this.http.post<{
            setupIntent: string;
            ephemeralKey: string;
            customer: string;
        }>(this.AppCMS.getRequestUrl('stripe', ['intent', 'setup'], {}), {}));

        await Stripe.createPaymentSheet({
            setupIntentClientSecret: setupIntent,
            customerEphemeralKeySecret: ephemeralKey,
            customerId: customer,
            merchantDisplayName: 'rdlabo',
            enableGooglePay: true,
            GooglePayIsTesting: true,
        });
    }

    getPaymentSummaryItems() {
        return this.paymentSummaryItems;
    }

    init() {

        if(!this.appConfig.stripePublishableKey) {
            console.warn('init stripe failed: missing stripePublishableKey in appConfig');
            return false;
        }

        if(!!this.tools.isDesktop()) {
            return this.initWeb();
        }

        Stripe.initialize({
            publishableKey: this.appConfig.stripePublishableKey,
        });

        this.initEvents();
    }

    async initEvents() {

        Stripe.addListener(PaymentSheetEventsEnum.Loaded, () => {
            this.processSheet = 'Ready';
            console.log('PaymentSheetEventsEnum.Loaded');
        });

        Stripe.addListener(PaymentSheetEventsEnum.FailedToLoad, () => {
            console.log('PaymentSheetEventsEnum.FailedToLoad');
        });

        Stripe.addListener(PaymentSheetEventsEnum.Completed, () => {
            this.processSheet = 'willReady';
            console.log('PaymentSheetEventsEnum.Completed');
        });

        Stripe.addListener(PaymentSheetEventsEnum.Canceled, () => {
            this.processSheet = 'willReady';
            console.log('PaymentSheetEventsEnum.Canceled');
        });

        Stripe.addListener(PaymentSheetEventsEnum.Failed, () => {
            this.processSheet = 'willReady';
            console.log('PaymentSheetEventsEnum.Failed');
        });

        /** ------------------------------------------------------------------- **/

        Stripe.addListener(PaymentFlowEventsEnum.Loaded, () => {
            this.processFlow = 'Ready';
            console.log('PaymentFlowEventsEnum.Loaded');
        });

        Stripe.addListener(PaymentFlowEventsEnum.FailedToLoad, () => {
            console.log('PaymentFlowEventsEnum.FailedToLoad');
        });

        Stripe.addListener(PaymentFlowEventsEnum.Completed, () => {
            this.processFlow = 'willReady';
            console.log('PaymentFlowEventsEnum.Completed');
        });

        Stripe.addListener(PaymentFlowEventsEnum.Canceled, () => {
            this.processFlow = 'willReady';
            console.log('PaymentFlowEventsEnum.Canceled');
        });

        Stripe.addListener(PaymentFlowEventsEnum.Failed, () => {
            this.processFlow = 'willReady';
            console.log('PaymentFlowEventsEnum.Failed');
        });

        Stripe.addListener(PaymentFlowEventsEnum.Created, (info) => {
            console.log(info);
            this.processFlow = 'canConfirm';
        });

        /** ------------------------------------------------------------------- **/

        Stripe.addListener(ApplePayEventsEnum.Loaded, () => {
            this.processApplePay = 'Ready';
            console.log('ApplePayEventsEnum.Loaded');
        });

        Stripe.addListener(ApplePayEventsEnum.FailedToLoad, () => {
            console.log('ApplePayEventsEnum.FailedToLoad');
        });

        Stripe.addListener(ApplePayEventsEnum.Completed, () => {
            this.processApplePay = 'willReady';
            console.log('ApplePayEventsEnum.Completed');
        });

        Stripe.addListener(ApplePayEventsEnum.Canceled, () => {
            this.processApplePay = 'willReady';
            console.log('ApplePayEventsEnum.Canceled');
        });

        Stripe.addListener(ApplePayEventsEnum.Failed, () => {
            this.processApplePay = 'willReady';
            console.log('ApplePayEventsEnum.Failed');
        });

        Stripe.addListener(ApplePayEventsEnum.DidCreatePaymentMethod, (data) => {
            console.log(['ApplePayEventsEnum.DidCreatePaymentMethod', data.hasOwnProperty('contact')]);
        });

        Stripe.addListener(ApplePayEventsEnum.DidSelectShippingContact, (data) => {
            console.log(['ApplePayEventsEnum.DidSelectShippingContact', data.hasOwnProperty('contact')]);
        });

        /** ------------------------------------------------------------------- **/

        Stripe.addListener(GooglePayEventsEnum.Loaded, () => {
            this.processGooglePay = 'Ready';
            console.log('GooglePayEventsEnum.Loaded');
        });

        Stripe.addListener(GooglePayEventsEnum.FailedToLoad, () => {
            console.log('GooglePayEventsEnum.FailedToLoad');
        });

        Stripe.addListener(GooglePayEventsEnum.Completed, () => {
            this.processGooglePay = 'willReady';
            console.log('GooglePayEventsEnum.Completed');
        });

        Stripe.addListener(GooglePayEventsEnum.Canceled, () => {
            this.processGooglePay = 'willReady';
            console.log('GooglePayEventsEnum.Canceled');
        });

        Stripe.addListener(GooglePayEventsEnum.Failed, () => {
            this.processGooglePay = 'willReady';
            console.log('GooglePayEventsEnum.Failed');
        });

        Stripe.isApplePayAvailable().then(() => this.isApplePayAvailable = true).catch(() => undefined);
        Stripe.isGooglePayAvailable().then(() => this.isGooglePayAvailable = true).catch(() => undefined);
    }

    async initWeb() {
        this.stripeWebService = await loadStripe(this.appConfig.stripePublishableKey);
        //console.log('web: stripeWebService is now', this.stripeWebService);
    }

    async initWebEmbed(element: any) {
        const session: any = await this.createCheckoutSession();

        if(!session.client_secret) {
            this.events.publish('error', 'missing_stripe_session_uid');
            return false;
        }

        let clientSecret: string = session.client_secret;
        
        if(!!this.checkout) {
            this.checkout.unmount();
        }

        // Create the checkout object
        this.checkout = await this.stripeWebService.initEmbeddedCheckout({
            clientSecret
        });

        // Mount checkout to element
        this.checkout.mount(element);
        
        return this.checkout;
    }
    
    presentPaymentSheet() {
        return Stripe.presentPaymentSheet();
    }

    presentPaymentFlow() {
        return Stripe.presentPaymentFlow();
    }

    presentApplePay() {
        return Stripe.presentApplePay();
    }

    async presentGooglePay() {
        return Stripe.presentGooglePay();
    }

    purchaseUsingWeb() {
        return new Promise((resolve, reject) => {
            this.events.publish('view:payments');
            
            setTimeout(() => {
                this.events.publish('payments:stripe:init');
            }, 100);
        });
    }

    setPaymentSummaryItems(items: any[]) {
        this.paymentSummaryItems = items;
        return this;
    }

}

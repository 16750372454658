import { Injectable } from '@angular/core';

import { BasketService } from 'src/app/services/ecommerce/basket.service';

import { PayPalPayment, PayPalConfiguration, PayPal } from '@ionic-native/paypal/ngx';

@Injectable({
  providedIn: 'root'
})
export class PaypalService {

  PayPalEnvironment: string = 'PayPalEnvironmentProduction';

  PayPalEnvironmentProduction: string = 'AR01MFbVPVbEoldhog1S3HXgeKhKeJsZL3AzZCQ_78sLRxfMLpTIGFyCiEJ0FIEMFotVLWw0pyS98U9Q';

  PayPalEnvironmentSandbox: string = 'AWIh2fDIdVqcZjJi4H7x3tO8ZriJW44zs3-JLWvZ57esrzS9An9c5s3Fhcoo_x2xxT4bVO_4_pCNryR3';

  constructor(
    private basket: BasketService,
    private payPal: PayPal,
  ) {
  }

  getEnvironment() {
    return this.PayPalEnvironment;
  }

  getPayPalEnvironmentProduction() {
    return this.PayPalEnvironmentProduction;
  }

  getPayPalEnvironmentSandbox() {
    return this.PayPalEnvironmentSandbox;
  }

  pay() {
    return new Promise(async (resolve, reject) => {
      let basketInfo: basketInfo = await this.basket.calculateBasketInfo();

      this.payPal.init({
        PayPalEnvironmentProduction: 'AR01MFbVPVbEoldhog1S3HXgeKhKeJsZL3AzZCQ_78sLRxfMLpTIGFyCiEJ0FIEMFotVLWw0pyS98U9Q',
        PayPalEnvironmentSandbox: 'AWIh2fDIdVqcZjJi4H7x3tO8ZriJW44zs3-JLWvZ57esrzS9An9c5s3Fhcoo_x2xxT4bVO_4_pCNryR3',
      })
        .then(() => {
          this.payPal.prepareToRender(
            this.getEnvironment(), new PayPalConfiguration({})
          )
            .then(() => {

              let payment = new PayPalPayment(
                basketInfo.price_a + '',
                'EUR',
                'Ihre Bestellung',
                'sale'
              );

              this.payPal.renderSinglePaymentUI(payment)
                .then(
                  (response: any) => {
                    resolve(response);
                  },
                  (error: any) => {
                    reject(error.message);
                  }
                );
            }, (error: any) => {
              reject(error);
            })
            .catch(reject);
        }, (error: any) => {
          reject(error);
        })
        .catch(reject);
    });
  }

  setPayPalEnvironment(env: string) {
    this.PayPalEnvironment = env;
  }

}
import { Component, OnInit } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';

import { CheckoutService } from 'src/app/services/ecommerce/checkout.service';
import { BasketService } from 'src/app/services/ecommerce/basket.service';
import { PaymentsService } from 'src/app/services/payments/payments.service';
import { EventsService } from "src/app/services/core/events.service";
import { OrdersService } from 'src/app/services/ecommerce/orders.service';
import { UserService } from 'src/app/services/core/user.service';
import { ModalService } from "src/app/services/core/modal.service";
import { ViewService } from 'src/app/services/core/view.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.page.html',
  styleUrls: ['./checkout.page.scss'],
})
export class CheckoutPage implements OnInit {

  basketInfo: basketInfo;

  basketStorage: any;

  block: boolean = false;

  deliveryOptions: any;

  estimatedTime: any;

  intervals: any = {
    'daily': 'täglich',
    'weekly': 'wöchentlich',
    'monthly': 'monatlich',
  };

  tmpOrder: any;

  paymentOptions: any;

  state: state = {};
  
  user: user;

  view: any = {
    hideOrderByBtn: true,
    hideSearch: true,
    title: 'confirm',
  };

  constructor(
    private basket: BasketService,
    private checkoutService: CheckoutService,
    private events: EventsService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private orders: OrdersService,
    private payments: PaymentsService,
    private platform: Platform,
    private userService: UserService,
    private viewService: ViewService,
  ) {
    this.user = this.userService.getUser() || {};
  }

  afterSuccessPay() {
    let method = this.deliveryOptions.repeat && this.tmpOrder && this.tmpOrder.interval ? 'checkoutScheduled' : 'checkout';
    let navPage = method == 'checkoutScheduled' ? '/planner' : '/on-delivery';

    this.checkoutService[method]().then(() => {
      this.block = false;
      this.navCtrl.navigateRoot(navPage);
    })
      .catch((error: any) => {
        this.orderFailed(error);
      });
  }

  calcViewVars() {
    this.view = this.viewService.calcVars(this.view);
  }

  changePayment() {
    this.dismiss(null, 'done');
    this.events.publish('view:payments');
  }

  async dismiss(data: any = null, role: string|null = 'dismiss') {
    this.modalService.dismiss(data, role);
  }

  async ionViewWillEnter() {
    this.basketStorage = await this.basket.getBasket();
    this.basketInfo = await this.basket.calculateBasketInfo();
    this.deliveryOptions = this.checkoutService.getDeliveryOptions();
    this.tmpOrder = this.orders.getTmpOrder();

    this.paymentOptions = this.payments.getPaymentOptions();

    if (!this.paymentOptions.paymentMethod) {
      this.events.publish('view:payments');
      this.dismiss(null, 'done');
    }

  }

  ionViewDidEnter() {
    this.platform.ready();
  }

  ngOnInit() {
    this.calcViewVars();

    window.addEventListener('resize', () => {
      this.calcViewVars();
    });
    
    this.basket.calculateBasketInfo()
      .then((basketInfo: basketInfo) => {
        this.basketInfo = basketInfo;
      });

    this.basket.getBasket()
      .then((basket: basketStorage) => {
        this.basketStorage = basket;
      });

    this.tmpOrder = this.orders.getTmpOrder();
    this.deliveryOptions = this.checkoutService.getDeliveryOptions();
    this.paymentOptions = this.payments.getPaymentOptions();
  }

  orderFailed(error: string = 'Ein unbekannter Fehler ist aufgetreten') {
    this.block = false;
    this.events.publish('error', error);
  }

  pay() {
    this.payments.pay()
      .catch((error: any) => {
        this.events.publish('error', error);
      });
  }

  shop() {

  }

  viewItem(item: any, index: number) {
    this.basket.setEditItemIndex(index);
    this.navCtrl.navigateForward('/details/' + item.id);
  }

}
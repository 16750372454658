import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbsService {

    calculateBack(view: any, config: any) {
        return new Promise((resolve, reject) => {

        });
    }

    calculateConfig(view: any, config: any) {
        return new Promise((resolve, reject) => {

        });
    }

    calculateNext(view: any, config: any) {
        return new Promise((resolve, reject) => {

        });
    }

}
import { Component, HostListener, NgZone, OnInit, ViewChild } from "@angular/core";

import {
  NavController,
  AlertController,
  IonInput,
  Platform,
  IonContent,
} from "@ionic/angular";

import { AccountsService } from 'src/app/services/core/accounts.service';
import { AppcmsService } from 'src/app/services/core/appcms.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { EventsService } from 'src/app/services/core/events.service';
import { LanguageService } from 'src/app/services/core/language.service';
import { LogfileService } from "src/app/services/core/logfile.service";
import { ModalService } from "src/app/services/core/modal.service";
import { NetworkService } from "src/app/services/core/network.service";
import { PipelineService } from "src/app/services/pipeline/pipeline.service";
import { ToolsService } from "src/app/services/utils/tools.service";
import { TranslationService } from 'src/app/services/core/translation.service';
import { UserService } from 'src/app/services/core/user.service';
import { ViewService } from 'src/app/services/core/view.service';
import { WebService } from "src/app/services/utils/web.service";

import {
  SignInWithApple,
  ASAuthorizationAppleIDRequest,
} from "@awesome-cordova-plugins/sign-in-with-apple/ngx";
import { Facebook, FacebookLoginResponse } from "@awesome-cordova-plugins/facebook/ngx";

import { DomSanitizer } from "@angular/platform-browser";

declare var AppleID: any;

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
  @ViewChild(IonContent) content: IonContent;
  @ViewChild("loginEmail") loginEmail: IonInput;
  @ViewChild("registerFirstname") registerFirstname: IonInput;

  agbPageId: number = 1;

  config: pipelineAppConfig;

  error: any;

  privacyPolicyPageId: number = 2;

  registerOptions: registerOptions = {};

  splineOptions: splineOptions = {
    zoom: 0.65,
  };

  user: user = {
    classifications: {},
    email: "",
    firstname: "",
    lastname: "",
  };

  view: any = {
    hideGetGeniusWallet: true,
    loading: false,
  };

  constructor(
    private accounts: AccountsService,
    private alertCtrl: AlertController,
    private AppCMS: AppcmsService,
    private configService: ConfigService,
    private events: EventsService,
    private fb: Facebook,
    private language: LanguageService,
    private log: LogfileService,
    private modalService: ModalService,
    private navCtrl: NavController,
    private network: NetworkService,
    private pipeline: PipelineService,
    private platform: Platform,
    private sanitizer: DomSanitizer,
    private siwa: SignInWithApple,
    private tools: ToolsService,
    private translations: TranslationService,
    private UserService: UserService,
    private viewService: ViewService,
    private webService: WebService,
    private zone: NgZone,
  ) {
    this.config = this.configService.getConfig();
    this.user = this.UserService.getUser() || this.user;
  }

  afterLogin() {
    if (this.view.isMultiMode) {
      this.dismiss(null, 'done');
    } else {
      const url: string = this.UserService.getAfterLoginRedirectUrl() || "/home";
      this.view.loggedIn = true;
      this.navCtrl.navigateForward(url);
    }
  }

  async alert(message: string, title: string = "Fehler") {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: [
        {
          text: "Okay",
        },
      ],
    });
    await alert.present();
  }

  calcLoginLegalInfo() {
    this.translations
      .get(["agb", "login_legal_info", "privacy_policy"])
      .subscribe((translations: any) => {
        setTimeout(() => {
          this.zone.run(() => {
            let loginLegalInfo = document.createElement('span');

            const agbLink: string = `${translations.agb}`;
            const privacyPolicyLink: string = `${translations.privacy_policy}`;
            const legalInfoContent = translations.login_legal_info
              .replace(/\$ /g, '$')
              .replace("$1", agbLink)
              .replace("$2", privacyPolicyLink);

            loginLegalInfo.innerHTML = legalInfoContent;

            this.view.loginLegalInfo = this.sanitizer.bypassSecurityTrustHtml(
              loginLegalInfo.innerHTML
            );

            window.addEventListener("hashchange", () => {
              const path = location.hash.replace('#/', '').split('/');

              switch (path[0]) {
                case 'dynamic':
                  if (!!path[1]) {
                    this.events.publish('view:page', parseInt(path[1]));
                  }
                  break;
                default:
                  console.warn('unknown path', path);
                  break;
              }

            }, false);
          });
        });
      });
  }

  calcViewVars() {
    this.view.isApiURLConfigurable = this.configService.isApiURLConfigurable();

    this.view = this.viewService.calcVars(this.view);
    this.view.platform = (this.platform.is("ios") ? "ios" : this.platform.is("android") ? "android" : "web");
    this.view.isMultiMode = this.accounts.isMultiMode();

    this.view.canRegiser = (!this.view.isMultiMode && !!this.config.allowUserRegister && (
      (!this.view.isWeb && this.config.allowUserRegisterInApp !== false) ||
      (!!this.view.isWeb && this.config.allowUserRegisterInWeb !== false)
    ));
  }

  async dismiss(data: any = null, role: string | null = 'dismiss') {
    this.accounts.setMultiMode(false);
    (await this.modalService).dismiss(data, role);
  }

  async forgotPassword() {
    (await this.modalService).closeAll();
    this.navCtrl.navigateForward("/forgot-password");
  }

  async initIntro() {
    /*
    const bl: boolean = await this.intro.loadedSliderPage();

    if (!bl && !this.accounts.isMultiMode() && !this.UserService.getUid() && !this.tools.isDesktop()) {
      this.navCtrl.navigateRoot('/intro-slider');
    }
    */
  }

  inputBlur(event: any = null) {
    this.zone.run(() => {
      this.view.scrollable = false;
      this.content.scrollToBottom();
    });
  }

  inputFocus(event: any = null) {
    this.zone.run(() => {
      this.view.scrollable = true;
      this.content.scrollToBottom();
    });
  }

  ionViewDidEnter() {
    try {
      this.renderSignInWithApple();
    } catch (e) {
      console.warn('render Sign in with Apple failed', e);
    }
  }

  ionViewWillEnter() {
    this.user = this.UserService.getUser() || this.user;

    //this.initIntro();
    this.calcViewVars();

    if (!this.view.isMultiMode) {
      this.validateUser();
    }

  }

  async loadLanguages() {
    try {
      this.view.languages = await this.language.getLanguages();
    } catch (e) {
      this.events.publish(e);
    }
  }

  async login(credentials: any = null) {

    credentials = credentials || {
      email: this.user.email,
      password: this.user.password,
    };

    if (!!this.view.api_url && (this.view.api_url.indexOf('://') === -1)) {
      this.view.api_url = `https://${this.view.api_url}`;
    }

    this.pipeline
      .login(credentials, {
        api_url: this.view.api_url,
      })
      .then((response: loginResponse) => {
        this.user = response.user;
        this.user.password = this.user.password;

        this.afterLogin();
      })
      .catch((error: any) => {
        this.error = error;
      });
  }

  loginWithFacebook() {
    if (this.tools.isWeb()) {
      return this.webService.appFeaturesRequested();
    }

    this.fb
      .login(["public_profile", "email"])
      .then((res: FacebookLoginResponse) => {
        this.fb
          .api(
            "me?fields=id,name,email,first_name,picture.width(720).height(720).as(picture_large)",
            []
          )
          .then((profile: any) => {

            const lastnameExplode = profile.name.split(" "),
              lastname = lastnameExplode[lastnameExplode.length - 1];

            this.user.email = profile.email || this.user.email;
            this.user.firstname = profile.first_name || this.user.firstname;
            this.user.lastname = lastname || this.user.lastname;
            this.user.email = profile.email || this.user.email;
            this.user.photo = profile.picture_large.data.url || this.user.photo;
            this.user.nickname = profile.name || this.user.nickname;

            this.UserService.setUser(this.user).then(() => {
              this.register();
            });

          })
          .catch((e: any) => {
            if (
              !e.errorMessage ||
              e.errorMessage.indexOf("User cancelled") === false
            ) {
              this.events.publish("error", e.errorMessage || e);
            }
          });
      })
      .catch((e) => {
        if (
          !e.errorMessage ||
          e.errorMessage.indexOf("User cancelled") === false
        ) {
          this.events.publish("error", e.errorMessage || e);
        }
      });
  }

  ngOnInit() {
    this.calcLoginLegalInfo();

    this.calcViewVars();
  }

  @HostListener('window:resize')
  onResize() {
    this.view = this.viewService.calcScreenSizeVars(this.view);
  }

  register() {
    const route: string = (!!this.config.registerRequiresAbonnement ? "/abonnements" : '/register');
    this.navCtrl.navigateForward(route);
  }

  removeRedeemCodeLetter() {
    this.view.code = this.view.code.substring(0, this.view.code.length - 1);
    this.view.redeemHeadlineMessage = this.view.redeemHeadlineMessageDefault;
    delete this.view.codeRedeemSuccess;
  }

  renderSignInWithApple() {
    if (!this.platform.is("ios") || this.tools.isWeb()) {
      if (this.tools.isWeb()) {

        AppleID.auth.init({
          clientId: "page.pipeline.web",
          scope: "name email",
          redirectURI: "https://web.pipeline.page",
          state: "origin:web",
          usePopup: true, //or false defaults to false
        });

        document.addEventListener('AppleIDSignInOnSuccess', (data) => {
          //handle successful response
          console.info('AppleIDSignInOnSuccess', data);
        });

        //Listen for authorization failures
        document.addEventListener('AppleIDSignInOnFailure', (error) => {
          //handle error.
          console.warn('AppleIDSignInOnFailure', error);
        });

      }
    }
  }

  signInWithApple() {
    if (this.tools.isWeb()) {
      return this.webService.appFeaturesRequested();
    }

    this.siwa
      .signin({
        requestedScopes: [
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
          ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail,
        ],
      })
      .then((response: any) => {

        if (
          (response.email && response.email.length) ||
          (response.fullName &&
            response.fullName.givenName &&
            response.fullName.givenName.length)
        ) {
          this.user.email = response.email || this.user.email;
          this.user.firstname =
            response.fullName.givenName || this.user.firstname;
          this.user.lastname =
            response.fullName.familyName || this.user.lastname;
          this.user.nickname =
            "user" + this.tools.generateRandomUid();
          this.user.identity_token = response.user;

          this.register();
        } else {
          this.validateAppleUser(response);
        }
      })
      .catch((error: any) => {
        console.warn("siwa error", error);
        this.events.publish(
          "error",
          error.message || error.localizedDescription || error
        );
      });
  }

  validateAppleUser(response: any) {
    this.UserService.loginWithAppleSignIn(response)
      .then((response: any) => {

        if (response && response.action) {
          switch (response.action) {
            case "cancel":
              break;
            case "createSIWAAccount":
              this.validateUser();
              break;
          }
        }

        if (response && response.success) {
          this.afterLogin();
        }
      })
      .catch((error: any) => {
        this.events.publish("error", error);
      });
  }

  async validateUser() {

    if (this.view.verify) {
      return false;
    }

    this.view.loading = true;
    this.view.loggedIn = false;
    this.view.verify = true;

    this.user = this.UserService.getUser() || this.user;

    if (this.user && this.user.uid) {
      this.AppCMS.storeCurrentAuthDetails(this.user);

      const networkStatus = await this.network.getStatus();

      if (!networkStatus.connected) {
        this.network.showOfflineMessage();

        this.navCtrl.navigateRoot(this.UserService.getAfterLoginRedirectUrl(), {
          animated: false,
        });

        this.view.loading = false;
        this.view.loggedIn = true;

        return false;
      }

      const requireValidation = await this.UserService.shouldValidate();

      if (!requireValidation) {
        this.UserService.setUser(this.user, false).then(() => {

          this.navCtrl.navigateRoot(
            this.UserService.getAfterLoginRedirectUrl(),
            { animated: false }
          );

          this.view.loading = false;
          this.view.verify = false;
          this.view.loggedIn = !!(this.user && this.user.uid);

          this.log.loggedIn();
        });

      } else {

        // @todo replace this with new token-exchange route

        this.pipeline
          .authorize()
          .then((response: any) => {
            console.log('authData', response);

            this.view.loading = false;
            this.view.verify = false;

            if (!response) {
              this.view.loggedIn = false;
              this.events.publish("error");
            } else
              if (
                !response.user ||
                !response.user.uid ||
                !response.user.active
              ) {
                this.view.loggedIn = false;
                this.events.publish("error", "error_account_not_active");
                this.UserService.logout();
              } else {
                this.view.loggedIn = !!(response.user && response.user.uid);

                this.UserService.setUser(response.user, false).then(() => {

                  this.navCtrl.navigateRoot(
                    this.UserService.getAfterLoginRedirectUrl(),
                    { animated: false }
                  );

                  this.UserService.shouldValidate(false);
                  this.log.loggedIn();
                });
              }
          })
          .catch((error: any) => {
            this.zone.run(() => {
              this.view.loading = false;
              this.view.loggedIn = false;
              this.view.verify = false;

              if (error !== 'error_offline') {
                this.events.publish("error", error);
              }
            });
          });
      }
    } else {
      this.view.loading = false;
      this.view.loggedIn = false;
      this.view.verify = false;
    }
  }

  viewAgb() {
    this.events.publish("view:page", this.agbPageId);
  }

  viewPrivacyPolicy() {
    this.events.publish("view:page", this.privacyPolicyPageId);
  }

}
import { Injectable } from '@angular/core';

import { CacheService } from 'src/app/services/core/cache.service';
import { EventsService } from 'src/app/services/core/events.service';
import { UserService } from 'src/app/services/core/user.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private cache: CacheService,
    private events: EventsService,
    private userService: UserService,
  ) {

  }

  getSetting(key: string, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      this.getSettings(null, blForceRefresh)
        .then((settings: any) => {
          resolve(settings[key]);
        })
        .catch((error: any) => {
          if (error && error.toLowerCase().indexOf('error') !== -1) {
            reject(error);
          } else {
            resolve(error); // resolve(), because empty setting is okay here
            // @todo validate error
          }
        });
    });
  }

  getSettings(userId: number = null, blForceRefresh: boolean = false) {
    return new Promise((resolve, reject) => {
      userId = userId || this.userService.getUid();
      this.userService.getByUid(userId, blForceRefresh)
        .then((user: user) => {
          resolve(user && user.classifications && user.classifications.settings ? user.classifications.settings : {});
        })
        .catch(reject);
    });
  }

  setSettings(settings: settings, userId: number = null) {
    const user: user = this.userService.getUser();

    userId = userId || user.uid;

    return this.cache.set('user_settings_' + userId, settings);
  }

  async showUpdatedToast() {
    this.events.publish('toast', {
      icon: 'checkmark-outline',
      message: 'settings_updated',
      color: 'primary',
    });
  }

  updateSettings(settings: settings) {
    return new Promise((resolve, reject) => {
      let user = this.userService.getUser() || {};
      if (!user.uid) {
        reject('error_missing_user_uid');
      } else {
        this.getSettings(user.uid, true)
          .then((_settings: settings) => {
            settings = Object.assign(_settings, settings);
            user.classifications.settings = settings;

            this.setSettings(settings)
              .then(() => {
                this.userService.setUser(user, true).then(resolve).catch(reject);
              })
              .catch(reject);
          })
          .catch(reject);
      }
    });
  }

  updateSetting(key: string, value: any) {
    let settings = {};
    settings[key] = value;
    return this.updateSettings(settings);
  }

}